import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {Areas, Categories} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { commonStatusName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgClose from './Images/close.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';

// start
const CategoriesScreen = () => {
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [areas, setAreas] = useState([]);
	const [categoriesAll, setCategoriesAll] = useState([]);
	const [categories, setCategories] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:sortOrder, setValue:setSortOrder, bind:bindSortOrder} = useInput('');
	const {value:link, setValue:setLink, bind:bindLink} = useInput('');
	const [categoryAreas, setCategoryAreas] = useState([]);
	const [search, setSearch] = useState(null);
	const [isHide, setIsHide] = useState(true);
	const [controlId, setControlId] = useState(null);
	const [status, setStatus] = useState(null);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await areasGet();
			await categoriesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const areasGet = async () => {
		const areas = await Areas.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (areas === undefined) return;
		setAreas(areas);
	};
	const categoriesGet = async () => {
		const categories = await Categories.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (categories === undefined) return;
		setCategories(categories);
		setCategoriesAll(categories);
	};
	const categoryShow = (category) => {
		setControlId(null);
		setId(category?._id||null);
		setName(category?.name||'');
		setDescription(category?.description||'');
		setSortOrder(category?.options?.sortOrder||'');
		setIsHide(category?.options?.isHide||false);
		setLink(category?.link||'');
		setStatus(category?category.status:'');
		setCategoryAreas(category ? (category.areas?.length ? areas.filter(f => category.areas.includes(f._id)) : []) : []);
		setIsEditShow(true);
	};
	const cancel = () => {
		categoryShow(null);
		setIsEditShow(false);
		modalDeleteHide();
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			description:description,
			areas:categoryAreas.length ? categoryAreas.map(f => f._id) : null,
			sortOrder:sortOrder,
			isHide:isHide,
			link:link,
			status:status
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название категории');
			return;
		}
		const res = id ?
				await Categories.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Categories.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			categoriesGet();
			cancel();
		}
	}
	const searchCategory = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setCategories(categoriesAll);
		else setCategories(categoriesAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const categoryControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const categoryDelete = () => {
		Categories.remove(id).then((res) => {
			successShow(res.message);
			categoriesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalDeleteHide = () => modalDeleteClose();
	const modalDeleteShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setName(categories.find(f => f._id === id).name);
		modalDeleteOpen();
	}
	const areaSelect = (id) => {
		if (categoryAreas.find(f => f._id === id)) setCategoryAreas(categoryAreas.filter(f => f._id !== id));
		else setCategoryAreas([...categoryAreas, areas.find(f => f._id === id)]);
	}
	const areaGet = (a) => {
		const out = [];
		if (a && a.length !== areas.length) {
			for (let i = 0; i < a.length; i++) {
				out.push(areas.find(f => f._id === a[i]).name);
			}
		}
		return out.length === 0 ? 'Все точки' : out.join(', ');
	}
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const linkCreate = (e) => {
		const name = e.target.value;
		setLink(Utils.translit(name));
		setName(name);
	}
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Кат.товаров" type="books" />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchCategory} value={search} />
										<button type="button" onClick={() => categoryShow()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{categories.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Название</th>
															<th className="area">Точка</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{categories.map((v,i) => <tr key={i} onClick={() => categoryShow(v)}>
															<td className={v.options?.isHide?'hidden':''}>{v.name}</td>
															<td className="area">{areaGet(v.areas)}</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => categoryControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			<div className="edit" onClick={() => categoryShow(v)}>Редактировать</div>
																			<div className="delete" onClick={(e) => modalDeleteShow(e, v._id)}>Удалить</div>
																		</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте первую<br/>категорию
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row">
														<input type="text" {...bindName} placeholder="Название категории" className="input-title" autoFocus={true} onChange={linkCreate} required />
													</div>
													<div className="product-edit-row">
														<textarea placeholder="Описание" {...bindDescription}></textarea>
													</div>
													<h4>Доступность на точках</h4>
													<div className="product-edit-row">
														{areas.map((v,i) => <div key={i} className="product-edit-area product-edit-row-oneline-start">
															<img src={categoryAreas.find(f => f._id === v._id)?imgCheckOn:imgCheckOff} alt="" className="checkbox" onClick={() => areaSelect(v._id)} />
															{v.name}
														</div>)}
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="link" className="label-middle">Ссылка</label>
														<input id="link" {...bindLink} type="text" placeholder="Ссылка для категории" />
													</div>
													<h4>Свойства</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label className="label-middle">Скрытая категория</label>
														<img src={isHide ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsHide(!isHide)} />
													</div>
													<h4>Дополнительные параметры</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="sortOrder" className="label-middle">Порядок сортировки</label>
														<input id="sortOrder" {...bindSortOrder} type="text" placeholder="0" maxLength={2} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="status">Статус</label>
														<div className="select select-middle">
															<select id="status" onChange={handleStatus} value={status} required>
																<option value=""></option>
																{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
															</select>
														</div>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									:
										<Empty image={'caregories'} text={<>Для редактирования категории,<br />выберите одну из списка</>} />
								}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalDelete>
				<div className="modal">
					<div className="header">
						<h4>Удалить категорию</h4>
						<img src={imgClose} alt="" onClick={modalDeleteHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите удалить <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalDeleteHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={categoryDelete} className="btn-accept btn-delete">Удалить</button>
					</div>
				</div>
			</ModalDelete>
		</>
	);
};

export default CategoriesScreen;