import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import InputMask from 'react-input-mask';
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';
import Calendar from '../../../Components/Calendar';

// models
import {Areas, Categories, Points, Roles, Users} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';
import Orfo from '../../../Globals/Orfo';

// constants
import { API, permissionTypeName, salaryParams, userStatus, userStatusName, userStatusNameWork, userType, userTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgClose from './Images/close.svg';
import imgNoPhoto from './Images/no-photo.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgPlus from './Images/plus.svg';
import imgEdit from './Images/edit.svg';


// start
const UsersScreen = (props) => {
	const [ModalSalaryFormula, modalSalaryFormulaOpen, modalSalaryFormulaClose] = useModal('root', {preventScroll:true});
	const [ModalSalary, modalSalaryOpen, modalSalaryClose] = useModal('root', {preventScroll:true});
	const [ModalDay, modalDayOpen, modalDayClose] = useModal('root', {preventScroll:true});
	const [ModalAccess, modalAccessOpen, modalAccessClose] = useModal('root', {preventScroll:true});
	const [ModalRolesAdd, modalRolesAddOpen, modalRolesAddClose] = useModal('root', {preventScroll:true});
	const [ModalChangeStatus, modalChangeStatusOpen, modalChangeStatusClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [partnerId, setPartnerId] = useState(null);
	const [pointId, setPointId] = useState(null);
	const [user, setUser] = useState(null);
	const [users, setUsers] = useState([]);
	const [usersAll, setUsersAll] = useState([]);
	const [categories, setCategories] = useState([]);
	const [points, setPoints] = useState([]);
	const [areas, setAreas] = useState([]);
	const [areasPoint, setAreasPoint] = useState([]);
	const [roles, setRoles] = useState([]);
	const [rolesPoint, setRolesPoint] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:comment, setValue:setComment, bind:bindComment} = useInput('');
	const {value:email, setValue:setEmail, bind:bindEmail} = useInput('');
	const {value:password, setValue:setPassword, bind:bindPassword} = useInput('');
	const {value:phone, setValue:setPhone, bind:bindPhone} = useInput('');
	const {value:birthdate, setValue:setBirthdate, bind:bindBirthdate} = useInput('');
	const {value:roleName, setValue:setRoleName, bind:bindRoleName} = useInput('');
	const [avatar, setAvatar] = useState(null);
	const [access, setAccess] = useState(null);
	const [accessTemp, setAccessTemp] = useState(null);
	const [permissions, setPermissions] = useState([]);
	const [typeId, setTypeId] = useState(null);
	const [roleId, setRoleId] = useState(null);
	const [areaId, setAreaId] = useState(null);
	const [isOrderAttach, setIsOrderAttach] = useState(false);
	const {value:workStart, setValue:setWorkStart, bind:bindWorkStart} = useInput('');
	const {value:workEnd, setValue:setWorkEnd, bind:bindWorkEnd} = useInput('');
	const [noAccess, setNoAccess] = useState(true);
	const [isIntern, setIsIntern] = useState(false);
	const [search, setSearch] = useState(null);
	const [month, setMonth] = useState(null);
	const [year, setYear] = useState(null);
	const [day, setDay] = useState(null);
	const {value:start, setValue:setStart, bind:bindStart} = useInput('');
	const {value:end, setValue:setEnd, bind:bindEnd} = useInput('');
	const [userWorktime, setUserWorktime] = useState(null);
	const {value:salary, setValue:setSalary, bind:bindSalary} = useInput('');
	const {value:salaryHour, setValue:setSalaryHour, bind:bindSalaryHour} = useInput('');
	const {value:salaryDay, setValue:setSalaryDay, bind:bindSalaryDay} = useInput('');
	const {value:percentsOrders, setValue:setPercentsOrders, bind:bindPercentsOrders} = useInput('');
	const {value:fixesOrders, setValue:setFixesOrders, bind:bindFixesOrders} = useInput('');
	const [formula, setFormula] = useState(null);
	const [userCategories, setUserCategories] = useState([]);
	const [controlId, setControlId] = useState(null);
	const [status, setStatus] = useState(null);
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await categoriesGet();
			await rolesGet();
			await areasGet();
			await pointsGet();
			await usersGet();
			const point = ls('point');
			setPartnerId(point.partner.id);
			setPointId(point.point.id);
		};
		await dataGet();
		setLoading(false);
	}, []);
	useEffect(() => {
		if (users.length > 0) {
			const user = users.find(f => f._id === props.match.params.id);
			if (user) userShow(user);
		}
	}, [users]);
	const usersGet = async () => {
		const users = await Users.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (users === undefined) return;
		setUsers(users);
		setUsersAll(users);
	};
	const categoriesGet = async () => {
		const categories = await Categories.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (categories === undefined) return;
		setCategories(categories);
	};
	const areasGet = async () => {
		const areas = await Areas.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (areas === undefined) return;
		setAreas(areas);
	};
	const rolesGet = async () => {
		const roles = await Roles.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (roles === undefined) return;
		setRoles(roles);
	};
	const pointsGet = async () => {
		const points = await Points.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (points === undefined) return;
		setPoints(points);
	};
	const userAdd = () => {
		setIsShow(false);
		setIsEditShow(true);
		show();
	}
	const userEdit = (e, user) => {
		e.stopPropagation();
		user = user || users.find(f => f._id === id);
		show(user);
		setIsShow(false);
		setIsEditShow(true);
	};
	const userShow = (user) => {
		show(user);
		setIsEditShow(false);
		setIsShow(true);
	}
	const show = (user) => {
		setControlId(null);
		setUser(user);
		setId(user?._id||null);
		setName(user?.name||'');
		setComment(user?.comment||'');
		setEmail(user?.email||'');
		setPassword(user?.password||'');
		setPhone(user?.phone||'');
		setBirthdate(Utils.dateNormalize(user, 'birthdate'));
		setStatus(user?user.status:'');
		setAccess(user?.points||null);
		setAccessTemp(user && user.points && user.points.length ? [...user.points] : null);
		if (user?.points && user.points.length === 1) {
			const p = user.points[0];
			setTypeId(p.type);
			setRoleId(p.role.id);
			setAreaId(p.areas.length === 1 ? p.areas[0].id : 0);
		}
		const noAccess = user?user.properties?.noAccess:true;
		setNoAccess(noAccess);
		if (noAccess && !user)  {
			setAreaId('');
			setTypeId('');
			setRoleId('');
		}
		setIsIntern(user?.properties?.isIntern||false);
		setIsOrderAttach(user?.properties?.isOrderAttach||false);
		setUserCategories(user?user.properties?.categories?.map(v => v.id) : []);
		setWorkStart(user?.properties?.worktime?.start||'');
		setWorkEnd(user?.properties?.worktime?.end||'');
		setUserWorktime(user? user.worktime.map(v => v.date) : []);
		const point = ls('point');
		setAreasPoint(areas.filter(f => f.pointId === point.point.id));
		setRolesPoint(roles.filter(f => f.pointId === point.point.id));
		setAvatar(user ? avatarUrlGet(partnerId, pointId, user._id) : null);
		setIsEditShow(true);
	};
	const cancel = () => {
		userShow(null);
		setIsEditShow(false);
		setIsShow(false);
		modalChangeStatusHide();
		modalDayClose();
		modalSalaryClose();
		modalSalaryFormulaClose();
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			email:email,
			password:password,
			phone:Utils.phoneNormalize(phone),
			birthdate:birthdate,
			points:access,
			comment:comment,
			isOrderAttach:isOrderAttach,
			categories:userCategories,
			noAccess:noAccess,
			isIntern:isIntern,
			workStart:workStart,
			workEnd:workEnd,
			status:status
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить имя сотрудника');
			return;
		}
		if (!noAccess) {
			if (Utils.empty(data.email)) {
				errorShow('Необходимо заполнить электронную почту');
				return;
			}
			if (!id) {
				if (Utils.empty(data.password)) {
					errorShow('Необходимо заполнить пароль');
					return;
				}
			}
			if (Utils.empty(data.phone)) {
				errorShow('Необходимо заполнить телефон');
				return;
			}
		}
		if (!Utils.empty(data.workStart) && Utils.empty(data.workEnd)) {
			errorShow('Необходимо заполнить конец рабочего времени');
			return;
		}
		if (Utils.empty(data.workStart) && !Utils.empty(data.workEnd)) {
			errorShow('Необходимо заполнить начало рабочего времени');
			return;
		}
		if (Utils.empty(data.points) || data.points.length === 0) {
			if (areaId === 0 || roleId === 0 || typeId === 0) {
				errorShow('Необходимо заполнить права и доступ');
				return;
			}
			const point = ls('point');
			data.points = [{point:{id:point.point.id},type:typeId,role:{id:roleId},areas:[{id:areaId}]}];
		}
		const res = id ?
				await Users.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.code === 409 ? ex.message : null);
				})
			:
				await Users.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.code === 409 ? ex.message : null);
				});
		if (res) {
			avatarUpload(id||res.id);
			successShow(res.message);
			usersGet();
			cancel();
		}
	}
	const avatarUpload = async (id) => avatar && avatar.indexOf('data:image') === 0 ? await Users.imageAdd(id, {data:avatar.replace(/^.*,/gi,'')}).catch((ex) => console.log(666, ex)) : null;
	const searchUser = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setUsers(usersAll);
		else setUsers(usersAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const userControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const handleType = (e) => setTypeId(parseInt(e.target.value));
	const handleRole = (e) => setRoleId(e.target.value);
	const handleArea = (e) => setAreaId(e.target.value);
	const userChangeStatusOn = (e, id) => {
		e.stopPropagation();
		userChangeStatus(id);
	}
	const userChangeStatusModal = () => userChangeStatus(id);
	const userChangeStatus = (id) => {
		const user = users.find(f => f._id === id);
		const status = user.status === userStatus.ACTIVE ? userStatus.IN_ACTIVE : userStatus.ACTIVE;
		Users.status(id, {status}).then((res) => {
			successShow(res.message);
			usersGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalChangeStatusHide = () => modalChangeStatusClose();
	const modalChangeStatusShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setName(users.find(f => f._id === id).name);
		modalChangeStatusOpen();
	}
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const typeGet = (type) => {
		if (type !== userType.UNKNOWN) {
			const idx = Object.values(userType).indexOf(type);
			if (idx) return userTypeName[idx];
		}
		return null;
	}
	const typesGet = (user) => {
		const types = [];
		user.points.forEach((v) => {
			if (v.type !== userType.UNKNOWN) {
				const t = typeGet(v.type);
				if (t) {
					if (!types.includes(t)) types.push(t);
				}
			}
		});
		return types.length > 0 ? <div className="types">{types.join(', ')}</div> : null;
	}
	const imageError = (e) => e.target.src = imgNoPhoto;
	const avatarDelete = () => setAvatar(null);
	const handleImage = (e) => {
		const file = e.target.files?.[0];
		if (!file) return;
		const reader = new FileReader();
		reader.onload = (r) => setAvatar(r.target.result);
		reader.readAsDataURL(file);
	};
	const accessShow = () => modalAccessOpen();
	const modalAccessHide = () => modalAccessClose();
	const accessAdd = () => {
		if (accessTemp && accessTemp.length === 1) {
			const p = accessTemp[0];
			setTypeId(p.type);
			setRoleId(p.role.id);
			setAreaId(p.areas.length === 1 ? p.areas[0].id : 0);
		}
		setAccess(accessTemp);
		modalAccessHide();
	}
	const roleAddShow = () => modalRolesAddOpen();
	const modalRolesAddHide = () => {
		setPermissions([]);
		modalRolesAddClose();
	}
	const roleSave = async () => {
		const data = {
			name:roleName,
			permissions:permissions
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название роли');
			return;
		}
		const res = await Roles.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			rolesGet();
			modalRolesAddHide();
		}
	};
	const permissionSet = (id) => {
		if (permissions.includes(id)) setPermissions(permissions.filter(f => f !== id));
		else setPermissions([...permissions, id]);
	};
	const isInPermission = (id) => permissions.includes(id);
	const accessBlock = () => <>
		<div className="product-edit-row product-edit-row-oneline">
			<label htmlFor="productProteins">Точка</label>
			<div className="select select-wide">
				<select onChange={handleArea} value={areaId} required>
					<option value=""></option>
					{areasPoint.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
				</select>
			</div>
		</div>
		<div className="product-edit-row product-edit-row-oneline">
			<label htmlFor="productProteins">Должность</label>
			<div className="select select-wide">
				<select onChange={handleType} value={typeId} required>
					<option value=""></option>
					{Object.entries(userType).map(([k,v]) => v === 0 ? null : <option key={k} value={v}>{typeGet(v)}</option>)}
				</select>
			</div>
		</div>
		<div className="product-edit-row product-edit-row-oneline">
			<label htmlFor="productProteins">Роль</label>
			<div className="select select-wide">
				<select onChange={handleRole} value={roleId} required>
					<option value=""></option>
					{rolesPoint.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
				</select>
			</div>
		</div>
	</>;
	const accessPointCheck = (id) => accessTemp?.find(f => f.point.id === id);
	const accessAreaCheck = (id) => accessTemp?.find(f => f.areas.find(a => a.id === id));
	const handleTypeModal = (e, pointId) => {
		accessTemp.forEach((v) => {
			if (v.point.id === pointId) v.type = parseInt(e.target.value);
		});
		setAccessTemp([...accessTemp]);
	}
	const handleRoleModal = (e, pointId) => {
		accessTemp.forEach((v) => {
			if (v.point.id === pointId) v.role = {id:e.target.value};
		});
		setAccessTemp([...accessTemp]);
	}
	const accessAreaSet = (pointId, areaId) => {
		const acc = accessTemp || {point:{id:pointId}};
		const item = acc.find(f => f.point.id === pointId);
		if (!item) {
			accessTemp.push({point:{id:pointId},areas:[{id:areaId}]});
			setAccessTemp([...accessTemp]);
		}
		else {
			const area = item.areas.find(f => f.id === areaId);
			if (area) item.areas.splice(item.areas.indexOf(area), 1);
			else item.areas.push({id:areaId});
			const accTmp = [];
			accessTemp.forEach((v) => {
				if (v.areas.length > 0) accTmp.push(v);
			});
			setAccessTemp([...accTmp]);
		}
	}
	const avatarUrlGet = (partnerId, pointId, userId) => `${API.assets}partners/${partnerId}/${pointId}/users/${userId}/avatar.jpg`;
	const worktimeShow = (date) => {
		const d = moment(date);
		setYear(d.year());
		setMonth(d.month()+1);
		setDay(d.date());
		modalDayOpen();
		const worktime = (user.worktime || []).find(f => f.date === d.format('YYYY-MM-D'));
		if (worktime) {
			setStart(worktime.start);
			setEnd(worktime.end);
		}
	};
	const modalDayHide = () => {
		setStart('');
		setEnd('');
		modalDayClose();
	};
	const worktimeSave = async (e) => {
		e.preventDefault();
		const date = `${year}-${month>9?month:`0${month}`}-${day}`;
		const worktime = user.worktime || [];
		const data = worktime.find(f => f.date === date);
		if (data) {
			data.start = start;
			data.end = end;
			worktime[worktime.findIndex(f => f.date === date)] = data;
		} else worktime.push({date,start,end});
		if (Utils.empty(start) || Utils.empty(end)) {
			errorShow('Заполните начало и конец рабочего дня');
			return;
		}
		if (parseInt(start.replace(':','')) > parseInt(end.replace(':',''))) {
			errorShow('Дата начала не может быть больше даты окончания');
			return;
		}
		const res = await Users.worktime(id, {worktime}).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			setUserWorktime([...worktime.map(v => v.date)]);
			usersGet();
			modalDayHide();
		}
	};
	const worktimeReset = async (day) => {
		const date = `${year}-${month>9?month:`0${month}`}-${day}`;
		const worktime = users.find(f => f._id === id)?.worktime.filter(f => f.date !== date);
		const res = await Users.worktime(id, {worktime}).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			setUserWorktime([...worktime.map(v => v.date)]);
			usersGet();
			modalDayHide();
		}
	};
	const salaryShow = (e, user) => {
		e?.stopPropagation();
		setControlId(null);
		setId(user._id);
		setUser(user);
		setSalary(user?.salary?.salary?.month);
		setSalaryHour(user?.salary?.salary?.hour);
		setSalaryDay(user?.salary?.salary?.day);
		setPercentsOrders(user?.salary?.percents?.orders);
		setFixesOrders(user?.salary?.fixes?.order);
		setFormula(user?.salary?.formula||null);
		modalSalaryOpen();
	};
	const salaryHide = () => {
		setSalary('');
		setSalaryHour('');
		setSalaryDay('');
		setPercentsOrders('');
		setFixesOrders('');
		setFormula(null);
		modalSalaryClose();
		modalSalaryFormulaClose();
	};
	const salaryAdd = async (e) => {
		e.preventDefault();
		const data = {
			salary:salary,
			salaryHour:salaryHour,
			salaryDay:salaryDay,
			percentsOrders:percentsOrders,
			fixesOrders:fixesOrders,
			formula:formula
		};
		const res = await Users.salary(id, data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			usersGet();
			salaryHide();
		}
	};
	const salaryFormulaShow = () => modalSalaryFormulaOpen();
	const salaryFormulaHide = () => modalSalaryFormulaClose();
	const salaryFormulaSave = async () => {
		const data = {
			formula:formula
		};
		const res = await Users.formula(id, data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			usersGet();
			salaryFormulaHide();
		}
	};
	const formulaRender = () => {
		const out = [], remove = (key) => <div className="remove" onClick={() => formulaParamRemove(key)}>&times;</div>;
		Object.entries(formula).map(([k,v]) => {
			const prefix = ['hours','days','salary'].includes(k) ? '₽' : '%', multi = k === 'salary' ? '=' : <>&times;</>, max = ['hours','days','salary'].includes(k) ? 6 : 2;
			out.push(<div key={k} className="formula-row">
				<div className="formula-row-inner">
					<div>
						{salaryParams[k === 'salary'?'salary':'properties'].find(f => f.key === k).name} {multi}
						<input type="text" onChange={(e) => inputHandler(e, k)} className={k==='salary'?'salary-input':''} maxLength={max} value={v.value} /> {prefix}
					</div>
					{remove(k)}
				</div>
			</div>);
		});
		return out;
	};
	const inputHandler = (e, key) => {
		let value = parseFloat(e.target.value);
		if (isNaN(value) || value <= 0) value = '';
		const f = formula || {};
		f[key] = {value};
		setFormula({...f});
	}
	const salaryTypeSet = (item) => {
		const f = formula || {};
		if (f.salary && f.salary.type === item.key) f.salary = {value:'',type:item.key};
		else {
			let value = '';
			if (item.key === 'salary') value = salary;
			if (item.key === 'salary_hour') value = salaryHour;
			if (item.key === 'salary_day') value = salaryDay;
			f['salary'] = {value,type:item.key};
		}
		setFormula({...f});
	}
	const salaryParamSet = (item) => {
		const f = formula || {};
		f[item.key] = {value:''};
		setFormula({...f});
	};
	const formulaParamRemove = (key) => {
		const f = formula || {};
		delete f[key];
		setFormula({...f});
	};
	const categorySelect = (id) => {
		if (userCategories.includes(id)) setUserCategories(userCategories.filter(f => f !== id));
		else setUserCategories([...userCategories, id]);
	};
	const categoryGet = (id) => categories.find(f => f._id === id)?.name;
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Сотрудники" type="books" />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchUser} value={search} />
										<button type="button" onClick={() => userAdd()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{users.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th className="avatar"></th>
															<th>Имя</th>
															<th className="attach">На заказах</th>
															<th className="status">Статус</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{users.map((v,i) => <tr key={i} onClick={() => userShow(v)} className={id===v._id?'row-selected':''}>
															<td className="avatar">
																<img src={avatarUrlGet(partnerId, pointId, v._id)} alt="" onError={imageError} />
															</td>
															<td>
																{v.name}
																<span>{typesGet(v)}</span>
															</td>
															<td className="attach">{v.isOrderAttach?'Да':'Нет'}</td>
															<td className="status">
																<div className={`status${v.status===userStatus.ACTIVE?v.status:0}`}>
																	<div className="status-point"></div>{userStatusNameWork[v.status]}
																</div>
															</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => userControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			<div className="edit" onClick={(e) => userEdit(e, v)}>Редактировать</div>
																			<div className="receipt" onClick={(e) => salaryShow(e, v)}>Зарплата</div>
																			<div className={v.status===userStatus.ACTIVE?'inactive':'active'} onClick={(e) => v.status === userStatus.ACTIVE ? modalChangeStatusShow(e, v._id) : userChangeStatusOn(e, v._id)}>{v.status===userStatus.ACTIVE?'Отключить':'Включить'}</div>
																		</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте новых<br/>сотрудников
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isShow ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
														<div className="product-view-row-oneline product-view-row-oneline-start">
															<img src={avatarUrlGet(partnerId, pointId, id)} alt="" onError={imageError} className="avatar" />
															<div>
																<h3>{name}</h3>
																{user ? <span className="user-type">{typesGet(user)}</span> : null}
																{user?.birthdate ? <span className="user-type">{Orfo.counters.years(moment().diff(user.birthdate, 'years', false))} ({moment(user.birthdate).format('DD MMMM y')})</span> : null}
															</div>
														</div>
														<img src={imgEdit} alt="" className="edit" onClick={(e) => userEdit(e)} />
													</div>
													{phone ?
														<>
															<h4>Телефон</h4>
															<div className="product-view-row-simple">
																{Utils.phoneFormatter(phone)}
															</div>
														</> : null}
													{comment ?
														<>
															<h4>Комментарий</h4>
															<div className="product-view-row product-view-row-simple">
																<p className="comment">{comment}</p>
															</div>
														</> : null}
													{isOrderAttach && userCategories.length ?
														<>
															<h4>Станции</h4>
															<div className="product-view-row product-view-row-oneline">
																<div>{userCategories.map(v => categoryGet(v)).join(', ')}</div>
															</div>
														</> : null}
													{workStart && workEnd ?
														<>
															<h4>Рабочее время</h4>
															<div className="product-view-row product-view-row-oneline">
																<div>{workStart} – {workEnd}</div>
															</div>
														</> : null}
													<h4>Заработная плата</h4>
													{user?.salary?.salary?.month ?
														<div className="product-view-row product-view-row-oneline">
															<div>Оклад в месяц</div>
															<div>{Utils.moneyFormat(user.salary.salary.month, false)} ₽</div>
														</div> : null}
													{user?.salary?.salary?.hour ?
														<div className="product-view-row product-view-row-oneline">
															<div>Ставка за час</div>
															<div>{user.salary.salary.hour} ₽</div>
														</div> : null}
													{user?.salary?.salary?.day ?
														<div className="product-view-row product-view-row-oneline">
															<div>Ставка за день</div>
															<div>{user.salary.salary.day} ₽</div>
														</div> : null}
													{user?.salary?.percents?.orders ?
														<div className="product-view-row product-view-row-oneline">
															<div>Процент от заказов</div>
															<div>{user.salary.percents.orders} %</div>
														</div> : null}
													{user?.salary?.fixes?.order ?
														<div className="product-view-row product-view-row-oneline">
															<div>Фиксированная сумма за заказ</div>
															<div>{user.salary.fixes.order} ₽</div>
														</div> : null}
													<div className="product-edit-row">
														<button type="button" className="product-edit-btn" onClick={() => salaryShow(null, user)}>Настроить</button>
													</div>
													<h4>График работы</h4>
													<Calendar onChange={(date) => worktimeShow(date)} dates={userWorktime} />
													<div className="product-edit-row">
														<Link to={`/statistics/worktime/${id}`} className="product-edit-btn" onClick={accessShow}>Перейти в табель</Link>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel btn-cancel-wide">Закрыть</button>
											</div>
										</div>
									: null}
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row">
														<input type="text" {...bindName} placeholder="Имя сотрудника" className="input-title" autoFocus={true} required />
													</div>
													<div className="product-edit-row">
														<textarea placeholder="Комментарий" {...bindComment} className="lite"></textarea>
													</div>
													<h4>Фотография</h4>
													<div className="product-edit-row">
														{avatar ?
																<div className="product-edit-images">
																	<div className="product-edit-image">
																		<img src={avatar} alt="" onError={imageError} />
																		<div className="delete" onClick={avatarDelete}></div>
																	</div>
																</div>
															: null}
														<label>
															<div className="product-edit-btn">
																Добавить фото
																<input type="file" accept="image/jpeg,image/png" onChange={handleImage} />
															</div>
														</label>
													</div>
													<h4>
														<div className="product-edit-row-oneline">
															Параметры доступа
															<div className="product-edit-row-switcher product-edit-row-oneline">
																<img src={noAccess?imgCheckOn:imgCheckOff} alt="" onClick={() => setNoAccess(!noAccess)} />
																без доступа
															</div>
														</div>
													</h4>
													{noAccess ?
															accessBlock()
														:
															<>
																<div className="product-edit-row">
																	<input type="email" {...bindEmail} placeholder="Электронная почта" required />
																</div>
																<div className="product-edit-row">
																	<input type="password" {...bindPassword} placeholder="Пароль" required={id==null} />
																</div>
																<div className="product-edit-row">
																	<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder="Телефон" maskPlaceholder={null} {...bindPhone} required />
																</div>
																<h4>Права и доступ</h4>
																<div className="product-edit-row">
																	{access ?
																			(access.length === 1 ?
																					accessBlock()
																				:
																					<div>
																						Комплексный доступ<br/>
																						Для каждого филиала и точки задана должность и роль
																					</div>
																			)
																		:
																			accessBlock()
																	}
																</div>
																<div className="product-edit-row">
																	<button type="button" className="product-edit-btn" onClick={accessShow}>Настроить</button>
																</div>
															</>
													}

													<h4>Выполнения заказов</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label>Привязывать к заказу</label>
														<img src={isOrderAttach ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsOrderAttach(!isOrderAttach)} />
													</div>
													{isOrderAttach ?
														<>
															<h5>Станции</h5>
															<div className="product-edit-row">
																{categories.map((v,i) => <div key={i} className="product-edit-area product-edit-row-oneline-start">
																	<img src={userCategories.find(f => f === v._id)?imgCheckOn:imgCheckOff} alt="" className="checkbox" onClick={() => categorySelect(v._id)} />
																	{v.name}
																</div>)}
															</div>
														</> : null}
													<h4>Рабочее время</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="workStart" className="label-middle">Прибытие</label>
														<input id="workStart" type="time" {...bindWorkStart} placeholder="8:00" />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="workEnd" className="label-middle">Убытие</label>
														<input id="workEnd" type="time" {...bindWorkEnd} placeholder="17:00" />
													</div>
													<h4>Заработная плата</h4>
													<div className="product-edit-row">
														<button type="button" className="product-edit-btn" onClick={() => salaryShow(null, user)}>Настроить</button>
													</div>
													<h4>Дополнительные параметры</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="birthdate" className="label-middle">Дата рождения</label>
														<input id="birthdate" {...bindBirthdate} type="date" placeholder="19.12.2000" />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label>Временный сотрудник, стажер</label>
														<img src={isIntern ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsIntern(!isIntern)} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="status">Статус</label>
														<div className="select select-middle">
															<select id="status" onChange={handleStatus} value={status} required>
																<option value=""></option>
																{userStatusName.map((v,i) => i === 0 ? null : <option key={i} value={i}>{v}</option>)}
															</select>
														</div>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									: null}
								{isShow || isEditShow ? null : <Empty image={'users'} text={<>Для редактирования сотрудника,<br />выберите одного из списка</>} />}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalChangeStatus>
				<div className="modal">
					<div className="header">
						<h4>Отключить сотрудника</h4>
						<img src={imgClose} alt="" onClick={modalChangeStatusHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите отключить сотрудника <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalChangeStatusHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={userChangeStatusModal} className="btn-accept btn-delete">Отключить</button>
					</div>
				</div>
			</ModalChangeStatus>
			<ModalAccess>
				<div className="modal modal-access">
					<div className="header">
						<h4>Права и доступ</h4>
						<img src={imgClose} alt="" onClick={modalAccessHide} className="btn-close" />
					</div>
					<div className="container-inner">
						{points.length === 0 && areas.length === 0 ?
								<div className="points-empty">
									<div>
										<p>Не найдены<br/>филиалы и точки</p>
										<p>Начните с добавления филиала</p>
									</div>
									<Link to={'/books/points'} className="button">Добавить филиал</Link>
								</div>
							:
								<>
									{points.length === 0 ?
										<div className="points-empty">
											<div>
												Не найден<br/>ни один филиал
											</div>
											<Link to={'/books/points'} className="button">Добавить филиал</Link>
										</div> : null}
									{areas.length === 0 ?
										<div className="points-empty">
											<div>
												Не найдена<br/>ни одина точка
											</div>
											<Link to={'/books/areas'} className="button">Добавить точку</Link>
										</div> : null}
								</>
						}
						{points.length && areas.length ?
								points.map((v,i) => <div key={i} className="modal-row-points">
									<h5>{v.name}</h5>
									<div className="type-role">
										<div className="select-block">
											<label htmlFor="type">Должность</label>
											<div className="select">
												<select id="type" onChange={(e) => handleTypeModal(e, v._id)} value={accessTemp?.find(f=>f.point.id===v._id)?.type} disabled={!accessPointCheck(v._id)}>
													<option value=""></option>
													{Object.entries(userType).map(([k,v]) => v === 0 ? null : <option key={k} value={v}>{typeGet(v)}</option>)}
												</select>
											</div>
										</div>
										<div className="select-block">
											<label htmlFor="role">Права</label>
											<div className="select">
												<select id="role" onChange={(e) => handleRoleModal(e, v._id)} value={accessTemp?.find(f=>f.point.id===v._id)?.role?.id} disabled={!accessPointCheck(v._id)}>
													<option value=""></option>
													{roles.filter(f => f.pointId === v._id).map((r,k) => <option key={k} value={r._id}>{r.name}</option>)}
												</select>
											</div>
											<img src={imgPlus} alt="" onClick={roleAddShow} />
										</div>
									</div>
									{areas.filter(f => f.pointId === v._id).map((a,j) => <div key={j} className="modal-row-points-area" onClick={() => accessAreaSet(v._id, a._id)}>
										<img src={accessAreaCheck(a._id)?imgCheckOn:imgCheckOff} alt="" />
										<div>{a.name}</div>
									</div>)}
								</div>)
							:
								null
						}
					</div>
					<div className="buttons">
						<button type="button" onClick={modalAccessHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={accessAdd} className="btn-accept">Добавить</button>
					</div>
				</div>
			</ModalAccess>
			<ModalRolesAdd>
				<div className="modal modal-permissions">
					<div className="header">
						<h4>Новая роль</h4>
						<img src={imgClose} alt="" onClick={modalRolesAddHide} className="btn-close" />
					</div>
					<input type="text" {...bindRoleName} placeholder="Название роли" autoFocus={true} />
					<div className="container-inner">
						{permissionTypeName.map((v,i) => <div key={i} className="permission-item" onClick={() => permissionSet(v.id)}>
							<img src={isInPermission(v.id)?imgCheckOn:imgCheckOff} alt="" />
							{v.name}
						</div>)}
					</div>
					<div className="buttons">
						<button type="button" onClick={modalRolesAddHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={roleSave} className="btn-accept">Сохранить</button>
					</div>
				</div>
			</ModalRolesAdd>
			<ModalDay>
				<div className="modal modal-day">
					<div className="day-info">
						{moment(`${year}-${parseInt(month)+1}-${day}`).format('D MMMM')} <span>{moment(`${year}-${month}-${day}`).format('dddd')}</span>
					</div>
					<div className="header">
						<h4>Установка рабочего времени</h4>
						<img src={imgClose} alt="" onClick={modalDayHide} className="btn-close" />
					</div>
					<form onSubmit={worktimeSave}>
						<div className="container-inner">
							<div className="user-info">
								<b>{user?.name}</b>
								{user ? <span>{typesGet(user)}</span> : null}
							</div>
							<div className="worktime">
								<div className="worktime-row">
									<label>Работает</label>
									с
									<div>
										<input type="time" {...bindStart} required />
									</div>
									до
									<div>
										<input type="time" {...bindEnd} required />
									</div>
								</div>
							</div>
						</div>
						{user?.worktime?.find(f => f.date === `${year}-${month>9?month:`0${month}`}-${day}`) ?
							<div className="worktime-reset" onClick={() => worktimeReset(day)}>&times; Удалить время</div> : null}
						<div className="buttons">
							<button type="button" onClick={modalDayHide} className="btn-cancel">Отменить</button>
							<button type="submit" className="btn-accept">Сохранить</button>
						</div>
					</form>
				</div>
			</ModalDay>
			<ModalSalary>
				<div className="modal modal-salary">
					<div className="header">
						<h4>Расчет заработанной платы</h4>
						<img src={imgClose} alt="" onClick={salaryHide} className="btn-close" />
					</div>
					<form onSubmit={salaryAdd}>
						<div className="user-info">
							<b>{user?.name}</b>
							{user ? <span>{typesGet(user)}</span> : null}
						</div>
						<div className="weights-form">
							<div className="weights-form-row">
								<label htmlFor="brutto">Расчет за месяц<span>сумма за 1 месяц</span></label>
								<div>
									<input type="text" {...bindSalary} placeholder="0" maxLength={6} />
									<span>₽</span>
								</div>
							</div>
							<div className="weights-form-row">
								<label htmlFor="brutto">Расчет по часам<span>сумма за 1 час</span></label>
								<div>
									<input type="text" {...bindSalaryHour} placeholder="0" maxLength={4} />
									<span>₽</span>
								</div>
							</div>
							<div className="weights-form-row">
								<label htmlFor="brutto">Расчет по дням<span>сумма за 1 день (8 ч)</span></label>
								<div>
									<input type="text" {...bindSalaryDay} placeholder="0" maxLength={5} />
									<span>₽</span>
								</div>
							</div>
						</div>
						<div className="weights-form">
							<div className="weights-form-row">
								<label htmlFor="brutto">Процент<span>от выручки по заказам</span></label>
								<div>
									<input id="brutto" type="text" {...bindPercentsOrders} placeholder="0" maxLength={2} />
									<span>%</span>
								</div>
							</div>
							<div className="weights-form-row">
								<label htmlFor="netto">Надбавка за заказ<span>где был исполнителем</span></label>
								<div>
									<input id="netto" type="text" {...bindFixesOrders} placeholder="0" maxLength={4} />
									<span>₽</span>
								</div>
							</div>
						</div>
						<div className="buttons">
							<button type="button" className="btn-cancel btn-select" onClick={() => salaryFormulaShow()}>{'Добавить расчет по формуле'}</button>
						</div>
						<div className="buttons">
							<button type="submit" className="btn-accept">Сохранить</button>
						</div>
					</form>
				</div>
			</ModalSalary>
			<ModalSalaryFormula>
				<div className="modal modal-salary-calc">
					<div className="header">
						<h4>Формула расчета заработной платы</h4>
						<img src={imgClose} alt="" onClick={salaryFormulaHide} className="btn-close" />
					</div>
					<div className="container-inner">
						<div className="params">
							{salaryParams.salary.map((v,i) => <div key={i} className="param" onClick={() => salaryTypeSet(v)}>{v.name}</div>)}
						</div>
						<div className="params">
							{salaryParams.properties.map((v,i) => <div key={i} className="param" onClick={() => salaryParamSet(v)}>{v.name}</div>)}
						</div>
					</div>
					<div className="formula-result">
						<h5>
							Формула
							<span>Сумма составляется из нескольких параметров</span>
						</h5>
						{formula && Object.keys(formula).length ? <code>{formulaRender()}</code> : <>формула не задана</>}
					</div>
					<div className="buttons">
						<button type="button" className="btn-cancel" onClick={salaryFormulaHide}>Отмена</button>
						<button type="button" className="btn-accept" onClick={() => salaryFormulaSave()}>Сохранить</button>
					</div>
				</div>
			</ModalSalaryFormula>
		</>
	);
};

export default UsersScreen;