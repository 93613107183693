import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {Promocodes, Referrals} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { commonStatus, commonStatusName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgClose from './Images/close.svg';

// start
const ReferralsScreen = () => {
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [referrals, setReferrals] = useState([]);
	const [referralsAll, setReferralsAll] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:bonuses, setValue:setBonuses, bind:bindBonuses} = useInput('');
	const {value:sortOrder, setValue:setSortOrder, bind:bindSortOrder} = useInput('');
	const [promocode, setPromocode] = useState('');
	const [promocodes, setPromocodes] = useState([]);
	const [search, setSearch] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [status, setStatus] = useState(commonStatus.ACTIVE);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await promocodesGet();
			await referralsGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const referralsGet = async () => {
		const referrals = await Referrals.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (referrals === undefined) return;
		setReferrals(referrals);
		setReferralsAll(referrals);
	};
	const promocodesGet = async () => {
		const promocodes = await Promocodes.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (promocodes === undefined) return;
		setPromocodes(promocodes);
	};
	const referralShow = (referral) => {
		setControlId(null);
		setId(referral?._id||null);
		setName(referral?.name||'');
		setDescription(referral?.description||'');
		setBonuses(referral?.properties.bonuses||'');
		setPromocode(referral?.properties.promocode?.id||'');
		setSortOrder(referral?.options.sortOrder||'');
		setStatus(referral?referral.status:'');
		setIsEditShow(true);
	};
	const cancel = () => {
		referralShow(null);
		setIsEditShow(false);
		modalDeleteClose();
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			description:description,
			sortOrder:sortOrder,
			status:status
		};
		if (bonuses) data.bonuses = bonuses;
		if (promocode) data.promocode = promocode;
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название уровня');
			return;
		}
		if (Utils.empty(data.bonuses) && Utils.empty(data.promocode)) {
			errorShow('Необходимо заполнить бонусы или промокод');
			return;
		}
		const res = id ?
				await Referrals.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Referrals.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			referralsGet();
			cancel();
		}
	}
	const searchReferral = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setReferrals(referralsAll);
		else setReferrals(referralsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const referralControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const referralDelete = () => {
		Referrals.remove(id).then((res) => {
			successShow(res.message);
			referralsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalDeleteHide = () => modalDeleteClose();
	const modalDeleteShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setName(referrals.find(f => f._id === id).name);
		modalDeleteOpen();
	}
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Реф.программа" type="marketing" />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchReferral} value={search} />
										<button type="button" onClick={() => referralShow()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{referrals.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Название</th>
															<th className="status">Статус</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{referrals.map((v,i) => <tr key={i} onClick={() => referralShow(v)}>
															<td>{v.name}</td>
															<td className="status">
																<div className={`status${v.status}`}>
																	<div className="status-point"></div>{commonStatusName[v.status]}
																</div>
															</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => referralControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			<div className="edit" onClick={() => referralShow(v)}>Редактировать</div>
																			<div className="delete" onClick={(e) => modalDeleteShow(e, v._id)}>Удалить</div>
																		</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте первый<br/>уровень
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row">
														<input type="text" {...bindName} placeholder="Название уровня" className="input-title" autoFocus={true} required />
													</div>
													<div className="product-edit-row">
														<textarea placeholder="Описание" {...bindDescription} className="lite"></textarea>
													</div>
													<h4>Параметры</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="discount" className="label-middle">Бонусы для<br/>начисления</label>
														<input type="text" {...bindBonuses} placeholder="0" />
													</div>
													<div className="product-edit-row">
														<div className="select select-wide">
															<select onChange={(e) => setPromocode(e.target.value)} value={promocode}>
																<option value="">Промокод</option>
																{promocodes.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
															</select>
														</div>
													</div>
													<h4>Дополнительные параметры</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="sortOrder" className="label-middle">Порядок сортировки</label>
														<input id="sortOrder" {...bindSortOrder} type="text" placeholder="0" maxLength={2} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="status">Статус</label>
														<div className="select select-middle">
															<select id="status" onChange={handleStatus} value={status} required>
																<option value=""></option>
																{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
															</select>
														</div>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									:
										<Empty image={'areas'} text={<>Для редактирования уровеня,<br />выберите один из списка</>} />
								}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalDelete>
				<div className="modal">
					<div className="header">
						<h4>Удалить уровень</h4>
						<img src={imgClose} alt="" onClick={modalDeleteHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите удалить уровень <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalDeleteHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={referralDelete} className="btn-accept btn-delete">Удалить</button>
					</div>
				</div>
			</ModalDelete>
		</>
	);
};

export default ReferralsScreen;