import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get		= async ()			=> await Http.request('points');

const byIdGet	= async (id)		=> await Http.request(`point/${id}`);

const add		= async (data)		=> await Http.request('point', httpMethods.POST, data);
const update	= async (id, data)	=> await Http.request(`point/${id}`, httpMethods.PUT, data);

const status	= async (id, data)	=> await Http.request(`point/status/${id}`, httpMethods.PATCH, data);


export {
	get,
	byIdGet,
	add,
	update,
	status
}