import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {Roles} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { permissionTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgClose from './Images/close.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgCheckOff from './Images/checkbox-off.svg';

// start
const RolesScreen = () => {
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [rolesAll, setRolesAll] = useState([]);
	const [roles, setRoles] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:code, setValue:setCode, bind:bindCode} = useInput('');
	const [permissions, setPermissions] = useState([]);
	const [fullAccess, setFullAccess] = useState(false);
	const [search, setSearch] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await rolesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const rolesGet = async () => {
		const roles = await Roles.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (roles === undefined) return;
		setRoles(roles);
		setRolesAll(roles);
	};
	const roleShow = (component) => {
		setControlId(null);
		setId(component?._id||null);
		setName(component?.name||'');
		setDescription(component?.description||'');
		setCode(component?.code||'');
		setPermissions(component?.permissions||[]);
		setFullAccess(component?.fullAccess||false);
		setIsEditShow(true);
	};
	const cancel = () => {
		roleShow(null);
		setIsEditShow(false);
		modalDeleteHide();
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			description:description,
			code:code,
			permissions:permissions,
			fullAccess:fullAccess
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название роли');
			return;
		}
		if (Utils.empty(data.code)) {
			errorShow('Необходимо заполнить код роли');
			return;
		}
		if (!data.fullAccess) {
			if (data.permissions === null || data.permissions.length === 0) {
				errorShow('Для ролей без полного доступа необходимо установить права');
				return;
			}
		}
		const res = id ?
				await Roles.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Roles.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			rolesGet();
			cancel();
		}
	}
	const searchRole = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setRoles(rolesAll);
		else setRoles(rolesAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const roleControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const roleDelete = () => {
		Roles.remove(id).then((res) => {
			successShow(res.message);
			rolesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalDeleteHide = () => modalDeleteClose();
	const modalDeleteShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setName(roles.find(f => f._id === id).name);
		modalDeleteOpen();
	}
	const codeCreate = (e) => {
		const name = e.target.value;
		setCode(Utils.translit(name));
		setName(name);
	}
	const permissionSet = (id) => {
		if (permissions.includes(id)) setPermissions(permissions.filter(f => f !== id));
		else setPermissions([...permissions, id]);
	};
	const isInPermission = (id) => permissions.includes(id);
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Роли" type="books" />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchRole} value={search} />
										<button type="button" onClick={() => roleShow()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{roles.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Название</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{roles.map((v,i) => <tr key={i} onClick={() => roleShow(v)}>
															<td>{v.name}</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => roleControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			<div className="edit" onClick={() => roleShow(v)}>Редактировать</div>
																			<div className="delete" onClick={(e) => modalDeleteShow(e, v._id)}>Удалить</div>
																		</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте первую<br/>роль
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row">
														<input type="text" {...bindName} placeholder="Название роли" className="input-title" autoFocus={true} onChange={codeCreate} required />
													</div>
													<div className="product-edit-row">
														<textarea placeholder="Описание" {...bindDescription} className="lite"></textarea>
													</div>
													<h4>Права доступа</h4>
													<div className="product-edit-row">
														{permissionTypeName.map((v,i) => <div key={i} className="permission-item" onClick={() => permissionSet(v.id)}>
															<img src={isInPermission(v.id)?imgCheckOn:imgCheckOff} alt="" />
															{v.name}
														</div>)}
													</div>
													<h4>Дополнительные параметры</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="code" className="label-middle">Код роли</label>
														<input id="code" {...bindCode} type="text" placeholder="Код роли" />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label>Полный доступ</label>
														<img src={fullAccess ? imgCheckOn : imgCheckOff} alt="" onClick={() => setFullAccess(!fullAccess)} />
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									:
										<Empty image={'components'} text={<>Для редактирования роли,<br />выберите одину из списка</>} />
								}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalDelete>
				<div className="modal">
					<div className="header">
						<h4>Удалить роль</h4>
						<img src={imgClose} alt="" onClick={modalDeleteHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите удалить роль <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalDeleteHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={roleDelete} className="btn-accept btn-delete">Удалить</button>
					</div>
				</div>
			</ModalDelete>
		</>
	);
};

export default RolesScreen;