import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// components
import Sidemenus from '../../Globals/Sidemenus';

// helpers
import {ls} from '../../Globals/Localstorage';

// contstants
import { API } from '../../Globals/Constants';

// images
import imgHelp from './Images/help.svg';

// styles
import './styles.css';

const SideMenu = ({title,type,children}) => {
	const [loading, setLoading] = useState(true);
	const [area, setArea] = useState(null);
	const [user, setUser] = useState(null);
	const [isShow, setIsShow] = useState(false);
	useEffect(async () => {
		const user = ls('user');
		setUser(user);
		const point = ls('point');
		let area = ls('area');
		if (!area) area = point.areas && point.areas.length ? point.areas[0] : null;
		if (area) {
			setArea(area);
			ls('area', area);
		}
		setLoading(false);
	}, []);
	const isselect = (page) => {
		const link = window.location.pathname.split('/');
		if (link.length === 4) {
			link.pop();
			page = page.substring(0, page.length - 1);
		}
		return link.join('/') === page;
	};
	const data = Array.isArray(type) ? type.map((v) => (typeof v === 'object' ? {items:Sidemenus[v.type],title:v.title} : {items:Sidemenus[v]})) : [{items:Sidemenus[type]}];
	const linkGet = () => {
		const link = window.location.pathname.split('/');
		link.shift();
		if (link.length === 3) link.pop();
		return link.join('/');
	};
	const showMenu = () => setIsShow(!isShow);
	const areaSwitch = (area) => {
		const point = user.points.find((v) => v.areas.find((a) => a.id === area.id));
		ls('point', point);
		ls('area', area);
		window.location.reload();
	};
	const areasGet = (ismain) => {
		let point = user.points.find(f => f.areas.find(a => a.id === area.id));
		const areas = [];
		if (ismain) point.areas.forEach((v) => areas.push({id:v.id,name:v.name,point:{id:point.point.id,name:point.point.name}}));
		else {
			const p = user.points.filter(f => f.point.id !== point.point.id);
			p.forEach((v) => {
				v.areas.forEach((a) => areas.push({id:a.id,name:a.name,point:{id:v.point.id,name:v.point.name}}))
			});
		}
		if (ismain) areas.sort((a,b) => a.id === area.id ? -1 : 1);
		return areas;
	}
	return loading ? null :
		<>
			{isShow ? <div className="categories-list-switcher-overlay" onClick={() => setIsShow(false)}></div> : null}
			<div className="components-categories-list">
				<div className="components-categories-list-title">
					<h2>{title}</h2>
					<a href={`${API.help}${linkGet()}`} target='_blank'><img src={imgHelp} alt="" /></a>
				</div>
				{user?.points.length > 1 ?
						<div className="categories-list-switcher" onClick={() => showMenu()}>
							<div className={`categories-list-switcher-menu${isShow?' categories-list-switcher-menu-open':''}`}>
								{isShow ?
										<>
											{areasGet(true).map((v,i) => <div key={i} onClick={() => areaSwitch(v)} className={`categories-list-switcher-menu-item${v.id===area.id?' categories-list-switcher-menu-item-select':''}`}>
													<b>{v.name}</b>
													<span>{v.point.name}</span>
												</div>)}
											{areasGet(false).map((v,i) => <div key={i} onClick={() => areaSwitch(v)} className={`categories-list-switcher-menu-item${v.id===area.id?' categories-list-switcher-menu-item-select':''}`}>
													<b>{v.name}</b>
													<span>{v.point.name}</span>
												</div>)}
										</>
									:
										<div className="categories-list-switcher-menu-item categories-list-switcher-menu-item-single">
											{area?.name}
										</div>
								}
							</div>
						</div>
					: null}
				{children ? children :
					<div className={`categories-list-container${user?.points.length > 1?' categories-list-container-menu':''}`}>
						<div className="container-inner">
							<div className="list">
								{data.map((v,i) => <div key={i}>
									{v.title ? <div className="separator">{v.title}</div> : null}
									{v.items.map((v,i) => <Link key={i} to={v.link} className={`category${isselect(v.link)?' category-select':''}`}>
										{v.name}
									</Link>)}
								</div>)}
							</div>
						</div>
					</div>
				}
			</div>
		</>;
};

export default SideMenu;