import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get		= async (areaId)	=> await Http.request(`cashbox/${areaId}`);

const add		= async (data)		=> await Http.request('cashbox', httpMethods.POST, data);

const remove	= async (id)		=> await Http.request(`cashbox/${id}`, httpMethods.DELETE);


export {
	get,
	add,
	remove
}