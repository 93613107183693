import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get = {
	target		: async (target)	=> await Http.request(`settings/${target}`),
	bulk		: async (codes)	=> await Http.request(`settings/codes`, httpMethods.POST, {codes})
};

const update	= async (id, data)	=> await Http.request(`setting/${id}`, httpMethods.PUT, data);

const admin = {
	add			: async (data)		=> await Http.request('setting/admin', httpMethods.POST, data),
	update		: async (id, data)	=> await Http.request(`setting/admin/${id}`, httpMethods.PUT, data)
};

const imageAdd	= async (id, data)	=> await Http.request(`setting/image/${id}`, httpMethods.POST, data);


export {
	get,
	update,
	admin,
	imageAdd
}