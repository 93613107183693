// styles
import './styles.css';

// img
import img from './images/error.svg';

const Error = (props) => {
	const {title, message} = props;
	const refresh = () => window.location.reload();
	return (
		<div className="error-inner-container">
			<img src={img} alt="Ошибка на сервере" />
			<h4>{title || 'Ошибка на сервере'}</h4>
			<p>
				{message || 
					<>
						На сервере произошла непредвиденная ошибка<br />
						Пожайлуста, подождите она вскоре будет исправлена
					</>
				}
			</p>
			<p>Попробуйте обновить страницу через некоторое время</p>
			<button type="button" onClick={() => refresh()}>Обновить</button>
		</div>
	);
};
export default Error;