import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {Loyalities} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';
import Orfo from '../../../Globals/Orfo';

// constants
import { commonStatus, commonStatusName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgClose from './Images/close.svg';
import imgEdit from './Images/edit.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';


// start
const LoyalitiesScreen = () => {
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [loyalties, setLoyalities] = useState([]);
	const [loyaltiesAll, setLoyalitiesAll] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:discount, setValue:setDiscount, bind:bindDiscount} = useInput('');
	const {value:bonuses, setValue:setBonuses, bind:bindBonuses} = useInput('');
	const {value:days, setValue:setDays, bind:bindDays} = useInput('30');
	const {value:orders, setValue:setOrders, bind:bindOrders} = useInput('');
	const {value:amounts, setValue:setAmounts, bind:bindAmounts} = useInput('');
	const [freeDelivery, setFreeDelivery] = useState(false);
	const [isFirstOrder, setIsFirstOrder] = useState(false);
	const [search, setSearch] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [status, setStatus] = useState(commonStatus.ACTIVE);
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await loyalitiesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const loyalitiesGet = async () => {
		const loyalties = await Loyalities.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (loyalties === undefined) return;
		setLoyalities(loyalties);
		setLoyalitiesAll(loyalties);
	};
	const loyaltyAdd = () => {
		setIsShow(false);
		setIsEditShow(true);
		show();
	}
	const loyaltyEdit = (e, loyalty) => {
		e.stopPropagation();
		loyalty = loyalty || loyalties.find(f => f._id === id);
		show(loyalty);
		setIsShow(false);
		setIsEditShow(true);
	};
	const loyaltyShow = (loyalty) => {
		show(loyalty);
		setIsEditShow(false);
		setIsShow(true);
	}
	const show = (loyalty) => {
		setControlId(null);
		setId(loyalty?._id||null);
		setName(loyalty?.name||'');
		setDescription(loyalty?.description||'');
		setDiscount(loyalty?loyalty.rules.discount:'');
		setBonuses(loyalty?loyalty.rules.bonuses:'');
		setDays(loyalty?loyalty.properties.days:'');
		setFreeDelivery(loyalty?loyalty.rules.freeDelivery:false);
		setIsFirstOrder(loyalty?loyalty.options.isFirstOrder:false);
		setOrders(loyalty?loyalty.options.orders:'');
		setAmounts(loyalty?loyalty.options.amounts:'');
		setStatus(loyalty?loyalty.status:commonStatus.ACTIVE);
		setIsEditShow(true);
	};
	const cancel = () => {
		loyaltyShow(null);
		setIsEditShow(false);
		setIsShow(false);
		modalDeleteHide();
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			description:description,
			discount:discount,
			bonuses:bonuses,
			days:days,
			freeDelivery:freeDelivery,
			orders:orders,
			amounts:amounts,
			isFirstOrder:isFirstOrder,
			status:status
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название программы лояльности');
			return;
		}
		if (Utils.empty(data.discount) && Utils.empty(data.bonuses) && !data.freeDelivery) {
			errorShow('Необходимо заполнить хотя бы одно правило');
			return;
		}
		if (!Utils.empty(data.discount) && (data.discount < 0 || data.discount > 100)) {
			errorShow('Скидка должна быть от 0 до 100');
			return;
		}
		if (Utils.empty(data.orders) && Utils.empty(data.amounts) && !data.isFirstOrder) {
			errorShow('Необходимо заполнить хотя бы одно условие');
			return;
		}
		const res = id ?
				await Loyalities.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Loyalities.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			loyalitiesGet();
			cancel();
		}
	}
	const searchLoyality = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setLoyalities(loyaltiesAll);
		else setLoyalities(loyaltiesAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const loyaltyControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const loyaltyDelete = () => {
		Loyalities.remove(id).then((res) => {
			successShow(res.message);
			loyalitiesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalDeleteHide = () => modalDeleteClose();
	const modalDeleteShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setName(loyalties.find(f => f._id === id).name);
		modalDeleteOpen();
	}
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Прог.лояльности" type="marketing" />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchLoyality} value={search} />
										<button type="button" onClick={() => loyaltyAdd()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{loyalties.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Название</th>
															<th className="weight">Скидка</th>
															<th className="status status-small">Статус</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{loyalties.map((v,i) => <tr key={i} onClick={() => loyaltyShow(v)}>
															<td>{v.name}</td>
															<th className="weight">{v.rules.discount}%</th>
															<td className="status status-small">
																<div className={`status${v.status}`}>
																	<div className="status-point"></div>
																</div>
															</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => loyaltyControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			<div className="edit" onClick={(e) => loyaltyEdit(e, v)}>Редактировать</div>
																			<div className="delete" onClick={(e) => modalDeleteShow(e, v._id)}>Удалить</div>
																		</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте первый статус<br/>программы лояльности
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isShow ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
														<h3>{name}</h3>
														<img src={imgEdit} alt="" className="edit" onClick={(e) => loyaltyEdit(e)} />
													</div>
													{description ? 
														<div className="product-view-row-simple">
															{description}
														</div> : null}
													<h4>Правила</h4>
													<div className="product-view-row product-view-row-oneline">
														<span>Скидка</span>
														<div>{discount?`${discount}%`:'–'}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Кол-во бонусов</span>
														<div>{bonuses?`${bonuses} ₽`:'–'}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Бесплатная доставка</span>
														<div>{freeDelivery?'Да':'Нет'}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Обновление 1 раз в</span>
														<div>{Orfo.counters.days(days)}</div>
													</div>
													<h4>Условия</h4>
													<div className="product-view-row product-view-row-oneline">
														<span>Минимальное кол-во заказов</span>
														<div>{orders?`${orders} ₽`:'–'}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Минимальная сумма заказа</span>
														<div>{amounts?`${amounts} ₽`:'–'}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Первый заказ</span>
														<div>{isFirstOrder?'Да':'Нет'}</div>
													</div>
												</div>
											</div>
										</div>
									: null}
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row">
														<input type="text" {...bindName} placeholder="Название" className="input-title" autoFocus={true} required />
													</div>
													<div className="product-edit-row">
														<textarea placeholder="Описание" {...bindDescription}></textarea>
													</div>
													<h4>Правила</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="discount" className="label-middle">Процент скидки</label>
														<input id="discount" {...bindDiscount} type="text" placeholder="0" maxLength={2} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="bonuses" className="label-middle">Кол-во бонусов</label>
														<input id="bonuses" {...bindBonuses} type="text" placeholder="0" maxLength={5} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label className="label-middle">Бесплатная доставка</label>
														<img src={freeDelivery ? imgCheckOn : imgCheckOff} alt="" onClick={() => setFreeDelivery(!freeDelivery)} />
													</div>
													<h4>Условия</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label className="label-middle">Первый заказ</label>
														<img src={isFirstOrder ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsFirstOrder(!isFirstOrder)} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="orders" className="label-middle">Кол-во заказов</label>
														<input id="orders" {...bindOrders} type="text" placeholder="0" maxLength={4} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="amount" className="label-middle">Сумма заказов</label>
														<input id="amount" {...bindAmounts} type="text" placeholder="0" maxLength={5} />
													</div>
													<h4>Дополнительные параметры</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="days" className="label-middle">Период обновленя, дней</label>
														<input id="days" {...bindDays} type="text" placeholder="0" maxLength={2} required />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="status">Статус</label>
														<div className="select select-middle">
															<select id="status" onChange={handleStatus} value={status} required>
																<option value=""></option>
																{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
															</select>
														</div>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									: null}
								{isShow || isEditShow ? null : <Empty image={'points'} text={<>Для редактирования статуса,<br />выберите один из списка</>} />}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalDelete>
				<div className="modal">
					<div className="header">
						<h4>Удалить  статус</h4>
						<img src={imgClose} alt="" onClick={modalDeleteHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите удалить статус <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalDeleteHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={loyaltyDelete} className="btn-accept btn-delete">Удалить</button>
					</div>
				</div>
			</ModalDelete>
		</>
	);
};

export default LoyalitiesScreen;