import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get = {
	get			: async ()			=> await Http.request('areas'),
	all			: async ()			=> await Http.request('areas/all'),
	coords		: async (lat, lng)	=> await Http.request(`area/coordinates/${lat},${lng}`)
};

const add		= async (data)		=> await Http.request('area', httpMethods.POST, data);
const update	= async (id, data)	=> await Http.request(`area/${id}`, httpMethods.PUT, data);

const status	= async (id, data)	=> await Http.request(`area/status/${id}`, httpMethods.PATCH, data);


export {
	get,
	add,
	update,
	status
}