
const counters = {
	months	: (count) => counterGet(count, ['месяц','месяца','месяцев']),
	days	: (count) => counterGet(count, ['день','дня','дней']),
	years	: (count) => counterGet(count, ['год','года','лет'])
};
const counterGet = (count, titles) => `${count} ${titles[(count % 100 > 4 && count % 100 < 20) ? 2 : [2,0,1,1,1,2][(count % 10 < 5) ? count % 10 :5 ]]}`


export default {
	counters
};