import React from 'react';
import {BrowserRouter,Switch,Route} from 'react-router-dom';

// plug-ins
import 'moment/locale/ru';

// screens
import Start				from './Screens/Start';
import Login				from './Screens/Login';

// books
import Menu					from './Screens/Books/Menu';
import Categories			from './Screens/Books/Categories';
import Clients				from './Screens/Books/Clients';
import Suppliers			from './Screens/Books/Suppliers';
import Workshop				from './Screens/Books/Workshop';
import Components			from './Screens/Books/Components';
import Semifinishes			from './Screens/Books/Semifinishes';
import Reasons				from './Screens/Books/Reasons';
import ExpenseCategories	from './Screens/Books/ExpenseCategories';
import Sources				from './Screens/Books/Sources';
import Statuses				from './Screens/Books/Statuses';
import Users				from './Screens/Books/Users';
import Roles				from './Screens/Books/Roles';

// statistics
import Statistics			from './Screens/Statistics/Statistics';
import WorkTime				from './Screens/Statistics/WorkTime';
import DayShifts			from './Screens/Statistics/DayShifts';

// orders
import OrderAdd				from './Screens/Orders/OrderAdd';
import Orders				from './Screens/Orders/Orders';
import OrdersArchive		from './Screens/Orders/OrdersArchive';

// marketing
import Promocodes			from './Screens/Marketing/Promocodes';
import Loyalities			from './Screens/Marketing/Loyalities';
import LoyalityTransactions	from './Screens/Marketing/LoyalityTransactions';
import Actions				from './Screens/Marketing/Actions';
import Labels				from './Screens/Marketing/Labels';
import Basket				from './Screens/Marketing/Basket';
import Events				from './Screens/Marketing/Events';
import Referrals			from './Screens/Marketing/Referrals';
import Pushes				from './Screens/Marketing/Pushes';
import Sms					from './Screens/Marketing/Sms';
import Feedbacks			from './Screens/Marketing/Feedbacks';

// warehouse
import Invoices				from './Screens/Warehouse/Invoices';
import Writeoffs			from './Screens/Warehouse/Writeoffs';

// money
import Expenses				from './Screens/Money/Expenses';
import Cashbox				from './Screens/Money/Cashbox';
import Salary				from './Screens/Money/Salary';

// account
import Account				from './Screens/Account/Account';
import Areas				from './Screens/Account/Areas';
import DeliveryAreas		from './Screens/Account/DeliveryAreas';
import Points				from './Screens/Account/Points';

// settings
import Settings				from './Screens/Settings/Settings';
import SettingsAdmin		from './Screens/Settings/SettingsAdmin';

// support
import Support				from './Screens/Support/Support';

import Error				from './Screens/Error';

// start
const App = () => (
	<BrowserRouter>
		<Switch>
			<Route path='/' component={Start} exact />
			<Route path='/login' component={Login} exact />

			<Route path='/order-add' component={OrderAdd} exact />
			<Route path='/order-add/:id' component={OrderAdd} exact />
			<Route path='/orders' component={Orders} exact />
			<Route path='/order/:id' component={Orders} exact />
			<Route path='/order-edit/:id' component={OrderAdd} exact />
			<Route path='/orders/archive' component={OrdersArchive} exact />

			<Route path='/statistics' component={Statistics} exact />
			<Route path='/statistics/worktime' component={WorkTime} exact />
			<Route path='/statistics/worktime/:id' component={WorkTime} exact />
			<Route path='/statistics/dayshifts' component={DayShifts} exact />

			<Route path='/books/menu' component={Menu} exact />
			<Route path='/books/menu/:id' component={Menu} exact />
			<Route path='/books/categories' component={Categories} exact />
			<Route path='/books/clients' component={Clients} exact />
			<Route path='/books/client/:id' component={Clients} exact />
			<Route path='/books/suppliers' component={Suppliers} exact />
			<Route path='/books/workshops' component={Workshop} exact />
			<Route path='/books/components' component={Components} exact />
			<Route path='/books/semifinishes' component={Semifinishes} exact />
			<Route path='/books/reasons' component={Reasons} exact />
			<Route path='/books/expensecategories' component={ExpenseCategories} exact />
			<Route path='/books/users' component={Users} exact />
			<Route path='/books/user/:id' component={Users} exact />
			<Route path='/books/sources' component={Sources} exact />
			<Route path='/books/statuses' component={Statuses} exact />
			<Route path='/books/roles' component={Roles} exact />

			<Route path='/marketing/promocodes' component={Promocodes} exact />
			<Route path='/marketing/promocode/:id' component={Promocodes} exact />
			<Route path='/marketing/loyalty' component={Loyalities} exact />
			<Route path='/marketing/loyalty/:id' component={Loyalities} exact />
			<Route path='/marketing/bonuses' component={LoyalityTransactions} exact />
			<Route path='/marketing/actions' component={Actions} exact />
			<Route path='/marketing/action/:id' component={Actions} exact />
			<Route path='/marketing/labels' component={Labels} exact />
			<Route path='/marketing/label/:id' component={Labels} exact />
			<Route path='/marketing/basket' component={Basket} exact />
			<Route path='/marketing/events' component={Events} exact />
			<Route path='/marketing/referrals' component={Referrals} exact />
			<Route path='/marketing/pushes' component={Pushes} exact />
			<Route path='/marketing/sms' component={Sms} exact />
			<Route path='/marketing/feedbacks' component={Feedbacks} exact />

			<Route path='/warehouse/invoices' component={Invoices} exact />
			<Route path='/warehouse/writeoffs' component={Writeoffs} exact />

			<Route path='/money/cashbox' component={Cashbox} exact />
			<Route path='/money/expenses' component={Expenses} exact />
			<Route path='/money/salary' component={Salary} exact />

			<Route path='/account' component={Account} exact />
			<Route path='/account/areas' component={Areas} exact />
			<Route path='/account/deliveryareas' component={DeliveryAreas} exact />
			<Route path='/account/points' component={Points} exact />

			<Route path='/settings' component={Settings} exact />
			<Route path='/settings/:target' component={Settings} exact />
			<Route path='/settings/admin/:target' component={SettingsAdmin} exact />

			<Route path='/support' component={Support} exact />
			<Route path='/support/:id' component={Support} exact />

			<Route path='/error' component={Error} status={500} exact />
			<Route path='/error401' component={Error} status={401} exact />
			<Route path='/error403' component={Error} status={403} exact />
			<Route path='/error404' component={Error} status={404} exact />
			<Route path='*' component={Error} status={404} />
		</Switch>
	</BrowserRouter>
);

export default App;