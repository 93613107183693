const books = [
	{name:'Товары',link:'/books/menu'},
	{name:'Компоненты (сырье)',link:'/books/components'},
	{name:'Категории товаров',link:'/books/categories'},
	{name:'Полуфабрикаты',link:'/books/semifinishes'},
	{name:'Клиенты',link:'/books/clients'},
	{name:'Склады',link:'/books/workshops'},
	{name:'Поставщики',link:'/books/suppliers'},
	{name:'Причины списания',link:'/books/reasons'},
	{name:'Категории расходов',link:'/books/expensecategories'},
	{name:'Источники заказов',link:'/books/sources'},
	{name:'Статусы заказа',link:'/books/statuses'},
	{name:'Сотрудники',link:'/books/users'},
	{name:'Роли',link:'/books/roles'}
]

const marketing = [
	{name:'Акции',link:'/marketing/actions'},
	{name:'Программа лояльности',link:'/marketing/loyalty'},
	{name:'Бонусы',link:'/marketing/bonuses'},
	{name:'Реферальная программа',link:'/marketing/referrals'},
	{name:'Промокоды',link:'/marketing/promocodes'},
	{name:'Правила корзины',link:'/marketing/basket'},
	{name:'События',link:'/marketing/events'},
	{name:'Метки',link:'/marketing/labels'},
	{name:'Отзывы, рейтинг',link:'/marketing/feedbacks'},
	{name:'Пуш-уведомления',link:'/marketing/pushes'},
	{name:'СМС-рассылки',link:'/marketing/sms'}
];

const orders = [
	{name:'Заказы',link:'/orders'},
	{name:'Архив заказов',link:'/orders/archive'}
];

const warehouse = [
	{name:'Накладные',link:'/warehouse/invoices'},
	{name:'Списания',link:'/warehouse/writeoffs'}
];

const money = [
	{name:'Касса',link:'/money/cashbox'},
	{name:'Прочие расходы',link:'/money/expenses'},
	{name:'Заработная плата',link:'/money/salary'},
	// {name:'Операции',link:'/money/operations'},
	// {name:'Транзакции',link:'/money/transactions'},
];

const statistics = [
	{name:'Сводка',link:'/statistics'},
	// {name:'Отчеты',link:'/statistics/reports'},
	{name:'Табель',link:'/statistics/worktime'},
	{name:'Смены',link:'/statistics/dayshifts'}
];

const account = [
	{name:'Профиль',link:'/account'},
	{name:'Филиалы',link:'/account/points'},
	{name:'Точки',link:'/account/areas'},
	{name:'Зоны доставки',link:'/account/deliveryareas'}
];

const settings = [
	{link:'/settings',name:'Общие'},
	{link:'/settings/site',name:'Сайт'},
	{link:'/settings/apps',name:'Мобильные приложения'},
	{link:'/settings/payment',name:'Оплата'},
	{link:'/settings/integrations',name:'Интеграции'},
	{link:'/settings/services',name:'Сервисы'},
	{link:'/settings/messages',name:'Шаблоны сообщений'},
	{link:'/settings/links',name:'Ссылки'},
	{link:'/settings/documents',name:'Документы'}
];

const support = [
	{name:'Служба поддержки',link:'/support'}
];

const Sidemenus = {
	books,
	marketing,
	orders,
	warehouse,
	money,
	statistics,
	account,
	settings,
	support
};
export default Sidemenus;