import Http from '../../Globals/Http';


const get = {
	actual	: async ()			=> await Http.request('statistics'),
	byDate	: async (date)		=> await Http.request(`statistics/${date}`),
	byDates	: async (from, to)	=> await Http.request(`statistics/${from}/${to}`)
};


export {
	get
};