import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get = {
	all			: async ()			=> await Http.request('deliveryareas'),
	coords		: async (lat, lng)	=> await Http.request(`deliveryarea/coordinates/${lat},${lng}`),
	default		: async ()			=> await Http.request('deliveryarea/default')
};

const add		= async (data)		=> await Http.request('deliveryarea', httpMethods.POST, data);
const update	= async (id, data)	=> await Http.request(`deliveryarea/${id}`, httpMethods.PUT, data);

const status	= async (id, data)	=> await Http.request(`deliveryarea/status/${id}`, httpMethods.PATCH, data);

const remove	= async (id)		=> await Http.request(`deliveryarea/${id}`, httpMethods.DELETE);


export {
	get,
	add,
	update,
	status,
	remove
}