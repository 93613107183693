import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {ComponentCategories, Components} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { commonStatusName, measureTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlus from './Images/plus-black.svg';
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveDown from './Images/arrow-curve-down.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgClose from './Images/close.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgEdit from './Images/edit.svg';


// start
const ComponentsScreen = () => {
	const [ModalCategoryAdd, modalCategoryAddOpen, modalCategoryAddClose] = useModal('root', {preventScroll:true});
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [componentsAll, setComponentsAll] = useState([]);
	const [components, setComponents] = useState([]);
	const [category, setCategory] = useState(null);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:weight, setValue:setWeight, bind:bindWeight} = useInput('');
	const {value:price, setValue:setPrice, bind:bindPrice} = useInput('');
	const [invoicePrice, setInvoicePrice] = useState('');
	const {value:minimum, setValue:setMinimum, bind:bindMinimum} = useInput('');
	const {value:requestCount, setValue:setRequestCount, bind:bindRequestCount} = useInput('');
	const [isPieces, setIsPieces] = useState(false);
	const [categoryId, setCategoryId] = useState(null);
	const [categories, setCategories] = useState([]);
	const {value:categoryName, setValue:setCategoryName, bind:bindCategoryName} = useInput('');
	const [search, setSearch] = useState(null);
	const [componentMeasureType, setComponentMeasureType] = useState('');
	const [controlId, setControlId] = useState(null);
	const [status, setStatus] = useState(null);
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const category = await categoriesGet();
			await componentsGet(category);
		};
		await dataGet();
		setLoading(false);
	}, []);
	const componentsGet = async (c) => {
		const components = await Components.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (components === undefined) return;
		setComponents(components);
		setComponentsAll(components);
		componentFilter(components, c||category);
	};
	const categoriesGet = async () => {
		const categories = await ComponentCategories.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (categories === undefined) return;
		setCategories(categories);
		const category = categories[0];
		setCategory(category);
		return category;
	};
	const componentFilter = (components, category) => {
		if (category) {
			const c = components.filter(f => f.category.id === category._id);
		 	setComponents(c);
		}
	}
	const componentAdd = () => {
		setIsShow(false);
		setIsEditShow(true);
		show();
	}
	const componentEdit = (e, component) => {
		e.stopPropagation();
		component = component || components.find(f => f._id === id);
		show(component);
		setIsShow(false);
		setIsEditShow(true);
	};
	const componentShow = (component) => {
		show(component);
		setIsEditShow(false);
		setIsShow(true);
	};
	const show = (component) => {
		setControlId(null);
		setId(component?._id||null);
		setName(component?.name||'');
		setDescription(component?.description||'');
		setPrice(component?.prices.manual||'');
		setInvoicePrice(component?.prices.invoice||'');
		setMinimum(component?.options?.minimum||'');
		setRequestCount(component?.options?.requestCount||'');
		setWeight(component?.weight||'');
		setComponentMeasureType(component?.measure||null);
		setCategoryId(component?.category?.id||null);
		setStatus(component?component.status:'');
		setIsPieces(component?.properties?.isPieces||false);
		setIsEditShow(true);
	};
	const cancel = () => {
		componentShow(null);
		setIsEditShow(false);
		setIsShow(false);
		modalDeleteHide();
		modalCategoryAddClose();
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			categoryId:categoryId,
			name:name,
			description:description,
			price:price,
			requestCount:requestCount,
			minimum:minimum,
			weight:weight,
			measure:componentMeasureType,
			isPieces:isPieces
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название компонента');
			return;
		}
		if (Utils.empty(data.weight)) {
			errorShow('Необходимо заполнить объем / вес компонента');
			return;
		}
		if (data.measure === null) {
			errorShow('Необходимо заполнить единицу измерения компонента');
			return;
		}
		const res = id ?
				await Components.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Components.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			componentsGet();
			cancel();
		}
	}
	const handleMeasureType = (e) => setComponentMeasureType(parseInt(e.target.value));
	const searchComponent = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setComponents(componentsAll);
		else setComponents(componentsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const componentControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const componentDelete = () => {
		Components.remove(id).then((res) => {
			successShow(res.message);
			componentsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalDeleteHide = () => modalDeleteClose();
	const modalDeleteShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setName(components.find(f => f._id === id).name);
		modalDeleteOpen();
	}
	const handleCategory = (e) => setCategoryId(e.target.value);
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const categoryAddShow = (category) => {
		setCategoryName(category?.name||'');
		modalCategoryAddOpen();
	}
	const categorySave = async () => {
		if (Utils.empty(categoryName)) return;
		const data = {name:categoryName};
		const res = await ComponentCategories.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			categoriesGet();
			modalCategoryAddClose();
		}
	}
	const categorySelect = (cat) => {
		if (category) {
			if (category._id === cat._id) {
				setCategory(null);
				setComponents(componentsAll);
				return;
			}
		}
		const components = componentsAll.filter(f => f.category.id === cat._id);
		setCategory(cat);
		setComponents(components);
	}
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="menu-container components-container">
							<div className="categories-container">
								<SideMenu title="Компоненты">
									<div className="categories-list-container">
										<div className="container-inner">
											<div className="list">
												{categories.length ?
														categories.map((v,i) => <div key={i} className={`category${v._id===category?._id?' category-select':''}`} onClick={() => categorySelect(v)}>
															{v.name}
															<span className="category-edit" onClick={() => categoryAddShow(v)}></span>
														</div>)
													:
														<div className="category-empty">
															<div>
																Здесь можно добавить новые категории
															</div>
															<img src={imgArrowCurveDown} alt="" />
														</div>
												}
											</div>
										</div>
										<div className="categories-list-footer">
											<button type="button" onClick={() => categoryAddShow()}>
												<img src={imgPlus} alt="" />
												Добавить категорию
											</button>
										</div>
									</div>
								</SideMenu>
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchComponent} value={search} />
										<button type="button" onClick={() => componentAdd()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{components.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Название</th>
															<th className="prices">Цена</th>
															<th className="weight">Объем / Вес</th>
															<th className="weight">Мин.остаток</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{components.map((v,i) => <tr key={i} onClick={() => componentShow(v)} className={id===v._id?'row-selected':''}>
															<td>{v.name}</td>
															<td className="prices">{v.prices ? `${v.prices?.manual||v.prices?.invoice} ₽` : '–'}</td>
															<td className="weight">{v.weight} {measureTypeName[v.measure]}</td>
															<td className="weight">{v.options?.minimum ? `${v.options.minimum} ${measureTypeName[v.measure]}` : '–'}</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => componentControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			<div className="edit" onClick={(e) => componentEdit(e, v)}>Редактировать</div>
																			<div className="delete" onClick={(e) => modalDeleteShow(e, v._id)}>Удалить</div>
																		</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте первый<br/>компонент
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isShow ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
														<h3>{name}</h3>
														<img src={imgEdit} alt="" className="edit" onClick={(e) => componentEdit(e)} />
													</div>
													{description ?
														<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
													<div className="product-view-row product-view-row-oneline">
														<span>Объем / Вес</span>
														<div>{weight} {measureTypeName[componentMeasureType]}</div>
													</div>
													<h4>Цены</h4>
													<div className="product-view-row product-view-row-oneline">
														<span>Цена из последней накладной</span>
														<div>{invoicePrice?`${invoicePrice} ₽`:'–'}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Цена (коррекция)</span>
														<div>{price?`${price} ₽`:'–'}</div>
													</div>
													<h4>Свойства</h4>
													<div className="product-view-row product-view-row-oneline">
														<span>Штучный, количественный товар</span>
														<div>{isPieces ? 'Да' : 'Нет'}</div>
													</div>
													<h4>Дополнительные параметры</h4>
													<div className="product-view-row product-view-row-oneline">
														<span>Минимальный остаток на складе</span>
														<div>{minimum?`${minimum} ${measureTypeName[componentMeasureType]}`:'–'}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Количество для заявки по умолчанию</span>
														<div>{requestCount?`${requestCount} ${measureTypeName[componentMeasureType]}`:'–'}</div>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel btn-cancel-wide">Закрыть</button>
											</div>
										</div>
									: null}
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row">
														<input type="text" {...bindName} placeholder="Название компонента" className="input-title" autoFocus={true} required />
													</div>
													<div className="product-edit-row">
														<textarea placeholder="Описание" {...bindDescription} className="lite"></textarea>
													</div>
													<div className="product-edit-row product-edit-row-oneline product-edit-row-plus">
														<div className="select select-wide">
															<select onChange={handleCategory} value={categoryId} required>
																<option value="">Выберите категорию</option>
																{categories.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
															</select>
														</div>
														<img src={imgPlusButton} alt="" className="categoty-add" onClick={() => categoryAddShow()} />
													</div>
													<h4>Объем / Вес</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<input type="text" {...bindWeight} placeholder="Объем / Вес" maxLength={4} required />
														<div className="select">
															<select onChange={handleMeasureType} value={componentMeasureType} required>
																{measureTypeName.map((v,i) => <option key={i} value={i===0?'':i}>{v}</option>)}
															</select>
														</div>
													</div>
													<h4>Цены</h4>
													<div className="product-edit-row product-edit-row-oneline product-edit-row-select">
														<label className="label-middle">Цена из последней накладной</label>
														<b>{invoicePrice?`${invoicePrice} ₽`:'–'}</b>
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="price" className="label-middle">Цена (коррекция), ₽</label>
														<input id="price" {...bindPrice} type="text" placeholder="0" maxLength={6} />
													</div>
													<h4>Свойства</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label className="label-middle">Штучный, количественный товар</label>
														<img src={isPieces ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsPieces(!isPieces)} />
													</div>
													<h4>Дополнительные параметры</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="minimum" className="label-middle">Минимальный остаток на складе</label>
														<input id="minimum" {...bindMinimum} type="text" placeholder="0" maxLength={4} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="requestcount" className="label-middle">Количество для заявки по умолчанию</label>
														<input id="requestcount" {...bindRequestCount} type="text" placeholder="0" maxLength={4} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="status">Статус</label>
														<div className="select select-middle">
															<select id="status" onChange={handleStatus} value={status} required>
																<option value=""></option>
																{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
															</select>
														</div>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									: null}
								{isShow || isEditShow ? null : <Empty image={'components'} text={<>Для редактирования компонента,<br />выберите один из списка</>} />}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalCategoryAdd>
				<div className="modal modal-component-add">
					<div className="header">
						<h4>Добавить категорию</h4>
						<img src={imgClose} alt="" onClick={modalCategoryAddClose} className="btn-close" />
					</div>
					<input type="text" {...bindCategoryName} placeholder="Название категории" autoFocus={true} />
					<div className="buttons">
						<button type="button" onClick={modalCategoryAddClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={categorySave} className="btn-accept">Сохранить</button>
					</div>
				</div>
			</ModalCategoryAdd>
			<ModalDelete>
				<div className="modal">
					<div className="header">
						<h4>Удалить компонент</h4>
						<img src={imgClose} alt="" onClick={modalDeleteHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите удалить <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalDeleteHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={componentDelete} className="btn-accept btn-delete">Удалить</button>
					</div>
				</div>
			</ModalDelete>
		</>
	);
};

export default ComponentsScreen;