import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const add		= async (data)		=> await Http.request('salary', httpMethods.POST, data);

const pay		= {
	pay			: async (id, type)	=> await Http.request(`salary/pay/${id}`, httpMethods.PATCH, {type}),
	unpay		: async (id)		=> await Http.request(`salary/unpay/${id}`, httpMethods.PATCH)
};

const remove	= async (id)		=> await Http.request(`salary/${id}`, httpMethods.DELETE);


export {
	add,
	pay,
	remove
}