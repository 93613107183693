import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {ComponentCategories, Components, Semifinishes} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { commonStatusName, measureTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlus from './Images/plus-black.svg';
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgClose from './Images/close.svg';
import imgEdit from './Images/edit.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgArrowCurveDown from './Images/arrow-curve-down.svg';
import imgAdd from './Images/add.svg';


// start
const SemifinishesScreen = () => {
	const componentAmountGet = (t) => {
		const netto = t.id === 'netto' ? t.value : itemNetto || 0;
		const loss = t.id === 'loss' ? t.value : itemLoss || 0;
		const amt = t.id === 'amount' ? t.value : amount || 0;
		const w = netto - loss;
		const a = amt * w;
		setTotalWeight(w > 0 ? Number(w.toFixed(3)) : 0);
		setTotalAmount(a > 0 ? Number(a.toFixed(2)) : 0);
	};
	const [ModalComponent, modalComponentOpen, modalComponentClose] = useModal('root', {preventScroll:true});
	const [ModalItem, modalItemOpen, modalItemClose] = useModal('root', {preventScroll:true});
	const [ModalCategoryAdd, modalCategoryAddOpen, modalCategoryAddClose] = useModal('root', {preventScroll:true});
	const [ModalItemComponents, modalItemComponentsOpen, modalItemComponentsClose] = useModal('root', {preventScroll:true});
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [semifinishesAll, setSemifinishesAll] = useState([]);
	const [semifinishes, setSemifinishes] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:price, setValue:setPrice, bind:bindPrice} = useInput('');
	const {value:minimum, setValue:setMinimum, bind:bindMinimum} = useInput('');
	const {value:requestCount, setValue:setRequestCount, bind:bindRequestCount} = useInput('');
	const [brutto, setBrutto] = useState(0);
	const [netto, setNetto] = useState(0);
	const [items, setItems] = useState([]);
	const {value:amount, setValue:setAmount, bind:bindAmount} = useInput('', componentAmountGet);
	const {value:itemBrutto, setValue:setItemBrutto, bind:bindItemBrutto} = useInput('');
	const {value:itemNetto, setValue:setItemNetto, bind:bindItemNetto} = useInput('', componentAmountGet);
	const {value:itemLoss, setValue:setItemLoss, bind:bindItemLoss} = useInput('', componentAmountGet);
	const [totalWeight, setTotalWeight] = useState(0);
	const [totalAmount, setTotalAmount] = useState(0);
	const [component, setComponent] = useState(null);
	const [components, setComponents] = useState([]);
	const [categoryId, setCategoryId] = useState(null);
	const [categories, setCategories] = useState([]);
	const {value:categoryName, setValue:setCategoryName, bind:bindCategoryName} = useInput('');
	const {value:componentName, setValue:setComponentName, bind:bindComponentName} = useInput('');
	const {value:componentDescription, setValue:setComponentDescription, bind:bindComponentDescription} = useInput('');
	const {value:componentWeight, setValue:setComponentWeight, bind:bindComponentWeight} = useInput('');
	const {value:componentMeasureType, setValue:setComponentMeasureType, bind:bindComponentMeasureType} = useInput('');
	const {value:componentPrice, setValue:setComponentPrice, bind:bindComponentPrice} = useInput('');
	const {value:componentInvoicePrice, setValue:setComponentInvoicePrice, bind:bindComponentInvoicePrice} = useInput('');
	const {value:componentMinimum, setValue:setComponentMinimum, bind:bindComponentMinimum} = useInput('');
	const {value:componentRequestCount, setValue:setComponentRequestCount, bind:bindComponentRequestCount} = useInput('');
	const [componentStatus, setComponentStatus] = useState('');
	const [isPieces, setIsPieces] = useState(false);
	const [search, setSearch] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [status, setStatus] = useState(null);
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await categoriesGet();
			await componentsGet();
			await semifinishesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const categoriesGet = async () => {
		const categories = await ComponentCategories.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (categories === undefined) return;
		setCategories(categories);
	};
	const componentsGet = async () => {
		const components = await Components.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (components === undefined) return;
		setComponents(components);
	};
	const semifinishesGet = async () => {
		const semifinishes = await Semifinishes.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (semifinishes === undefined) return;
		setSemifinishes(semifinishes);
		setSemifinishesAll(semifinishes);
	};
	const semifinishAdd = () => {
		setIsShow(false);
		setIsEditShow(true);
		show();
	}
	const semifinishEdit = (e, semifinish) => {
		e.stopPropagation();
		semifinish = semifinish || semifinishes.find(f => f._id === id);
		show(semifinish);
		setIsShow(false);
		setIsEditShow(true);
	};
	const semifinishShow = (semifinish) => {
		show(semifinish);
		setIsEditShow(false);
		setIsShow(true);
	};
	const show = (semifinish) => {
		setControlId(null);
		setId(semifinish?._id||null);
		setName(semifinish?.name||'');
		setDescription(semifinish?.description||'');
		setPrice(semifinish?.prices?.manual||'');
		setMinimum(semifinish?.options?.minimum||'');
		setRequestCount(semifinish?.options?.requestCount||'');
		setBrutto(semifinish?.properties?.brutto||0);
		setNetto(semifinish?.properties?.netto||0);
		setItems(semifinish?.items||[]);
		setStatus(semifinish?semifinish.status:'');
		setIsEditShow(true);
	};
	const cancel = () => {
		semifinishShow(null);
		setIsEditShow(false);
		setIsShow(false);
		modalDeleteHide();
		modalCategoryAddClose();
		modalItemComponentsClose();
		modalItemClose();
		modalComponentClose();
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			description:description,
			price:price,
			calc:calcPriceGet(),
			brutto:calcBruttoGet(),
			netto:calcNettoGet(),
			items:items,
			requestCount:requestCount,
			minimum:minimum
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название полуфабриката');
			return;
		}
		if (!data.items || data.items.length === 0) {
			errorShow('Необходимо добавить компоненты');
			return;
		}
		const res = id ?
				await Semifinishes.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Semifinishes.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			semifinishesGet();
			cancel();
		}
	}
	const searchSemifinish = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setSemifinishes(semifinishesAll);
		else setSemifinishes(semifinishesAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const componentControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const semifinishDelete = () => {
		Semifinishes.remove(id).then((res) => {
			successShow(res.message);
			semifinishesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalDeleteHide = () => modalDeleteClose();
	const modalDeleteShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setName(semifinishes.find(f => f._id === id).name);
		modalDeleteOpen();
	}
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const handleComponentStatus = (e) => setComponentStatus(parseInt(e.target.value));
	const calcPriceGet = () => Number(items.reduce((acc, v) => acc += parseFloat(v.total), 0).toFixed(2));
	const calcBruttoGet = () => Number(items.reduce((acc, v) => acc += parseFloat(v.brutto), 0).toFixed(3));
	const calcNettoGet = () => Number(items.reduce((acc, v) => acc += parseFloat(v.netto), 0).toFixed(3));
	const componentsItemShow = () => modalItemComponentsOpen();
	const componentSelect = (component) => {
		setComponent(component);
		const amount = component.prices?.manual||component.prices?.invoice||0;
		setAmount(amount);
		modalItemComponentsClose();
	}
	const componentAdd = async (e) => {
		e.preventDefault();
		if (!component) {
			errorShow('Необходимо выбрать компонент');
			return;
		}
		const data = {
			id:component._id,
			name:component.name,
			brutto:parseFloat(itemBrutto),
			netto:parseFloat(itemNetto),
			loss:parseFloat(itemLoss),
			amount:parseFloat(amount),
			weight:totalWeight,
			total:totalAmount
		};
		if (Utils.empty(data.brutto) || Utils.empty(data.netto) || Utils.empty(data.loss) || Utils.empty(data.amount)) {
			errorShow('Необходимо заполнить все поля');
			return;
		}
		setItems([...items, data]);
		modalItemClose();
	}
	const categoryAddShow = () => {
		setCategoryName('');
		modalCategoryAddOpen();
	}
	const categorySave = async () => {
		if (Utils.empty(categoryName)) return;
		const data = {name:categoryName};
		const res = await ComponentCategories.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			categoriesGet();
			modalCategoryAddClose();
		}
	}
	const componentAddShow = () => {
		setComponentName('');
		setComponentDescription('');
		setComponentPrice('');
		setComponentInvoicePrice('');
		setComponentMinimum('');
		setComponentRequestCount('');
		setComponentWeight('');
		setComponentMeasureType('');
		setIsPieces(false);
		setComponentStatus('');
		modalComponentOpen();
	}
	const handleCategory = (e) => setCategoryId(e.target.value);
	const handleMeasureType = (e) => setComponentMeasureType(parseInt(e.target.value));
	const itemShow = () => {
		setAmount('');
		setItemBrutto('');
		setItemNetto('');
		setItemLoss('');
		setTotalWeight(0);
		setTotalAmount(0);
		setComponent(null);
		setCategoryId(null);
		modalItemOpen();
	}
	const componentSave = async (e) => {
		e.preventDefault();
		const data = {
			categoryId:categoryId,
			name:componentName,
			description:componentDescription,
			price:componentPrice,
			requestCount:componentRequestCount,
			minimum:componentMinimum,
			weight:componentWeight,
			measure:componentMeasureType,
			isPieces:isPieces
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название компонента');
			return;
		}
		if (Utils.empty(data.weight)) {
			errorShow('Необходимо заполнить объем / вес компонента');
			return;
		}
		if (data.measure === null) {
			errorShow('Необходимо заполнить единицу измерения компонента');
			return;
		}
		const res = id ?
				await Components.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Components.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			componentsGet();
			modalComponentClose();
		}
	}
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Полуфабрикаты" type="books" />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchSemifinish} value={search} />
										<button type="button" onClick={() => semifinishAdd()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{semifinishes.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Название</th>
															<th className="weight">Объем / Вес</th>
															<th className="prices">Цена</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{semifinishes.map((v,i) => <tr key={i} onClick={() => semifinishShow(v)}>
															<td>{v.name}</td>
															<th className="weight">{v.properties.netto} кг</th>
															<td className="prices">{v.prices ? `${v.prices?.manual||v.prices?.invoice} ₽` : '–'}</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => componentControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			<div className="edit" onClick={(e) => semifinishEdit(e, v)}>Редактировать</div>
																			<div className="delete" onClick={(e) => modalDeleteShow(e, v._id)}>Удалить</div>
																		</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте первый<br/>полуфабрикат
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isShow ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
														<h3>{name}</h3>
														<img src={imgEdit} alt="" className="edit" onClick={(e) => semifinishEdit(e)} />
													</div>
													{description ?
														<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
													<h4>Компоненты</h4>
													{items.length ?
															<div className="product-edit-components-block">
																{items.map((v,i) => <div key={i} className="product-edit-component-item">
																	<div className="product-edit-component-item-name">
																		<b>{v.name}</b>
																	</div>
																	<div className="info">
																		<div>брутто: {v.brutto}кг • нетто: {v.netto}кг • потери: {v.loss}кг</div>
																		<div><b>вес: {v.weight}кг • цена: {v.total}₽</b></div>
																	</div>
																</div>)}
															</div>
														: null}
													<h4>Цены</h4>
													<div className="product-view-row product-view-row-oneline">
														<span>Цена (коррекция)</span>
														<div>{price?`${price} ₽`:'–'}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Расчетная цена</span>
														<div>{calcPriceGet()} ₽</div>
													</div>
													<h4>Свойства</h4>
													<div className="product-view-row product-view-row-oneline">
														<span>Брутто</span>
														<div>{brutto} кг</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Нетто</span>
														<div>{netto} кг</div>
													</div>
													<h4>Дополнительные параметры</h4>
													<div className="product-view-row product-view-row-oneline">
														<span>Минимальный остаток на складе</span>
														<div>{minimum?`${minimum} ${measureTypeName[componentMeasureType]}`:'–'}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Количество для заявки по умолчанию</span>
														<div>{requestCount?`${requestCount} ${measureTypeName[componentMeasureType]}`:'–'}</div>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel btn-cancel-wide">Закрыть</button>
											</div>
										</div>
									: null}
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row">
														<input type="text" {...bindName} placeholder="Название полуфабриката" className="input-title" autoFocus={true} required />
													</div>
													<div className="product-edit-row">
														<textarea placeholder="Описание" {...bindDescription} className="lite"></textarea>
													</div>
													<h4>Компоненты</h4>
													<div className="product-edit-row">
														{items.length ?
																<div className="product-edit-components-block">
																	{items.map((v,i) => <div key={i} className="product-edit-component-item">
																		<div className="product-edit-component-item-name">
																			<b>{v.name}</b>
																			<img src={imgClose} alt="" onClick={() => setItems(items.filter(f => f.id !== v.id))} />
																		</div>
																		<div className="info">
																			<div>брутто: {v.brutto}кг • нетто: {v.netto}кг • потери: {v.loss}кг</div>
																			<div><b>вес: {v.weight}кг • цена: {v.total}₽</b></div>
																		</div>
																	</div>)}
																</div>
															: null
														}
														<div className="product-edit-btn" onClick={() => itemShow()}>Добавить</div>
													</div>
													<h4>Цены</h4>
													<div className="product-edit-row product-edit-row-oneline product-edit-row-select">
														<label className="label-middle">Расчетная цена</label>
														{calcPriceGet().toFixed(2)} ₽
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="price" className="label-middle">Цена (коррекция), ₽</label>
														<input id="price" {...bindPrice} type="text" placeholder="0" maxLength={6} />
													</div>
													<h4>Свойства</h4>
													<div className="product-edit-row product-edit-row-oneline product-edit-row-select">
														<label className="label-middle">Брутто</label>
														{calcBruttoGet().toFixed(3)} кг
													</div>
													<div className="product-edit-row product-edit-row-oneline product-edit-row-select">
														<label className="label-middle">Нетто</label>
														{calcNettoGet().toFixed(3)} кг
													</div>
													<h4>Дополнительные параметры</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="minimum" className="label-middle">Минимальный остаток на складе</label>
														<input id="minimum" {...bindMinimum} type="text" placeholder="0" maxLength={4} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="requestcount" className="label-middle">Количество для заявки по умолчанию</label>
														<input id="requestcount" {...bindRequestCount} type="text" placeholder="0" maxLength={4} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="status">Статус</label>
														<div className="select select-middle">
															<select id="status" onChange={handleStatus} value={status} required>
																<option value=""></option>
																{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
															</select>
														</div>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									: null}
								{isShow || isEditShow ? null : <Empty image={'components'} text={<>Для редактирования полуфабриката,<br />выберите один из списка</>} />}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalItem>
				<div className="modal modal-items">
					<div className="header">
						<h4>Добавить компонент</h4>
						<img src={imgClose} alt="" onClick={modalItemClose} className="btn-close" />
					</div>
					<form onSubmit={componentAdd}>
						{component ?
								<div>
									<b>{component.name}</b> • <span>{component.prices?.manual||component.prices?.invoice||0} ₽ за {component.weight} {measureTypeName[component.measure]}</span>
								</div>
							: null}
						<div className="buttons">
							<button type="button" className="btn-cancel btn-select" onClick={() => componentsItemShow()}>{component ? 'Изменить компонент' : 'Выбрать компонент'}</button>
						</div>
						<div className="weights-form">
							<div className="weights-form-row">
								<label htmlFor="brutto">Цена<span>компонента за 1 кг</span></label>
								<div>
									<input type="text" {...bindAmount} placeholder="0" maxLength={5} required />
									<span>₽</span>
								</div>
							</div>
						</div>
						<div className="weights-form">
							<div className="weights-form-row">
								<label htmlFor="brutto">Брутто<span>вес с упаковкой</span></label>
								<div>
									<input id="brutto" type="text" {...bindItemBrutto} placeholder="0" maxLength={5} required />
									<span>кг</span>
								</div>
							</div>
							<div className="weights-form-row">
								<label htmlFor="netto">Нетто<span>чистый вес</span></label>
								<div>
									<input id="netto" type="text" {...bindItemNetto} placeholder="0" maxLength={5} required />
									<span>кг</span>
								</div>
							</div>
							<div className="weights-form-row">
								<label htmlFor="loss">Потери</label>
								<div>
									<input id="loss" type="text" {...bindItemLoss} placeholder="0" maxLength={5} required />
									<span>кг</span>
								</div>
							</div>
						</div>
						<div className="weights-form">
							<div className="weights-form-row">
								<label>Выход веса</label>
								<div>
									<b>{totalWeight} кг</b>
								</div>
							</div>
							<div className="weights-form-row">
								<label>Сумма</label>
								<div>
									<b>{totalAmount} ₽</b>
								</div>
							</div>
						</div>
						<div className="buttons">
							<button type="submit" className="btn-accept">Добавить</button>
						</div>
					</form>
				</div>
			</ModalItem>
			<ModalItemComponents>
				<div className="modal modal-conditions-lite">
					<div className="header">
						<div className="header-sub">
							<h4>Добавить компонент</h4>
							<img src={imgAdd} alt="" className="add" onClick={() => componentAddShow()} />
						</div>
						<img src={imgClose} alt="" onClick={modalItemComponentsClose} className="btn-close" />
					</div>
					<div className="conditions">
						<div className="sections">
							<div className="inner">
								{categories.length ?
										<div className="list">
											{categories.map((v,i) => <div key={i} className={`section${categoryId===v._id?' section-select':''}`} onClick={() => setCategoryId(v._id)}>
												{v.name}
											</div>)}
										</div>
									:
										<div className="category-empty">
											<div>
												Здесь можно добавить новые категории, чтобы заполнить меню
											</div>
											<img src={imgArrowCurveDown} alt="" />
										</div>
								}
							</div>
							<div className="sections-footer">
								<button type="button" onClick={() => categoryAddShow()}>
									<img src={imgPlus} alt="" />
										Добавить категорию
								</button>
							</div>
						</div>
						<div className="content">
							{components.filter(f => f.category.id === categoryId).length ?
									<div className="components-list">
										{components.filter(f => f.category.id === categoryId).map((v,i) => <div key={i} className="component" onClick={() => componentSelect(v)}>
											{v.name}
											<span>{v.category.name} • {v.prices?.manual||v.prices?.invoice||0} ₽ • {v.weight} {measureTypeName[v.measure]}</span>
										</div>)}
									</div>
								:
									<div className="empty">
										<Empty image={'components'} text={
											<>
												Для добавления компонента,<br />выберите один из списка слева
												<div className="product-add-container">
													<p>или<br/>добавьте новую позицию</p>
													<button type="button" onClick={() => componentAddShow()}>Добавить компонент</button>
												</div>
											</>} />
									</div>
								}
						</div>
					</div>
				</div>
			</ModalItemComponents>
			<ModalComponent>
				<div className="modal modal-component-newadd">
					<div className="header">
						<h4>Добавить компонент</h4>
						<img src={imgClose} alt="" onClick={modalComponentClose} className="btn-close" />
					</div>
					<form className="product-edit" onSubmit={componentSave}>
						<div className="container-inner">
							<div className="list">
								<div className="product-edit-row">
									<input type="text" {...bindComponentName} placeholder="Название компонента" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindComponentDescription} className="lite"></textarea>
								</div>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-plus">
									<div className="select select-wide">
										<select onChange={handleCategory} value={categoryId} required>
											<option value="">Выберите категорию</option>
											{categories.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
									<img src={imgPlusButton} alt="" className="categoty-add" onClick={() => categoryAddShow()} />
								</div>
								<h4>Объем / Вес</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<input type="text" {...bindComponentWeight} placeholder="Объем / Вес" maxLength={4} required />
									<div className="select">
										<select onChange={handleMeasureType} value={componentMeasureType} required>
											{measureTypeName.map((v,i) => <option key={i} value={i===0?'':i}>{v}</option>)}
										</select>
									</div>
								</div>
								<h4>Цены</h4>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-select">
									<label className="label-middle">Цена из последней накладной</label>
									{componentInvoicePrice?`${componentInvoicePrice} ₽`:'–'}
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="price" className="label-middle">Цена (коррекция), ₽</label>
									<input id="price" {...bindComponentPrice} type="text" placeholder="0" maxLength={6} />
								</div>
								<h4>Свойства</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label className="label-middle">Штучный, количественный товар</label>
									<img src={isPieces ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsPieces(!isPieces)} />
								</div>
								<h4>Дополнительные параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="minimum" className="label-middle">Минимальный остаток на складе</label>
									<input id="minimum" {...bindComponentMinimum} type="text" placeholder="0" maxLength={4} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="requestcount" className="label-middle">Количество для заявки по умолчанию</label>
									<input id="requestcount" {...bindComponentRequestCount} type="text" placeholder="0" maxLength={4} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="status">Статус</label>
									<div className="select select-middle">
										<select id="status" onChange={handleComponentStatus} value={componentStatus} required>
											<option value=""></option>
											{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
							</div>
						</div>
						<div className="product-edit-footer">
							<button type="button" onClick={modalComponentClose} className="btn-cancel">Отменить</button>
							<button type="submit" className="btn-save btn-accept">Сохранить</button>
						</div>
					</form>
				</div>
			</ModalComponent>
			<ModalCategoryAdd>
				<div className="modal">
					<div className="header">
						<h4>Добавить категорию</h4>
						<img src={imgClose} alt="" onClick={modalCategoryAddClose} className="btn-close" />
					</div>
					<input type="text" {...bindCategoryName} placeholder="Название категории" autoFocus={true} />
					<div className="buttons">
						<button type="button" onClick={modalCategoryAddClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={categorySave} className="btn-accept">Сохранить</button>
					</div>
				</div>
			</ModalCategoryAdd>
			<ModalDelete>
				<div className="modal">
					<div className="header">
						<h4>Удалить полуфабрикат</h4>
						<img src={imgClose} alt="" onClick={modalDeleteHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите удалить <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalDeleteHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={semifinishDelete} className="btn-accept btn-delete">Удалить</button>
					</div>
				</div>
			</ModalDelete>
		</>
	);
};

export default SemifinishesScreen;