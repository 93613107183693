import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import InputMask from 'react-input-mask';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {Suppliers} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { commonStatusName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgClose from './Images/close.svg';
import imgEdit from './Images/edit.svg';


// start
const SuppliersScreen = () => {
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [suppliersAll, setSuppliersAll] = useState([]);
	const [suppliers, setSuppliers] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:phone, setValue:setPhone, bind:bindPhone} = useInput('');
	const {value:email, setValue:setEmail, bind:bindEmail} = useInput('');
	const {value:address, setValue:setAddress, bind:bindAddress} = useInput('');
	const {value:contact, setValue:setContact, bind:bindContact} = useInput('');
	const {value:inn, setValue:setInn, bind:bindInn} = useInput('');
	const {value:kpp, setValue:setKpp, bind:bindKpp} = useInput('');
	const [search, setSearch] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [status, setStatus] = useState(null);
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await suppliersGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const suppliersGet = async () => {
		const suppliers = await Suppliers.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (suppliers === undefined) return;
		setSuppliers(suppliers);
		setSuppliersAll(suppliers);
	};
	const supplierAdd = () => {
		setIsShow(false);
		setIsEditShow(true);
		show();
	}
	const supplierEdit = (e, supplier) => {
		e.stopPropagation();
		supplier = supplier || suppliers.find(f => f._id === id);
		show(supplier);
		setIsShow(false);
		setIsEditShow(true);
	};
	const supplierShow = (supplier) => {
		show(supplier);
		setIsEditShow(false);
		setIsShow(true);
	};
	const show = (supplier) => {
		setControlId(null);
		setId(supplier?._id||null);
		setName(supplier?.name||'');
		setDescription(supplier?.description||'');
		setPhone(supplier?.contacts.phone||'');
		setEmail(supplier?.contacts.email||'');
		setContact(supplier?.contacts.contact||'');
		setAddress(supplier?.requisites.address||'');
		setInn(supplier?.requisites.inn||'');
		setKpp(supplier?.requisites.kpp||'');
		setStatus(supplier?supplier.status:'');
		setIsEditShow(true);
	};
	const cancel = () => {
		supplierShow(null);
		setIsEditShow(false);
		setIsShow(false);
		modalDeleteHide();
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			description:description,
			phone:phone,
			email:email,
			contact:contact,
			address:address,
			inn:inn,
			kpp:kpp,
			status:status
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить наименование поставщика');
			return;
		}
		const res = id ?
				await Suppliers.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Suppliers.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			suppliersGet();
			cancel();
		}
	}
	const searchSuppliers = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setSuppliers(suppliersAll);
		else setSuppliers(suppliersAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const supplierControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const supplierDelete = () => {
		Suppliers.remove(id).then((res) => {
			successShow(res.message);
			suppliersGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalDeleteHide = () => modalDeleteClose();
	const modalDeleteShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setName(suppliers.find(f => f._id === id).name);
		modalDeleteOpen();
	}
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Поставщики" type="books" />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchSuppliers} value={search} />
										<button type="button" onClick={() => supplierAdd()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{suppliers.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Название</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{suppliers.map((v,i) => <tr key={i} onClick={() => supplierShow(v)}>
															<td>{v.name}</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => supplierControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			<div className="edit" onClick={(e) => supplierEdit(e, v)}>Редактировать</div>
																			<div className="delete" onClick={(e) => modalDeleteShow(e, v._id)}>Удалить</div>
																		</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте первого<br/>поставщика
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isShow ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
														<h3>{name}</h3>
														<img src={imgEdit} alt="" className="edit" onClick={(e) => supplierEdit(e)} />
													</div>
													{description ?
														<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
													{phone || email || contact ? <h4>Контакты</h4> : null}
													{phone ?
														<div className="product-view-row product-view-row-oneline">
															<span>Телефон</span>
															<div>{Utils.phoneFormatter(phone)}</div>
														</div> : null}
													{email ?
														<div className="product-view-row product-view-row-oneline">
															<span>E-mail</span>
															<div>{email}</div>
														</div> : null}
													{contact ?
														<div className="product-view-row product-view-row-oneline">
															<span>Контактное лицо</span>
															<div>{contact}</div>
														</div> : null}
													{address || inn || kpp ? <h4>Реквизиты</h4> : null}
													{address ?
														<div className="product-view-row product-view-row-oneline">
															<span>Адрес</span>
															<div>{address}</div>
														</div> : null}
													{inn ?
														<div className="product-view-row product-view-row-oneline">
															<span>ИНН</span>
															<div>{inn}</div>
														</div> : null}
													{kpp ?
														<div className="product-view-row product-view-row-oneline">
															<span>КПП</span>
															<div>{kpp}</div>
														</div> : null}
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel btn-cancel-wide">Закрыть</button>
											</div>
										</div>
									: null}
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row">
														<input type="text" {...bindName} placeholder="Наименование поставщика" className="input-title" autoFocus={true} required />
													</div>
													<div className="product-edit-row">
														<textarea placeholder="Описание" {...bindDescription}></textarea>
													</div>
													<h4>Контакты</h4>
													<div className="product-edit-row">
														<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder="Телефон" maskPlaceholder={null} {...bindPhone} />
													</div>
													<div className="product-edit-row">
														<input type="email" placeholder="E-mail" {...bindEmail} />
													</div>
													<div className="product-edit-row">
														<input type="text" placeholder="Контактное лицо" {...bindContact} />
													</div>
													<h4>Реквизиты</h4>
													<div className="product-edit-row">
														<input type="text" placeholder="Адрес" {...bindAddress} />
													</div>
													<div className="product-edit-row">
														<input type="text" placeholder="ИНН" {...bindInn} maxLength={12} />
													</div>
													<div className="product-edit-row">
														<input type="text" placeholder="КПП" {...bindKpp} maxLength={9} />
													</div>
													<h4>Дополнительные параметры</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="status">Статус</label>
														<div className="select select-middle">
															<select id="status" onChange={handleStatus} value={status} required>
																<option value=""></option>
																{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
															</select>
														</div>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									: null}
								{isShow || isEditShow ? null : <Empty image={'caregories'} text={<>Для редактирования поставщика,<br />выберите одного из списка</>} />}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalDelete>
				<div className="modal">
					<div className="header">
						<h4>Удалить поставщика</h4>
						<img src={imgClose} alt="" onClick={modalDeleteHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите удалить <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalDeleteHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={supplierDelete} className="btn-accept btn-delete">Удалить</button>
					</div>
				</div>
			</ModalDelete>
		</>
	);
};

export default SuppliersScreen;