import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import InputMask from 'react-input-mask';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {Areas, Workshops} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { commonStatusName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgClose from './Images/close.svg';
import imgEdit from './Images/edit.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';


// start
const WorkshopsScreen = () => {
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [areas, setAreas] = useState([]);
	const [workshopsAll, setWorkshopsAll] = useState([]);
	const [workshops, setWorkshops] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:phone, setValue:setPhone, bind:bindPhone} = useInput('');
	const {value:address, setValue:setAddress, bind:bindAddress} = useInput('');
	const {value:contact, setValue:setContact, bind:bindContact} = useInput('');
	const [workshopAreas, setWorkshopAreas] = useState([]);
	const [search, setSearch] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [status, setStatus] = useState(null);
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await areasGet();
			await workshopsGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const areasGet = async () => {
		const areas = await Areas.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (areas === undefined) return;
		setAreas(areas);
	};
	const workshopsGet = async () => {
		const workshops = await Workshops.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (workshops === undefined) return;
		setWorkshops(workshops);
		setWorkshopsAll(workshops);
	};
	const workshopAdd = () => {
		setIsShow(false);
		setIsEditShow(true);
		show();
	}
	const workshopEdit = (e, workshop) => {
		e.stopPropagation();
		workshop = workshop || workshops.find(f => f._id === id);
		show(workshop);
		setIsShow(false);
		setIsEditShow(true);
	};
	const workshopShow = (workshop) => {
		show(workshop);
		setIsEditShow(false);
		setIsShow(true);
	};
	const show = (workshop) => {
		setControlId(null);
		setId(workshop?._id||null);
		setName(workshop?.name||'');
		setDescription(workshop?.description||'');
		setPhone(workshop?.contacts.phone||'');
		setContact(workshop?.contacts.contact||'');
		setAddress(workshop?.address||'');
		setWorkshopAreas(workshop ? (workshop.areas?.length ? areas.filter(f => workshop.areas.includes(f._id)) : []) : []);
		setStatus(workshop?workshop.status:'');
		setIsEditShow(true);
	};
	const cancel = () => {
		workshopShow(null);
		setIsEditShow(false);
		setIsShow(false);
		modalDeleteHide();
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			description:description,
			areas:workshopAreas.length ? workshopAreas.map(f => f._id) : null,
			phone:phone,
			contact:contact,
			address:address,
			status:status
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название склада');
			return;
		}
		const res = id ?
				await Workshops.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Workshops.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			workshopsGet();
			cancel();
		}
	}
	const searchWorkshops = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setWorkshops(workshopsAll);
		else setWorkshops(workshopsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const workshopControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const workshopDelete = () => {
		Workshops.remove(id).then((res) => {
			successShow(res.message);
			workshopsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalDeleteHide = () => modalDeleteClose();
	const modalDeleteShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setName(workshops.find(f => f._id === id).name);
		modalDeleteOpen();
	}
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const areaSelect = (id) => {
		if (workshopAreas.find(f => f._id === id)) setWorkshopAreas(workshopAreas.filter(f => f._id !== id));
		else setWorkshopAreas([...workshopAreas, areas.find(f => f._id === id)]);
	}
	const areaGet = (a) => {
		const out = [];
		if (a && a.length !== areas.length) {
			for (let i = 0; i < a.length; i++) {
				out.push(areas.find(f => f._id === a[i]).name);
			}
		}
		return out.length === 0 ? 'Все точки' : out.join(', ');
	}
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Склады" type="books" />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchWorkshops} value={search} />
										<button type="button" onClick={() => workshopAdd()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{workshops.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Название</th>
															<th className="area">Точка</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{workshops.map((v,i) => <tr key={i} onClick={() => workshopShow(v)}>
															<td>{v.name}</td>
															<td className="area">{areaGet(v.areas)}</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => workshopControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			<div className="edit" onClick={(e) => workshopEdit(e, v)}>Редактировать</div>
																			<div className="delete" onClick={(e) => modalDeleteShow(e, v._id)}>Удалить</div>
																		</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте первый<br/>склада
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isShow ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
														<h3>{name}</h3>
														<img src={imgEdit} alt="" className="edit" onClick={(e) => workshopEdit(e)} />
													</div>
													{description ?
														<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
													{phone || address || contact ? <h4>Контакты</h4> : null}
													{phone ?
														<div className="product-view-row product-view-row-oneline">
															<span>Телефон</span>
															<div>{Utils.phoneFormatter(phone)}</div>
														</div> : null}
													{contact ?
														<div className="product-view-row product-view-row-oneline">
															<span>Контактное лицо</span>
															<div>{contact}</div>
														</div> : null}
													{address ?
														<div className="product-view-row product-view-row-oneline">
															<span>Адрес</span>
															<div>{address}</div>
														</div> : null}
													<h4>Обслуживание точек</h4>
													<div className="product-view-row">
														{workshopAreas.length === 0 || workshopAreas.length === areas.length ? 'Все точки' : workshopAreas.map((v,i) => <div key={i} className="product-view-area">{v.name}</div>)}
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel btn-cancel-wide">Закрыть</button>
											</div>
										</div>
									: null}
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row">
														<input type="text" {...bindName} placeholder="Название склада" className="input-title" autoFocus={true} required />
													</div>
													<div className="product-edit-row">
														<textarea placeholder="Описание" {...bindDescription}></textarea>
													</div>
													<h4>Обслуживание точек</h4>
													<div className="product-edit-row">
														{areas.map((v,i) => <div key={i} className="product-edit-area product-edit-row-oneline-start">
															<img src={workshopAreas.find(f => f._id === v._id)?imgCheckOn:imgCheckOff} alt="" className="checkbox" onClick={() => areaSelect(v._id)} />
															{v.name}
														</div>)}
													</div>
													<h4>Контакты</h4>
													<div className="product-edit-row">
														<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder="Телефон" maskPlaceholder={null} {...bindPhone} />
													</div>
													<div className="product-edit-row">
														<input type="text" placeholder="Контактное лицо" {...bindContact} />
													</div>
													<div className="product-edit-row">
														<input type="text" placeholder="Адрес" {...bindAddress} />
													</div>
													<h4>Дополнительные параметры</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="status">Статус</label>
														<div className="select select-middle">
															<select id="status" onChange={handleStatus} value={status} required>
																<option value=""></option>
																{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
															</select>
														</div>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									: null}
								{isShow || isEditShow ? null : <Empty image={'caregories'} text={<>Для редактирования склада,<br />выберите один из списка</>} />}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalDelete>
				<div className="modal">
					<div className="header">
						<h4>Удалить склад</h4>
						<img src={imgClose} alt="" onClick={modalDeleteHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите удалить <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalDeleteHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={workshopDelete} className="btn-accept btn-delete">Удалить</button>
					</div>
				</div>
			</ModalDelete>
		</>
	);
};

export default WorkshopsScreen;