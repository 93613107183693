import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import Moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import OrderInfo from '../../../Components/OrderInfo';

// models
import { Addresses, Areas, Clients, Orders, OrderStatuses, Users } from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';
import Orfo from '../../../Globals/Orfo';

// constants
import { orderStatusName, sourceTypeName, paymentTypeName, userType, userTypeName, datePriodName, datePriod } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgClock from './Images/clock-black.svg';
import imgPayment from './Images/payment.svg';
import imgDiscount from './Images/discount.svg';
import imgUser from './Images/user.svg';
import imgBtnCall from './Images/call-btn.svg';
import imgClose from './Images/close.svg';
import imgCutlery from './Images/cutlery.svg';
import imgCutleryMain from './Images/cutlery-main.svg';
import imgPlus from './Images/plus-minus-plus.svg';
import imgMinus from './Images/plus-minus-minus.svg';
import imgLupe from './Images/lupe-btn.svg';
import imgClient from './Images/client-profile.svg';
import imgOrder from './Images/check-btn.svg';
import imgDelete from './Images/delete.svg';
import imgOrderReturn from './Images/return-btn.svg';


// start
const OrdersArchiveScreen = () => {
	const [ModalOrderReturn, modalOrderReturnOpen, modalOrderReturnClose] = useModal('root', {preventScroll:true});
	const [ModalOrders, modalOrdersOpen, modalOrdersClose] = useModal('root', {preventScroll:true});
	const [ModalAddressAdd, modalAddressAddOpen, modalAddressAddClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [loadingInner, setLoadingInner] = useState(true);
	const [orders, setOrders] = useState([]);
	const [ordersAll, setOrdersAll] = useState(null);
	const [ordersSearch, setOrdersSearch] = useState(null);
	const [areas, setAreas] = useState([]);
	const [order, setOrder] = useState(null);
	const [orderStatuses, setOrderStatuses] = useState([]);
	const [addressId, setAddressId] = useState(null);
	const {value:addressName, setValue:setAddressName, bind:bindAddressName} = useInput('');
	const {value:addressRoom, setValue:setAddressRoom, bind:bindAddressRoom} = useInput('');
	const {value:addressFloor, setValue:setAddressFloor, bind:bindAddressFloor} = useInput('');
	const {value:addressEntrance, setValue:setAddressEntrance, bind:bindAddressEntrance} = useInput('');
	const {value:addressIntercom, setValue:setAddressIntercom, bind:bindAddressIntercom} = useInput('');
	const {value:addressComment, setValue:setAddressComment, bind:bindAddressComment} = useInput('');
	const [addresses, setAddresses] = useState([]);
	const [address, setAddress] = useState(null);
	const [client, setClient] = useState(null);
	const [cutlery, setCutlery] = useState(1);
	const [status, setStatus] = useState(null);
	const {value:comment, setValue:setComment, bind:bindComment} = useInput('');
	const [ordersSelected, setOrdersSelected] = useState([]);
	const [search, setSearch] = useState(null);
	const [datePeriod, setDatePeriod] = useState(null);
	const [number, setNumber] = useState(null);
	const [users, setUsers] = useState([]);
	const [tab, setTab] = useState(0);
	const [filter, setFilter] = useState({status:0,paymentType:0,area:'',user:'',date:''});
	const [isClientShow, setIsClientShow] = useState(false);
	const [isSearchShow, setIsSearchShow] = useState(false);
	const [isShow, setIsShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await usersGet();
			await areasGet();
			await ordersGet();
			await orderStatusesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const ordersGet = async () => {
		const orders = await Orders.get.archive().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (orders === undefined) return;
		setOrders(orders);
		setOrdersAll(orders);
	};
	const usersGet = async () => {
		const users = await Users.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (users === undefined) return;
		setUsers(users);
	};
	const areasGet = async () => {
		const areas = await Areas.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (areas === undefined) return;
		setAreas(areas);
	};
	const orderStatusesGet = async () => {
		const statuses = await OrderStatuses.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (statuses === undefined) {
			const statuses = Object.keys(orderStatusName).map((v,i) => ({code:i,name:orderStatusName[v],code:v,color:'#000'})).slice(1);
			setOrderStatuses(statuses);
		} else setOrderStatuses(statuses);
	};
	const statusGet = (code) => orderStatuses.find(f => f.code === code);
	const orderInfoShow = async (order) => {
		setOrder(order);
		setStatus(order.status);
		setIsShow(true);
		if (order.client) {
			setLoadingInner(true);
			clientsGet(order.client.id).catch((ex) => console.log(666, ex));
			setLoadingInner(false);
		}
	}
	const orderInfoClose = () => {
		setOrder(null);
		if (isSearchShow) {
			setIsShow(false);
			setIsClientShow(false);
		} else {
			setSearch('');
			setOrdersSearch(null);
			setIsShow(false);
			setIsSearchShow(false);
			setIsClientShow(false);
			setClient(null);
		}
	}
	const orderReturnShow = (order) => {
		setOrder(order);
		setNumber(order.number);
		modalOrderReturnOpen();
	}
	const orderReturn = async () => {
		const status = order.statuses[order.statuses.length - 2].status;
		const res = await Orders.status(order._id, {status}).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			filterDateApply();
			modalOrderReturnClose();
		}
	}
	const clientsGet = async (id) => {
		const client = await Clients.get.id(id).catch((ex) => console.log(666, ex));
		setClient(client||null);
		if (client) setComment(client.comment||'');
	}
	const modalAddressAddShow = (address) => {
		setAddress(address);
		setAddressId(address?._id||null);
		setAddressName(address?.address||'');
		setAddressRoom(address?.room||'');
		setAddressFloor(address?.floor||'');
		setAddressEntrance(address?.entrance||'');
		setAddressIntercom(address?.intercom||'');
		setAddressComment(address?.comment||'');
		setAddresses([]);
		modalAddressAddOpen();
	}
	const addressAdd = async () => {
		if (Utils.empty(addressName)) {
			errorShow('Необходимо заполнить адрес');
			return;
		}
		if (Utils.empty(addressRoom)) {
			errorShow('Необходимо заполнить квартиру / офис');
			return;
		}
		if (Utils.empty(address) || Utils.empty(address.latitude) || Utils.empty(address.longitude)) {
			errorShow('Необходимо выбрать адрес из списка');
			return;
		}
		const id = order.client.id;
		const data = {
			address:addressName,
			room:addressRoom,
			floor:addressFloor,
			entrance:addressEntrance,
			intercom:addressIntercom,
			comment:addressComment,
			latitude:address.latitude,
			longitude:address.longitude
		};
		const addresses = addressId ? client.addresses.map(v => v._id === addressId ? data : v) : [...client.addresses, data];
		const res = await Clients.address(id, {addresses}).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			clientsGet(id);
			addressClose();
		}
	}
	const addressClose = () => {
		setAddress(null);
		setAddressName('');
		setAddressRoom('');
		setAddressFloor('');
		setAddressEntrance('');
		setAddressIntercom('');
		setAddressComment('');
		modalAddressAddClose();
	}
	const addressGet = async (e) => {
		const address = e.target.value;
		const addresses = await Addresses.get(address).catch((ex) => console.log(666, ex));
		setAddressName(address);
		setAddresses(addresses);
	}
	const selectAddress = (address) => {
		setAddress(address);
		setAddressName(address.address);
		setAddresses([]);
	}
	const ordersTotalGet = (orders, isdiscount) => orders.reduce((a,v) => a + (isdiscount ? v.amounts.amountFull : v.amounts.amount), 0);
	const ordersAverageGet = (orders) => orders.length ? Math.round(ordersTotalGet(orders, true) / orders.length) : 0;
	const ordersMedianGet = (orders) => {
		if (orders.length === 0) return 0;
		const prices = orders.map(v => v.amounts.amountFull);
		prices.sort((a, b) => a - b);
		const mid = Math.floor(prices.length / 2);
		if (prices.length % 2 !== 0) return prices[mid];
		return (prices[mid - 1] + prices[mid]) / 2;
	}
	const modalOrderHistoryShow = () => modalOrdersOpen();
	const searchPanelShow = () => {
		setOrdersSearch(null);
		setIsSearchShow(!isSearchShow);
	}
	const searchOrder = async (e) => {
		const search = e.target.value;
		setSearch(search);
		if (Utils.empty(search)) setOrdersSearch([]);
		else {
			const orders = await Orders.get.search(search).catch((ex) => console.log(666, ex));
			setOrdersSearch(orders||[]);
		}
	}
	const clientShow = async (order) => {
		setLoadingInner(true);
		setIsClientShow(true);
		await clientsGet(order.client.id).catch((ex) => console.log(666, ex));
		setLoadingInner(false);
	};
	const orderItemCreate = (v,i) => <div key={i} className="order-item">
			<div className="controls-row">
				<div className="number">
					<span>
						№ {v.number}
						<div className="tooltip">
							<ul>
								{v.statuses.map((s,i) => <li key={i}>
									<div className="row">
										<div>
											<div className="point" style={{background:statusGet(s.status).color.background}}></div>
											{orderStatusName[s.status]}
										</div>
										<div>{Moment(s.date).format('DD.MM.YYYY HH:mm:ss')}</div>
									</div>
									{s.user ? <div className="user">{s.user.name}</div> : null}
								</li>)}
								<li className="noborder creator">
									<div className="row">
										<div>Заказ создал</div>
										<div>{v.user?.name}</div>
									</div>
								</li>
								<li className="noborder">
									<div className="row">
										<div>Дата создания</div>
										<div>{Moment(v.createdAt).format('DD.MM.YYYY HH:mm:ss')}</div>
									</div>
								</li>
							</ul>
						</div>
					</span>
				</div>
				<div className="status">
					<div className="select" style={{backgroundColor:statusGet(v.status).color.background}}>
						<div style={{color:statusGet(v.status).color.text}}>
							{statusGet(v.status).name}
						</div>
					</div>
				</div>
				<div className="payment-type">
					<div className="select">
						<div style={{color:statusGet(v.status).color.text}}>
							{paymentTypeName[v.properties.paymentType]}
						</div>
					</div>
				</div>
				<div className="block cutlery">
					<img src={imgCutleryMain} alt="" />
					<div>{v.options.cutleries}</div>
				</div>
				{v.client ?
					<div className="block client" onClick={() => clientShow(v)}>
						<img src={imgClient} alt="" />
					</div> : null}
				<div className="workers">
					{v.workers && v.workers.length > 0 ?
							<>
								{v.workers.slice(0,3).map((w,i) => <div key={i} className="worker">
									<span>{typeGet(w.type)}</span>
									<div>{w.name}</div>
								</div>)}
								{v.workers.length > 3 ? <div className="worker worker-more">+{v.workers.length - 3}</div> : null}
							</>
						:
							<div className="worker worker-empty">
								Нет сотрудников
							</div>
					}
				</div>
				<div className="controls">
					{Moment(v.dateFinish).add(1, 'day') > Moment() ? <img src={imgOrderReturn} alt="" onClick={() => orderReturnShow(v)} /> : null}
					<img src={imgOrder} alt="" onClick={() => orderInfoShow(v)} />
				</div>
			</div>
			<div className="order-item-info">
				<div className="more" onClick={() => orderShowMore(v._id)}>
					{isShowMore(v._id) ? 'Скрыть заказ' : 'Показать заказ'}
				</div>
				<div className="price">
					{Utils.moneyFormat(v.amounts.amountFull, true)} ₽
				</div>
				<div className="discount">
					{Utils.moneyFormat(v.discounts.discount, true)} ₽
				</div>
				<div className="delivery">
					{v.properties.isPickup ? 'Самовывоз' : <>Доставка <b>{Utils.moneyFormat(v.amounts.delivery, false)}₽</b></>}
				</div>
				<div className="address">
					{v.properties.isPickup ? <><span>Самовывоз с точки</span> {v.area.name}</> : v.address.address}
				</div>
				<div className="comment">
					{v.comment}
				</div>
			</div>
			{isShowMore(v._id) ?
					<div className="order-item-products">
						{v.products.map((p,i) => <div key={i} className="product">
							<div className="name">{p.name}</div>
							<div className="quantity">{p.quantity} шт.</div>
							<div className="price">{Utils.moneyFormat(p.priceDiscount||p.price, true)} ₽</div>
						</div>)}
						<div className="product">
							<div className="name"><b>Итого</b></div>
							<div className="quantity"></div>
							<div className="price"><b>{Utils.moneyFormat(totalGet(v.products), true)}</b> ₽</div>
						</div>
					</div>
				: null}
		</div>;
	const totalGet = (items) => items.reduce((a,v) => a + (v.priceDiscount||v.price * v.quantity||1), 0);
	const orderShowMore = (id) => {
		if (ordersSelected.includes(id)) {
			const idx = ordersSelected.indexOf(id);
			ordersSelected.splice(idx, 1);
		} else ordersSelected.push(id);
		setOrdersSelected([...ordersSelected]);
	};
	const isShowMore = (id) => ordersSelected.includes(id);
	const clientCommentSave = async (e) => await Clients.patch(client._id, {comment:e.target.value}).catch((ex) => console.log(666, ex));
	const typeGet = (type) => {
		if (type || type !== userType.UNKNOWN) {
			const idx = Object.values(userType).indexOf(type);
			if (idx) return userTypeName[idx];
		}
		return null;
	};
	const filterStatusSet = (e) => {
		const status = parseInt(e.target.value);
		filterApplyFull({...filter,status});
	};
	const filterPaymentTypeSet = (e) => {
		const paymentType = parseInt(e.target.value);
		filterApplyFull({...filter,paymentType});
	};
	const filterAreaSet = (e) => {
		const area = e.target.value;
		filterApplyFull({...filter,area});
	};
	const filterUserSet = (e) => {
		const user = e.target.value;
		filterApplyFull({...filter,user});
	};
	const filterApplyFull = (filter) => {
		setFilter(filter);
		setOrders(filterApply(filter, ordersAll));
	};
	const filterApply = (filter, orders) => {
		if (filter.status) orders = orders.filter(f => f.status === filter.status);
		if (filter.paymentType) orders = orders.filter(f => f.properties.paymentType === filter.paymentType);
		if (filter.area) orders = orders.filter(f => f.area?.id === filter.area);
		if (filter.user) orders = orders.filter(f => f.workers.find(w => w.id === filter.user));
		return orders;
	};
	const filterDateSet = (e) => {
		const type = parseInt(e.target.value);
		setFilter({...filter,date:type});
		filterDateApply(type);
	};
	const filterDateClose = () => {
		setFilter({...filter,date:''});
		filterDateApply(datePriod.TODAY);
	};
	const filterDateGet = (type) => {
		let date = new Date();
		switch (type) {
			case datePriod.TODAY:
				date.setDate(date.getDate());
				break;
			case datePriod.YESTERDAY:
				date.setDate(date.getDate() - 1);
				break;
			default:
				return '';
		}
		return Moment(date).format('DD.MM.YYYY');
	}
	const dateChangeStart = (e) => {
		const p = datePeriod || {};
		p.start = e.target.value;
		setDatePeriod(p);
	};
	const dateChangeEnd = (e) => {
		const p = datePeriod || {};
		p.end = e.target.value;
		setDatePeriod(p);
	};
	const filterDateApply = async (type) => {
		if (type === datePriod.CUSTOM) return;
		let dateStart, dateEnd;
		if (type === undefined && datePeriod) {
			dateStart = datePeriod.start;
			dateEnd = datePeriod.end;
			if (Moment(dateStart) > Moment(dateEnd)) {
				errorShow('Дата начала периода не может быть больше даты окончания');
				return;
			}
			setFilter({...filter,date:100});
		}
		if (type === datePriod.TODAY) dateStart = Moment().format('YYYY-MM-DD');
		if (type === datePriod.YESTERDAY) {
			dateStart = Moment().add(-1, 'day').format('YYYY-MM-DD');
			dateEnd = Moment().add(1, 'day').format('YYYY-MM-DD');
		}
		if (type === datePriod.WEEK) {
			dateStart = Moment().startOf('week').format('YYYY-MM-DD');
			dateEnd = Moment().endOf('week').format('YYYY-MM-DD');
		}
		if (type === datePriod.MONTH) {
			dateStart = Moment().startOf('month').format('YYYY-MM-DD');
			dateEnd = Moment().endOf('month').format('YYYY-MM-DD');
		}
		const orders = await Orders.get.archive({dateStart,dateEnd}).catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (orders === undefined) return;
		if (orders === undefined) return;
		const o = filterApply(filter, orders);
		setOrders(o);
		setOrdersAll(o);
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<>
							<div className="orders-container-archive">
								<div>
									<div className="orders-info-block">
										<span>Заказы <b>{(isSearchShow?ordersSearch||[]:orders).length}</b> шт.</span>
										<span>Средний чек <b>{ordersMedianGet(isSearchShow?ordersSearch||[]:orders)}</b> ₽</span>
										<span>Выручка <b>{ordersTotalGet(isSearchShow?ordersSearch||[]:orders)}</b> ₽</span>
									</div>
									<div className="header">
										<div className="switchers">
											<div className="select select-area">
												<select onChange={filterAreaSet} value={filter.area}>
													<option value="">Все точки</option>
													{areas.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
												</select>
											</div>
											<div className="select">
												<select onChange={filterDateSet} value={filter.date}>
													{datePriodName.map((v,i) => <option key={i} value={i}>{v} {filterDateGet(i)}</option>)}
													{datePeriod ? <option value="100">{Moment(datePeriod.start).format('DD.MM.YYYY')} – {Moment(datePeriod.end).format('DD.MM.YYYY')}</option> : null}
												</select>
												{filter.date === datePriod.CUSTOM ?
													<div className="date">
														<b>Период с</b>
														<input type="date" onChange={dateChangeStart} value={datePeriod?datePeriod.start:Moment().add(-1, 'day').format('YYYY-MM-DD')} />
														<b>по</b>
														<input type="date" onChange={dateChangeEnd} value={datePeriod?datePeriod.end:Moment().format('YYYY-MM-DD')} />
														<div className="btn" onClick={() => filterDateApply()}>Применить</div>
														<img src={imgClose} alt="" onClick={() => filterDateClose()} />
													</div> : null}
											</div>
											<div className="select">
												<select onChange={filterStatusSet} value={filter.status}>
													<option value="0">Все статусы</option>
													{orderStatuses.map((v,i) => <option key={i} value={v.code}>{v.name}</option>)}
												</select>
											</div>
											<div className="select select-paymenttype">
												<select onChange={filterPaymentTypeSet} value={filter.paymentType}>
													<option value="0">Все оплаты</option>
													{paymentTypeName.map((v,i) => i === 0 ? null : <option key={i} value={i}>{v}</option>)}
												</select>
											</div>
											<div className="select">
												<select onChange={filterUserSet} value={filter.user}>
													<option value="">Все сотрудники</option>
													{users.filter(f => f.properties?.isOrderAttach).map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
												</select>
											</div>
										</div>
										<div className="controls">
											{isSearchShow ?
													<input type="search" placeholder="Номер заказа, имя или телефон клиента" className="search" onChange={searchOrder} autoFocus={true} />
												: null}
											<img src={isSearchShow?imgDelete:imgLupe} alt="" className="orders-search-icon" onClick={() => searchPanelShow()} />
										</div>
									</div>
								</div>
								<div className={`main${isSearchShow?(orders.length?'':' main-empty'):(ordersSearch&&ordersSearch.length?'':' main-empty')}`}>
									{isSearchShow ?
											(
												ordersSearch === null ? null :
													(ordersSearch.length ?
															ordersSearch.map((v,i) => orderItemCreate(v,i))
														:
															<div className="empty">
																<Empty image={'orders'} text={'Ничего не найдено'} />
															</div>
													)
											)
										:
											(orders.length > 0 ?
													orders.map((v,i) => orderItemCreate(v, i))
												:
													<div className="empty">
														<Empty image={'orders'} text={<>Нет заказов с<br/>выбранными фильтрами</>} />
													</div>
											)
									}
								</div>
							</div>
							<div className={`order-overlay-archive${isShow || isClientShow ?' order-overlay-archive-show':''}`} onClick={() => orderInfoClose()}></div>
							{isClientShow ?
									<div className="order-info-panel-archive order-info-panel-archive-show">
										<div className="order-info-panel-archive-inner">
											{loadingInner ? <Loader /> :
												<>
													<div className="order-header order-header-wide order-info-oneline-wide">
														<h3>{client.name}</h3>
														<div className="order-info-oneline">
															<a href={`tel:${Utils.phoneNormalize(client.phone)}`}><img src={imgBtnCall} alt="" /></a>
															<div className="order-info-panel-archive-close" onClick={orderInfoClose}>
																<img src={imgClose} alt="" />
															</div>
														</div>
													</div>
													<div className="order-info-main order-info-main-client">
														<div className="order-info-main-inner">
															<div className="order-info-row order-info-oneline-wide">
																<label>Телефон</label>
																<div>{Utils.phoneFormatter(client.phone)}</div>
															</div>
															<div className="order-info-row order-info-oneline-wide">
																<label>Имя</label>
																<div>{client.name}</div>
															</div>
															{client.birthdate ?
																<div className="order-info-row order-info-oneline-wide">
																	<label>Дата рождения</label>
																	<div>{Moment(client.birthdate).format('D MMMM YYYY')} ({Orfo.counters.years(Moment().diff(client.birthdate, 'years', false))})</div>
																</div> : null}
															<div className="order-info-row order-info-oneline-wide">
																<label>Скидка</label>
																<div>{client.discount||0} %</div>
															</div>
															<div className="order-info-row order-info-oneline-wide">
																<label>Дата регистрации</label>
																<div>{Moment(client.createdAt).format('D MMMM YYYY')}</div>
															</div>
															<h4>Комментарий</h4>
															<div className="order-info-row order-info-row-noborder">
																<input type="text" {...bindComment} placeholder="Комментарий" className="input-client" value={comment} onBlur={clientCommentSave} />
															</div>
															<h4>Адреса доставки</h4>
															<div className="order-info-row order-info-row-noborder">
																{client.addresses.length ?
																		client.addresses.map((v,i) => <div key={i} className="order-info-address" onClick={() => modalAddressAddShow(v)}>
																			{v.address}
																		</div>)
																	:
																		<div>Адреса не заданы</div>
																}
															</div>
															<div className="order-info-row order-info-row-noborder">
																<button type="button" className="order-info-btn" onClick={modalAddressAddShow}>Добавить адрес</button>
															</div>
															<h4>Статистика заказов</h4>
															<div className="order-info-row order-info-row-noborder">
																<div className="order-info-orders">
																	<div className="order-info-orderinfo">
																		<span>Количество заказов</span>
																		<div>{client.length}</div>
																	</div>
																	<div className="order-info-orderinfo">
																		<span>Скидка</span>
																		<div>{client.discount ? `${client.discount}%` : '–'}</div>
																	</div>
																	<div className="order-info-orderinfo">
																		<span>Сумма заказов</span>
																		<div>{Utils.moneyFormat(ordersTotalGet(client.orders, true))} ₽</div>
																	</div>
																	<div className="order-info-orderinfo">
																		<span>Срдений чек</span>
																		<div>{Utils.moneyFormat(ordersAverageGet(client.orders))} ₽</div>
																	</div>
																	<div className="order-info-orderinfo">
																		<span>Медианный чек</span>
																		<div>{Utils.moneyFormat(ordersMedianGet(client.orders))} ₽</div>
																	</div>
																	<div className="order-info-orderinfo">
																		<span>Сумма заказов без скидки</span>
																		<div>{Utils.moneyFormat(ordersTotalGet(client.orders, false))} ₽</div>
																	</div>
																</div>
															</div>
															<div className="order-info-row order-info-row-noborder order-info-row-oneline-start">
																<button type="button" className="order-info-btn" onClick={modalOrderHistoryShow}>История заказов</button>
																<Link to={`/order-add/${client._id}`} className="order-info-btn order-info-btn-second">Добавить заказ</Link>
															</div>
														</div>
													</div>
												</>
											}
										</div>
									</div>
								: null}
							{order ?
									<div className={`order-info-panel-archive${isShow?' order-info-panel-archive-show':''}`}>
										<div className="order-info-panel-archive-inner">
											{loadingInner ? <Loader /> :
												<>
													<div className="order-header order-info-oneline-wide">
														<div>
															<h3>Заказ № {order.number}</h3>
															<span><span>{Moment(order.createdAt).format('D MMMM H:mm')}</span> • {order.properties.isPaid ? 'онлайн' : 'не оплачен'}</span>
														</div>
														<div className="select select-status">
															<span>Статус заказа</span>
															<div className="status">
																{statusGet(status).name}
															</div>
														</div>
														<div className="order-info-oneline">
															<a href={`tel:${Utils.phoneNormalize(order.client.phone)}`}><img src={imgBtnCall} alt="" /></a>
															<div className="order-info-panel-archive-close" onClick={orderInfoClose}>
																<img src={imgClose} alt="" />
															</div>
														</div>
													</div>
													<div className="order-tabs order-info-oneline-wide">
														<div className={`order-tab${tab===0?' order-tab-active':''}`} onClick={() => setTab(0)}>Заказ</div>
														<div className={`order-tab${tab===1?' order-tab-active':''}`} onClick={() => setTab(1)}>Клиент</div>
														<div className={`order-tab${tab===2?' order-tab-active':''}`} onClick={() => setTab(2)}>Отметки</div>
													</div>
													<div className={`order-info-main${tab === 0?' order-info-main-order':''}`}>
														{tab === 0 ?
																<>
																	<div className="order-info-oneline order-info-oneline-wrap">
																		{order.client ?
																			<div className="order-props order-props-client order-info-oneline" onClick={() => setTab(1)}>
																				<img src={imgUser} alt="" />
																				{order.client.name}
																			</div> : null}
																		{order.options.date ?
																			<div className="order-props order-props-date order-info-oneline">
																				<img src={imgClock} alt="" />
																				Время доставки: {Moment(order.options.date).format('D MMM H:mm')}
																			</div> : null}
																		<div className="order-props order-props-payment order-info-oneline">
																			<img src={imgPayment} alt="" />
																			{order.properties.isPaid ? 'Оплата картой онлайн' : 'Оплата при получении'}
																		</div>
																		<div className="order-props order-props-discount order-info-oneline">
																			<img src={imgDiscount} alt="" />
																			Размер скидки: {order.discounts.discount||0} ₽
																		</div>
																	</div>
																	<h4>Адрес доставки</h4>
																	<div className="order-info-row order-info-row-noborder">
																		{order.properties.isPickup ? <b>Самовывоз</b> : order.address.address}
																	</div>
																	{order.address.comment ? 
																		<div className="order-info-row order-info-row-noborder">
																			<p><i>{order.address.comment}</i></p>
																		</div> : null}
																	{order.comment ?
																		<>
																			<h4>Комментарий клиента</h4>
																			<div className="order-info-row order-info-row-noborder">
																				<p>{order.address.comment}</p>
																			</div>
																		</> : null}
																	<h4>Позиции</h4>
																	<div className="order-info-row order-info-oneline-wide">
																		<div className="order-info-oneline">
																			<img src={imgCutlery} alt="" />
																			<label>Приборы</label>
																		</div>
																		<div className="order-info-oneline-wide">
																			<div className="order-info-counter order-info-oneline-wide">
																				<img src={imgMinus} alt="" className="inactive" />
																				<div className="quantity">{cutlery}</div>
																				<img src={imgPlus} alt="" className="inactive" />
																			</div>
																			<div className="order-info-price"></div>
																		</div>
																	</div>
																	{order.products.map((v,i) => <div key={i} className="order-info-row order-info-oneline-wide">
																		<div>{v.name}</div>
																		<div className="order-info-oneline-wide">
																			<div className="order-info-counter order-info-oneline-wide">
																				<img src={imgMinus} alt="" className="inactive"/>
																				<div className="quantity">{v.quantity}</div>
																				<img src={imgPlus} alt="" className="inactive"/>
																			</div>
																			<div className="order-info-price">{Utils.moneyFormat(v.priceDiscount||v.price)} ₽</div>
																		</div>
																	</div>)}
																</>
															: null}
														{tab === 1 ?
																<div className="order-info-main-inner">
																	<h4 className="first">Информация о клиенте</h4>
																	{order.client ?
																			<>
																				<div className="order-info-row order-info-oneline-wide">
																					<label>Телефон</label>
																					<div>{Utils.phoneFormatter(order.client.phone)}</div>
																				</div>
																				<div className="order-info-row order-info-oneline-wide">
																					<label>Имя</label>
																					<div>{order.client.name}</div>
																				</div>
																				{order.client.birthdate ?
																					<div className="order-info-row order-info-oneline-wide">
																						<label>Дата рождения</label>
																						<div>{Moment(order.client.birthdate).format('D MMMM YYYY')} ({Orfo.counters.years(Moment().diff(order.client.birthdate, 'years', false))})</div>
																					</div> : null}
																				<div className="order-info-row order-info-oneline-wide">
																					<label>Скидка</label>
																					<div>{order.client.discount||0} %</div>
																				</div>
																				<h4>Комментарий</h4>
																				<div className="order-info-row order-info-row-noborder">
																					<input type="text" {...bindComment} placeholder="Комментарий" className="input-client" value={comment} onBlur={clientCommentSave} />
																				</div>
																				<h4>Адреса доставки</h4>
																				<div className="order-info-row order-info-row-noborder">
																					{client.addresses.length ?
																							client.addresses.map((v,i) => <div key={i} className="order-info-address" onClick={() => modalAddressAddShow(v)}>
																								{v.address}
																							</div>)
																						:
																							<div>Адреса не заданы</div>
																					}
																				</div>
																				<div className="order-info-row order-info-row-noborder">
																					<button type="button" className="order-info-btn" onClick={modalAddressAddShow}>Добавить адрес</button>
																				</div>
																				<h4>Статистика заказов</h4>
																				<div className="order-info-row order-info-row-noborder">
																					<div className="order-info-orders">
																						<div className="order-info-orderinfo">
																							<span>Количество заказов</span>
																							<div>{client.orders.length}</div>
																						</div>
																						<div className="order-info-orderinfo">
																							<span>Скидка</span>
																							<div>{order.client.discount ? `${order.client.discount}%` : '–'}</div>
																						</div>
																						<div className="order-info-orderinfo">
																							<span>Сумма заказов</span>
																							<div>{Utils.moneyFormat(ordersTotalGet(client.orders, true))} ₽</div>
																						</div>
																						<div className="order-info-orderinfo">
																							<span>Срдений чек</span>
																							<div>{Utils.moneyFormat(ordersAverageGet(client.orders))} ₽</div>
																						</div>
																						<div className="order-info-orderinfo">
																							<span>Медианный чек</span>
																							<div>{Utils.moneyFormat(ordersMedianGet(client.orders))} ₽</div>
																						</div>
																						<div className="order-info-orderinfo">
																							<span>Сумма заказов без скидки</span>
																							<div>{Utils.moneyFormat(ordersTotalGet(client.orders, false))} ₽</div>
																						</div>
																					</div>
																				</div>
																				<div className="order-info-row order-info-row-noborder order-info-row-oneline-start">
																					<button type="button" className="order-info-btn" onClick={modalOrderHistoryShow}>История заказов</button>
																					<Link to={`/order-add/${client._id}`} className="order-info-btn order-info-btn-second">Добавить заказ</Link>
																				</div>
																			</>
																		:
																			<div className="order-info-empty">
																				Клиент не указан в заказе
																			</div>
																	}
																</div>
															: null}
														{tab === 2 ?
																<div className="order-info-main-inner">
																	<div className="order-info-row order-info-oneline-wide">
																		<label>Оплата</label>
																		<div>{paymentTypeName[order.properties.paymentType]}</div>
																	</div>
																	<div className="order-info-row order-info-oneline-wide">
																		<label>Источник</label>
																		<div>{sourceTypeName[order.properties.paymentSource]}</div>
																	</div>
																	{order.options?.source ? 
																		<div className="order-info-row order-info-oneline-wide">
																			<label>Откуда пришел заказ</label>
																			<div>{order.options.source.name}</div>
																		</div> : null}
																	{order.options?.shipping ?
																		<div className="order-info-row order-info-oneline-wide">
																			<label>Дата отгрузки</label>
																			<div>{Moment(order.options.shipping).format('DD.MM.YYYY HH:mm:ss')}</div>
																		</div> : null}
																	<div className="order-info-row order-info-oneline-wide">
																		<label>Создан</label>
																		<div>{Moment(order.createdAt).format('DD.MM.YYYY HH:mm:ss')}</div>
																	</div>
																	<div className="order-info-row order-info-oneline-wide">
																		<label>Завершен</label>
																		<div>{Moment(order.dateFinish).format('DD.MM.YYYY HH:mm:ss')}</div>
																	</div>
																</div>
															: null}
													</div>
													{tab === 0 ?
															<div className="order-info-summary">
																<div className="order-info-summary-row order-info-oneline-wide">
																	<label>Сумма заказа</label>
																	<div>{Utils.moneyFormat(order.amounts.amount)} ₽</div>
																</div>
																<div className="order-info-summary-row order-info-oneline-wide">
																	<label>Скидка</label>
																	<div className="discount">– {Utils.moneyFormat(order.discounts.discount)} ₽</div>
																</div>
																<div className="order-info-summary-row order-info-oneline-wide">
																	<label>Доставка{order.properties.isPickup?' (самовывоз)':''}</label>
																	<div>{order.amounts.delivery} ₽</div>
																</div>
																<div className="order-info-summary-row order-info-summary-total order-info-oneline-wide ">
																	<label>Общая сумма</label>
																	<div>{Utils.moneyFormat(order.amounts.amountFull)} ₽</div>
																</div>
															</div>
														: null}
												</>
											}
										</div>
									</div>
								: null}
						</>
					)
				}
			</Template>
			<Alert />
			<ModalAddressAdd>
				<div className="modal modal-address-add">
					<div className="header">
						<h4>{address ? 'Изменить адрес' : 'Добавить новый адрес'}</h4>
						<img src={imgClose} alt="" onClick={addressClose} className="btn-close" />
					</div>
					<div className="container-inner">
						<input type="text" {...bindAddressName} placeholder="Введите адрес (улица и дом)" onChange={addressGet} />
						{addresses.length ?
							<div className="addresses-list">
								{addresses.map((v,i) => <div key={i} onClick={() => selectAddress(v)}>{v.address}</div>)}
							</div> : null}
						<div className="address-form">
							<div>
								<input type="text" {...bindAddressRoom} placeholder="Квартира, офиc" maxLength={5} required />
								<span>кв/офис</span>
							</div>
							<div>
								<input type="text" {...bindAddressEntrance} placeholder="Подъезд" maxLength={3} />
								<span>подъезд</span>
							</div>
							<div>
								<input type="text" {...bindAddressFloor} placeholder="Этаж" maxLength={2} />
								<span>этаж</span>
							</div>
							<div>
								<input type="text" {...bindAddressIntercom} placeholder="Код домофона" maxLength={6} />
								<span>домофон</span>
							</div>
						</div>
						<div className="address-form">
							<textarea {...bindAddressComment} placeholder="Комментарий к адресу"></textarea>
						</div>
					</div>
					<div className="buttons">
						<button type="button" onClick={addressClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={addressAdd} className="btn-accept">Добавить</button>
					</div>
				</div>
			</ModalAddressAdd>
			<ModalOrders>
				<div className="modal modal-orders">
					<div className="header">
						<h4>История заказов</h4>
						<img src={imgClose} alt="" onClick={modalOrdersClose} className="btn-close" />
					</div>
					<div className="container-inner">
						{client?.orders.length > 0 ?
								client.orders.map((v,i) => <OrderInfo key={i} order={v} clientHide={isClientShow} />)
							:
								<div className="orders-empty">
									Заказов пока нет
								</div>
						}
					</div>
				</div>
			</ModalOrders>
			<ModalOrderReturn>
				<div className="modal modal-status-back">
					<div className="header">
						<h4>Вернуть заказ в работу</h4>
						<img src={imgClose} alt="" onClick={modalOrderReturnClose} className="btn-close" />
					</div>
					<p>Вы уверены что хотите вернуть заказ <b>№ {number}</b> в работу?</p>
					<div className="buttons">
						<button type="button" onClick={modalOrderReturnClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={orderReturn} className="btn-accept btn-save">Вернуть</button>
					</div>
				</div>
			</ModalOrderReturn>
		</>
	);
};

export default OrdersArchiveScreen;