import {useState} from 'react';

export const useInput = (initialValue, onChange) => {
	const [value, setValue] = useState(initialValue);
	return {
		value,
		setValue,
		reset: () => setValue(''),
		bind: {
			value,
			onChange: (e) => {
				setValue(e.target.value)
				if (onChange) onChange(e.target);
			}
		}
	};
};