import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import {YMaps,Map,ZoomControl,Polygon} from 'react-yandex-maps';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {Cities, DeliveryAreas, Points} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { commonStatus, commonStatusName, MAP } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgClose from './Images/close.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgCheckOff from './Images/checkbox-off.svg';


// start
const DeliveryAreasScreen = () => {
	const [ModalMap, modalMapOpen, modalMapClose] = useModal('root', {preventScroll:true});
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root', {preventScroll:true});
	const [ModalChangeStatus, modalChangeStatusOpen, modalChangeStatusClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [areasAll, setAreasAll] = useState([]);
	const [areas, setAreas] = useState([]);
	const [point, setPoint] = useState(null);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:color, setValue:setColor, bind:bindColor} = useInput('');
	const {value:area, setValue:setArea, bind:bindArea} = useInput('');
	const {value:price, setValue:setPrice, bind:bindPrice} = useInput('');
	const {value:priceFree, setValue:setPriceFree, bind:bindPriceFree} = useInput('');
	const {value:minimumAmount, setValue:setMinimumAmount, bind:bindMinimumAmount} = useInput('');
	const {value:maxTime, setValue:setMaxTime, bind:bindMaxTime} = useInput('');
	const {value:minTime, setValue:setMinTime, bind:bindMinTime} = useInput('');
	const [alwaysPaid, setAlwaysPaid] = useState(commonStatus.ACTIVE);
	const [workTime, setWorkTime] = useState([]);
	const [cities, setCities] = useState([]);
	const [search, setSearch] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [status, setStatus] = useState(null);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const point = ls('point');
			await pontGet(point.point.id);
			await citiesGet();
			await areasGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const pontGet = async (id) => {
		const point = await Points.byIdGet(id).catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (point === undefined) return;
		setPoint(point);
	};
	const citiesGet = async () => {
		const cities = await Cities.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (cities === undefined) return;
		setCities(cities);
	};
	const areasGet = async () => {
		const areas = await DeliveryAreas.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (areas === undefined) return;
		setAreas(areas);
		setAreasAll(areas);
	};
	const areaShow = (area) => {
		setControlId(null);
		setId(area?._id||null);
		setName(area?.name||'');
		setDescription(area?.description||'');
		setPrice(area?.prices.price||'');
		setPriceFree(area?.prices.priceFree||'');
		setMinimumAmount(area?.prices.minimumAmount||'');
		setMaxTime(area?.maxTime||'');
		setMinTime(area?.minTime||'');
		setAlwaysPaid(area?.alwaysPaid||false);
		setArea(area && area.area ? area.area : []);
		setColor(area?.properties.color||'#00ff00');
		setWorkTime(workTimePrepare(area?.workTime)||[]);
		setStatus(area?area.status:'');
		setIsEditShow(true);
	};
	const cancel = () => {
		areaShow(null);
		setIsEditShow(false);
		modalChangeStatusHide();
		modalDeleteClose();
		modalMapClose();
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			description:description,
			price:price,
			priceFree:priceFree,
			minimumAmount:minimumAmount,
			maxTime:maxTime,
			minTime:minTime,
			alwaysPaid:alwaysPaid,
			area:area,
			color:color||'#00ff00',
			area:area,
			workTime:workTime,
			status:status
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название зоны доставки');
			return;
		}
		if (Utils.empty(data.area)) {
			errorShow('Необходимо заполнить область зоны доставки');
			return;
		}
		const res = id ?
				await DeliveryAreas.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await DeliveryAreas.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			areasGet();
			cancel();
		}
	}
	const searchArea = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setAreas(areasAll);
		else setAreas(areasAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const areaControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const areaChangeStatusOn = (e, id) => {
		e.stopPropagation();
		areaChangeStatus(id);
	}
	const areaChangeStatusModal = () => areaChangeStatus(id);
	const areaChangeStatus = (id) => {
		const area = areas.find(f => f._id === id);
		const status = area.status === commonStatus.ACTIVE ? commonStatus.IN_ACTIVE : commonStatus.ACTIVE;
		DeliveryAreas.status(id, {status}).then((res) => {
			successShow(res.message);
			areasGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalChangeStatusHide = () => modalChangeStatusClose();
	const modalChangeStatusShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setName(areas.find(f => f._id === id).name);
		modalChangeStatusOpen();
	}
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const areaDelete = () => {
		DeliveryAreas.remove(id).then((res) => {
			successShow(res.message);
			areasGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalDeleteHide = () => modalDeleteClose();
	const modalDeleteShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setName(areas.find(f => f._id === id).name);
		modalDeleteOpen();
	}
	const workTimePrepare = (workTime) => {
		workTime = workTime||[];
		const wt = [];
		Utils.weekDayName.full.forEach((v,i) => {
			const start = workTime.find(f => f.day === i)?.start||null;
			const end = workTime.find(f => f.day === i)?.end||null;
			wt.push({day:i,start:start,end:end});
		});
		return wt;
	};
	const wtStartSet = (e, i) => {
		const wt = [...workTime||[]];
		wt[i].start = e.target.value;
		setWorkTime(wt);
	}
	const wtEndSet = (e, i) => {
		const wt = [...workTime||[]];
		wt[i].end = e.target.value;
		setWorkTime(wt);
	}
	const cityCoordinatesGet = () => {
		const city = cities.find(f => f._id === point.cityId);
		const latitude = city?.coordinates?.latitude||MAP.defCoords[0], longitude = city?.coordinates?.longitude||MAP.defCoords[1];
		return [latitude,longitude];
	};
	const mapDraw = (ref) => {
		if (ref) {
			ref.editor.startDrawing();
			ref.geometry.events.add('change', (e) => setArea(e.get('newCoordinates')));
		}
	};
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Зоны доставки" type={[{type:'account'},{type:'settings',title:'Настройки'}]} />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchArea} value={search} />
										<button type="button" onClick={() => areaShow()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{areas.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Название</th>
															<th className="prices">Дост / Беспл / Мин</th>
															<th className="status">Статус</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{areas.map((v,i) => <tr key={i} onClick={() => areaShow(v)} className={id===v._id?'row-selected':''}>
															<td>{v.name}</td>
															<td className="prices">{v.prices.price||'–'} / {v.prices.priceFree||'–'} / {v.prices.minimumAmount||'–'}</td>
															<td className="status">
																<div className={`status${v.status}`}>
																	<div className="status-point"></div>{commonStatusName[v.status]}
																</div>
															</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => areaControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			<div className="edit" onClick={() => areaShow(v)}>Редактировать</div>
																			<div className={v.status===commonStatus.ACTIVE?'inactive':'active'} onClick={(e) => v.status === commonStatus.ACTIVE ? modalChangeStatusShow(e, v._id) : areaChangeStatusOn(e, v._id)}>{v.status===commonStatus.ACTIVE?'Отключить':'Включить'}</div>
																			<div className="delete" onClick={(e) => modalDeleteShow(e, v._id)}>Удалить</div>
																		</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте первую<br/>зону доставки
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row">
														<input type="text" {...bindName} placeholder="Название зоны" className="input-title" autoFocus={true} required />
													</div>
													<div className="product-edit-row">
														<textarea placeholder="Описание" {...bindDescription} className="lite"></textarea>
													</div>
													<h4>Область обслуживания</h4>
													<div className="product-edit-row">
														<div className="product-edit-btn" onClick={() => modalMapOpen()}>{area && area.length ? 'Изменить' : 'Добавить'}</div>
													</div>
													<h4>Условия</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="price" className="label-middle">Стоимость доставки</label>
														<input id="price" {...bindPrice} type="text" placeholder="0" maxLength={3} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="priceFree" className="label-middle">Бесплтано от</label>
														<input id="priceFree" {...bindPriceFree} type="text" placeholder="0" maxLength={5} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="minimumAmount" className="label-middle">Мин. сумма заказа</label>
														<input id="minimumAmount" {...bindMinimumAmount} type="text" placeholder="0" maxLength={6} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="maxTime" className="label-middle">Макс. время доставки (мин.)</label>
														<input id="maxTime" {...bindMaxTime} type="text" placeholder="0" maxLength={3} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="minTime" className="label-middle">Мин. время доставки (мин.)</label>
														<input id="minTime" {...bindMinTime} type="text" placeholder="0" maxLength={3} />
													</div>
													<h4>Время работы</h4>
													<div className="product-edit-row">
														<div className="work-time">
															{Utils.weekDayName.full.map((v,i) => <div key={i} className="product-edit-row product-edit-row-oneline">
																<div>{v}</div>
																<div className="product-edit-row-oneline">
																	<input type="time" className="time" value={workTime[i]?.start||''} onChange={(e) => wtStartSet(e, i)} />
																	<input type="time" className="time" value={workTime[i]?.end||''} onChange={(e) => wtEndSet(e, i)} />
																</div>
															</div>)}
														</div>
													</div>
													<h4>Дополнительные параметры</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label>Платная доставка</label>
														<img src={alwaysPaid ? imgCheckOn : imgCheckOff} alt="" onClick={() => setAlwaysPaid(!alwaysPaid)} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="color" className="label-middle">Цвет</label>
														<div className="input">
															<input type="color" id="color" {...bindColor} className="color" />
														</div>
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="status">Статус</label>
														<div className="select select-middle">
															<select id="status" onChange={handleStatus} value={status} required>
																<option value=""></option>
																{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
															</select>
														</div>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									:
										<Empty image={'areas'} text={<>Для редактирования зоны доставки,<br />выберите одну из списка</>} />
								}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalMap>
				<div className="modal modal-map">
					<div className="map-container">
						<YMaps query={{apikey:MAP.keys.Yandex}}>
							<Map
								state={{center:cityCoordinatesGet(),zoom:12}}
								modules={['geoObject.addon.editor']}
								width={'100%'} height={'100%'}
							>
								<ZoomControl />
								<Polygon 
									geometry={area||[]}
									options={{
										draggable:true,
										editorDrawingCursor:'crosshair',
										strokeOpacity:.5,
										strokeWidth:3,
										fillColor:color,
										opacity:.5
									}}
									properties={{hintContent:name}}
									modules={['geoObject.addon.hint']}
									instanceRef={mapDraw} />
							</Map>
						</YMaps>
						<div className="map-close">
							<img src={imgClose} alt="" onClick={modalMapClose} className="btn-close" />
						</div>
					</div>
				</div>
			</ModalMap>
			<ModalChangeStatus>
				<div className="modal">
					<div className="header">
						<h4>Отключить точку</h4>
						<img src={imgClose} alt="" onClick={modalChangeStatusHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите отключить точку <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalChangeStatusHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={areaChangeStatusModal} className="btn-accept btn-delete">Отключить</button>
					</div>
				</div>
			</ModalChangeStatus>
			<ModalDelete>
				<div className="modal">
					<div className="header">
						<h4>Удалить зону доставки</h4>
						<img src={imgClose} alt="" onClick={modalDeleteHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите удалить зону <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalDeleteHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={areaDelete} className="btn-accept btn-delete">Удалить</button>
					</div>
				</div>
			</ModalDelete>
		</>
	);
};

export default DeliveryAreasScreen;