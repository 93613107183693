import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const check		= async ()			=> await Http.request('partner/check');

const get		= async ()			=> await Http.request('partner');

const update	= async (id, data)	=> await Http.request(`partner/${id}`, httpMethods.PUT, data);


export {
	check,
	get,
	update
}