import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {OrderStatuses} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { orderStatus, orderStatusName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgMenuPoints from './Images/menu-points.svg';
import imgCheck from './Images/check.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgClose from './Images/close.svg';

// start
const StatusesScreen = () => {
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [statuses, setStatuses] = useState([]);
	const [statusesStandart, setStatusesStandart] = useState([]);
	const [statusesStandartAll, setStatusesStandartAll] = useState([]);
	const [status, setStatus] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:text, setValue:setText, bind:bindText} = useInput('');
	const {value:background, setValue:setBackground, bind:bindBackground} = useInput('');
	const [code, setCode] = useState(orderStatus.UNKNOWN);
	const [search, setSearch] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [isUsed, setIsUsed] = useState(0);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			statusesStandartGet();
			await statusesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const statusesStandartGet = async () => {
		const statuses = Object.keys(orderStatusName).map((v,i) => ({code:i,name:orderStatusName[v],code:v,color:'#f2f2f3',description:'Стандартный статус системы'})).slice(1);
		setStatusesStandart(statuses);
		setStatusesStandartAll(statuses);
	};
	const statusesGet = async () => {
		const statuses = await OrderStatuses.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (statuses === undefined) return;
		setStatuses(statuses);
	};
	const statusShow = (status) => {
		if (status) {
			const s = statuses.find(f => f.code === parseInt(status.code));
			if (s) status = s;
		}
		setControlId(null);
		setStatus(status);
		setName(status?.name||'');
		setCode(status?.code||'');
		setIsUsed(isUsedCheck(status?.code));
		setDescription(status?.description||'');
		setText(status?.color?.text||'');
		setBackground(status?.color?.background||'');
		setIsEditShow(true);
	};
	const cancel = () => {
		statusShow(null);
		setIsEditShow(false);
		modalDeleteHide();
	}
	const save = async (e) => {
		e.preventDefault();
		const c = parseInt(code);
		if (isUsedCheck(c) && !isUsed) {
			statusDelete();
			return;
		}
		const data = {
			name:name,
			description:description==='Стандартный статус системы'?null:description,
			text:text,
			background:background
		};
		if (!isUsedCheck(c)) data.code = c;
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название');
			return;
		}
		const res = isUsedCheck(c) ?
				await OrderStatuses.update(c, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await OrderStatuses.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			statusesGet();
			cancel();
		}
	}
	const searchStatuses = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setStatusesStandart(statusesStandartAll);
		else setStatusesStandart(statusesStandartAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const statusControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const statusDelete = () => {
		OrderStatuses.remove(code).then((res) => {
			successShow(res.message);
			statusesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalDeleteHide = () => modalDeleteClose();
	const modalDeleteShow = (e, code) => {
		e.stopPropagation();
		setControlId(null);
		setCode(code);
		setName(statuses.find(f => f.code === parseInt(code)).name);
		modalDeleteOpen();
	}
	const isUsedCheck = (code) => statuses.find(f => f.code === parseInt(code)) ? 1 : 0;
	const usedGet = (code) => statuses.find(f => f.code === parseInt(code));
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Статусы заказа" type="books" />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search search-single" onChange={searchStatuses} value={search} />
									</div>
									<div className="container-inner">
										<table className="items">
											<thead>
												<tr>
													<th>Название</th>
													<th className="color">Цвет</th>
													<th className="current-wide">Используется</th>
													<th className="control"></th>
												</tr>
											</thead>
											<tbody>
												{statusesStandart.map((v,i) => <tr key={i} onClick={() => statusShow(v)}>
													<td>
														{isUsedCheck(v.code) ? 
															<>
																{usedGet(v.code).name}
																<span>{v.name}</span>
															</> : v.name
														}
													</td>
													<td className="color"><div className="color-example" style={{backgroundColor:usedGet(v.code)?.color.background||v.color}}></div></td>
													<td className="current-wide">{isUsedCheck(v.code) ? <img src={imgCheck} alt="" /> : null}</td>
													<td className="control">
														<img src={imgMenuPoints} alt="" onClick={(e) => statusControlSelect(e, v.code)} />
														{controlId === v.code ?
																<div className="control-block">
																	<div className="edit" onClick={() => statusShow(v)}>Редактировать</div>
																	<div className="delete" onClick={(e) => modalDeleteShow(e, v.code)}>Не использовать</div>
																</div>
															: null}
													</td>
												</tr>)}
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row">
														<input type="text" {...bindName} placeholder="Название компонента" className="input-title" autoFocus={true} required />
													</div>
													<div className="product-edit-row">
														<textarea placeholder="Описание, комментарий" {...bindDescription} className="lite"></textarea>
													</div>
													<div className="product-edit-row">
														<i>Действие статуса, как <b>{orderStatusName[status?.code]}</b></i>
													</div>
													<h4>Параметры</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="text" className="label-middle">Цвет фона</label>
														<div className="input">
															<input type="color" id="text" {...bindBackground} className="color" />
														</div>
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="background" className="label-middle">Цвет текста</label>
														<div className="input">
															<input type="color" id="background" {...bindText} className="color" />
														</div>
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label>Пример</label>
														<div><span style={{color:text,backgroundColor:background}}>&nbsp;{orderStatusName[status?.code]}&nbsp;</span></div>
													</div>
													{isUsedCheck(code) ?
														<div className="product-edit-row product-edit-row-oneline">
															<label>Успользуется</label>
															<img src={isUsed ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsUsed(!isUsed)} />
														</div> : null}
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									:
										<Empty image={'caregories'} text={<>Для редактирования статуса заказа,<br />выберите один из списка</>} />
								}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalDelete>
				<div className="modal">
					<div className="header">
						<h4>Отменить использование статуса</h4>
						<img src={imgClose} alt="" onClick={modalDeleteHide} className="btn-close" />
					</div>
					<p>Вы уверены что не хотите использовать статус <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalDeleteHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={statusDelete} className="btn-accept btn-delete">Удалить</button>
					</div>
				</div>
			</ModalDelete>
		</>
	);
};

export default StatusesScreen;