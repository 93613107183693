import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get = {
	all			: async (pageId)	=> await Http.request(`clients/${pageId||0}`),
	id			: async (id)		=> await Http.request(`client/${id}`),
	search		: async (query)		=> await Http.request('clients/search', httpMethods.POST, {query})
};

const add		= async (data)		=> await Http.request('client', httpMethods.POST, data);
const update	= async (id, data)	=> await Http.request(`client/${id}`, httpMethods.PUT, data);

const status	= async (id, data)	=> await Http.request(`client/status/${id}`, httpMethods.PATCH, data);

const patch		= async (id, data)	=> await Http.request(`client/${id}`, httpMethods.PATCH, data);

const address	= async (id, data)	=> await Http.request(`client/address/${id}`, httpMethods.PATCH, data);

const imageAdd	= async (id, data)	=> await Http.request(`client/image/${id}`, httpMethods.POST, data);


export {
	get,
	add,
	update,
	status,
	patch,
	address,
	imageAdd
}