import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {Clients, Products, Promocodes} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { commonStatus, commonStatusName, deliveryTypeName, measureTypeName, promoCodeType, promoCodeTypeName, sourceTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgClose from './Images/close.svg';
import imgEdit from './Images/edit.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';

// start
const PromocodesScreen = () => {
	const [ModalChangeStatus, modalChangeStatusOpen, modalChangeStatusClose] = useModal('root', {preventScroll:true});
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root', {preventScroll:true});
	const [ModalProducts, modalProductsOpen, modalProductsClose] = useModal('root', {preventScroll:true});
	const [ModalClients, modalClientsOpen, modalClientsClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [promocodes, setPromocodes] = useState([]);
	const [promocodesAll, setPromocodesAll] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:code, setValue:setCode, bind:bindCode} = useInput('');
	const {value:dateStart, setValue:setDateStart, bind:bindDateStart} = useInput('');
	const {value:dateEnd, setValue:setDateEnd, bind:bindDateEnd} = useInput('');
	const {value:amountMin, setValue:setAmountMin, bind:bindAmountMin} = useInput('');
	const {value:amountMax, setValue:setAmountMax, bind:bindAmountMax} = useInput('');
	const {value:counts, setValue:setCounts, bind:bindCounts} = useInput('');
	const [isFirstOrder, setIsFirstOrder] = useState(false);
	const [isOneTime, setIsOneTime] = useState(false);
	const [isStop, setIsStop] = useState(false);
	const [orderDays, setOrderDays] = useState([]);
	const [preOrderDays, setPreOrderDays] = useState([]);
	const [deliveryType, setDeliveryType] = useState([]);
	const [sources, setSources] = useState([]);
	const {value:value, setValue:setValue, bind:bindValue} = useInput('');
	const [clients, setClients] = useState([]);
	const [clientsSearch, setClientsSearch] = useState('');
	const [clientsSelected, setClientsSelected] = useState([]);
	const [products, setProducts] = useState([]);
	const [productsAll, setProductsAll] = useState([]);
	const [productsSelected, setProductsSelected] = useState([]);
	const [useds, setUseds] = useState([]);
	const [search, setSearch] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [type, setType] = useState(promoCodeType.UNKNOWN);
	const [status, setStatus] = useState(commonStatus.ACTIVE);
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await productsGet();
			await promocodesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const promocodesGet = async () => {
		const promocodes = await Promocodes.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (promocodes === undefined) return;
		setPromocodes(promocodes);
		setPromocodesAll(promocodes);
	};
	const productsGet = async () => {
		const products = await Products.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (products === undefined) return;
		setProducts(products);
		setProductsAll(products);
	};
	const promocodeAdd = () => {
		setIsShow(false);
		setIsEditShow(true);
		show();
	}
	const promocodeEdit = (e, promocode) => {
		e.stopPropagation();
		promocode = promocode || promocodes.find(f => f._id === id);
		show(promocode);
		setIsShow(false);
		setIsEditShow(true);
	};
	const promocodeShow = (promocode) => {
		show(promocode);
		setIsEditShow(false);
		setIsShow(true);
	}
	const show = (promocode) => {
		setControlId(null);
		setId(promocode?._id||null);
		setName(promocode?.name||'');
		setDescription(promocode?.description||'');
		setCode(promocode?.code||'');
		setDateStart(Utils.dateNormalize(promocode?.properties.period, 'start'));
		setDateEnd(Utils.dateNormalize(promocode?.properties.period, 'end'));
		setAmountMin(promocode?promocode.properties.amounts.min:'');
		setAmountMax(promocode?promocode.properties.amounts.max:'');
		setIsFirstOrder(promocode?promocode.options.isFirstOrder:false);
		setIsOneTime(promocode?promocode.options.isOneTime:false);
		setIsStop(promocode?promocode.options.isStop:false);
		setValue(promocode?promocode.value:null);
		setCounts(promocode?promocode.properties.counts:'');
		setOrderDays(promocode?promocode.properties.orderDays:[false,false,false,false,false,false,false]);
		setPreOrderDays(promocode?promocode.properties.preOrderDays:[false,false,false,false,false,false,false]);
		setDeliveryType(promocode?promocode.properties.deliveryType:[]);
		setSources(promocode?promocode.properties.sources:[]);
		setUseds(promocode?promocode.useds:[]);
		setType(promocode?promocode.type:promoCodeType.UNKNOWN);
		setProductsSelected(promocode ? products.filter(f => promocode.products.includes(f._id)) : []);
		setClients(promocode?.clients||[]);
		setClientsSelected(promocode?.clients||[]);
		setStatus(promocode?promocode.status:commonStatus.ACTIVE);
		setIsEditShow(true);
	};
	const cancel = () => {
		promocodeShow(null);
		setIsEditShow(false);
		setIsShow(false);
		modalChangeStatusHide();
		modalProductsClose();
		modalDeleteHide();
		setProducts(productsAll);
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			description:description,
			code:code,
			dateStart:dateStart,
			dateEnd:dateEnd,
			amountMin:amountMin,
			amountMax:amountMax,
			counts:counts,
			type:type,
			status:status
		};
		if (isFirstOrder) data.isFirstOrder = isFirstOrder;
		if (isOneTime) data.isOneTime = isOneTime;
		if (isStop) data.isStop = isStop;
		if (clientsSelected.length > 0) data.clients = clientsSelected.map(m => m._id);
		if (orderDays.length > 0) data.orderDays = orderDays;
		if (preOrderDays.length > 0) data.preOrderDays = preOrderDays;
		if (deliveryType.length > 0) data.deliveryType = deliveryType;
		if (sources.length > 0) data.sources = sources;
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название промокода');
			return;
		}
		if (Utils.empty(data.code)) {
			errorShow('Необходимо заполнить код промокода');
			return;
		}
		switch (data.type) {
			case promoCodeType.PERCENT:
				if (Utils.empty(value)) {
					errorShow('Необходимо заполнить скидку');
					return;
				}
				data.value = value;
				break;
			case promoCodeType.DISCOUNT:
				if (Utils.empty(value)) {
					errorShow('Необходимо заполнить сумму скидки');
					return;
				}
				data.value = value;
				break;
			case promoCodeType.GIFT:
			case promoCodeType.FREE:
				if (productsSelected.length === 0) {
					errorShow('Необходимо выбрать одну или несколько позиций');
					return;
				}
				data.products = productsSelected.map(m => m._id);
				break;
			case promoCodeType.BONUSES:
				if (Utils.empty(value)) {
					errorShow('Необходимо заполнить количество бонусов');
					return;
				}
				data.value = value;
				break;
		}
		const res = id ?
				await Promocodes.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Promocodes.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			promocodesGet();
			cancel();
		}
	}
	const searchPromocode = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setPromocodes(promocodesAll);
		else setPromocodes(promocodesAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase()) || f.code.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const promocodeControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const handleType = (e) => setType(parseInt(e.target.value));
	const promocodeChangeStatusOn = (e, id) => {
		e.stopPropagation();
		promocodeChangeStatus(id);
	}
	const promocodeChangeStatusModal = () => promocodeChangeStatus(id);
	const promocodeChangeStatus = (id) => {
		const promocode = promocodes.find(f => f._id === id);
		const status = promocode.status === commonStatus.ACTIVE ? commonStatus.IN_ACTIVE : commonStatus.ACTIVE;
		Promocodes.status(id, {status}).then((res) => {
			successShow(res.message);
			promocodesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalChangeStatusHide = () => modalChangeStatusClose();
	const modalChangeStatusShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setName(promocodes.find(f => f._id === id).name);
		modalChangeStatusOpen();
	}
	const promocodeDelete = () => {
		Promocodes.remove(id).then((res) => {
			successShow(res.message);
			promocodesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalDeleteHide = () => modalDeleteClose();
	const modalDeleteShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setName(promocodes.find(f => f._id === id).name);
		modalDeleteOpen();
	}
	const codeChange = (e) => {
		const val = e.target.value;
		setCode(/^[A-Za-zА-Яа-я0-9_-]{1,20}$/.test(val) ? val.toUpperCase() : val.slice(0, -1));
	}
	const productsShow = () => modalProductsOpen();
	const productSelect = (product) => {
		const i = productsSelected.findIndex(f => f.id === product._id);
		if (i === -1) setProductsSelected([...productsSelected, product]);
		else setProductsSelected(productsSelected.filter(f => f.id !== product._id));
	};
	const searchProduct = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setProducts(productsAll);
		else setProducts(productsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
	};
	const productAdd = () => {
		modalProductsClose();
		setProducts(productsAll);
	};
	const clientsShow = () => modalClientsOpen();
	const clientSelect = (client) => {
		const i = clientsSelected.findIndex(f => f.id === client._id);
		if (i === -1) setClientsSelected([...clientsSelected, client]);
		else setClientsSelected(clientsSelected.filter(f => f.id !== client._id));
	};
	const clientSearch = async (e) => {
		const search = e.target.value;
		setSearch(search);
		if (Utils.empty(search)) setClientsSearch(null);
		else {
			const clients = await Clients.get.search(search).catch((ex) => console.log(666, ex));
			setClientsSearch(clients||[]);
		}
	};
	const clientAdd = () => {
		modalClientsClose();
		setClients(null);
	};
	const sourceSet = (item) => {
		if (sources.includes(item)) setSources(sources.filter(f => f !== item));
		else setSources([...sources, item]);
	}
	const deliveryTypeSet = (item) => {
		if (deliveryType.includes(item)) setDeliveryType(deliveryType.filter(f => f !== item));
		else setDeliveryType([...deliveryType, item]);
	}
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Промокоды" type="marketing" />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchPromocode} value={search} />
										<button type="button" onClick={() => promocodeAdd()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{promocodes.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Название</th>
															<th className="weight">Кол / Исп</th>
															<th className="status status-small">Статус</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{promocodes.map((v,i) => <tr key={i} onClick={() => promocodeShow(v)}>
															<td>
																{v.name}
																{v.properties.period?.start || v.properties.period?.end ?
																		<span>
																			{v.properties.period?.start ? `с ${moment(v.properties.period.start).format('DD.MM.YYYY')}` : null}
																			{v.properties.period?.end ? (v.properties.period?.start ? ' до ' : 'до ') + moment(v.properties.period.end).format('DD.MM.YYYY'): null}
																		</span>
																	: null}
															</td>
															<th className="weight">{v.properties.counts} / {v.useds.length}</th>
															<td className="status status-small">
																<div className={`status${v.status}`}>
																	<div className="status-point"></div>
																</div>
															</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => promocodeControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			<div className="edit" onClick={(e) => promocodeEdit(e, v)}>Редактировать</div>
																			<div className={v.status===commonStatus.ACTIVE?'inactive':'active'} onClick={(e) => v.status === commonStatus.ACTIVE ? modalChangeStatusShow(e, v._id) : promocodeChangeStatusOn(e, v._id)}>{v.status===commonStatus.ACTIVE?'Отключить':'Включить'}</div>
																			<div className="delete" onClick={(e) => modalDeleteShow(e, v._id)}>Удалить</div>
																		</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте первый<br/>промокод
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isShow ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
														<h3>{name}</h3>
														<img src={imgEdit} alt="" className="edit" onClick={(e) => promocodeEdit(e)} />
													</div>
													{description ? 
														<div className="product-view-row-simple">
															{description}
														</div> : null}
													<div className="product-view-row-simple">
														<code>{code}</code>
													</div>
													<h4>Результат</h4>
													<div className="product-view-row product-view-row-oneline">
														<span>Тип промокода</span>
														<div>{promoCodeTypeName[type]}</div>
													</div>
													{type === promoCodeType.PERCENT ?
														<div className="product-view-row product-view-row-oneline">
															<span>Скидка</span>
															<div>{value?`${value}%`:'–'}</div>
														</div> : null}
													{type === promoCodeType.DISCOUNT ?
														<div className="product-view-row product-view-row-oneline">
															<span>Сумма скидки</span>
															<div>{value?`${value} ₽`:'–'}</div>
														</div> : null}
													{type === promoCodeType.GIFT || type === promoCodeType.FREE ?
														<div className="product-view-row product-view-row-oneline">
															<span>Подарок</span>
															<div>{productsSelected.length ? productsSelected.map(m => m.name).join(', ') : '–'}</div>
														</div> : null}
													{type === promoCodeType.BONUSES ?
														<div className="product-view-row product-view-row-oneline">
															<span>Количество бонусов</span>
															<div>{value?`${value} ₽`:'–'}</div>
														</div> : null}
													<div className="product-view-row product-view-row-oneline">
														<span>Статус</span>
														<div>{commonStatusName[status]}</div>
													</div>
													<h4>Параметры</h4>
													<div className="product-view-row product-view-row-oneline">
														<span>Начало действия</span>
														<div>{dateStart?moment(dateStart).format('DD.MM.YYYY'):'–'}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Окончание действия</span>
														<div>{dateEnd?moment(dateEnd).format('DD.MM.YYYY'):'–'}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Минимальная сумма</span>
														<div>{amountMin?`${amountMin} ₽`:'–'}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Максимальная сумма</span>
														<div>{amountMax?`${amountMax} ₽`:'–'}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Количество кодов</span>
														<div>{counts?counts:'–'}</div>
													</div>
													{clients.length ?
														<>
															<h4>Персонализация</h4>
															<div className="product-view-row product-view-row-oneline">
																<span>Клиенты</span>
																<div>{clients.map(m => m.name).join(', ')}</div>
															</div>
														</> : null}
													<h4>Доступность</h4>
													<div className="product-view-row">
														<label>Доступно для заказа</label>
														{orderDays.map((v, i) => !v ? null : Utils.weekDayName.short[i]).filter(f => !Utils.empty(f)).join(', ')}
													</div>
													<div className="product-view-row">
														<label>Доступно для предзаказа</label>
														{preOrderDays.map((v, i) => !v ? null : Utils.weekDayName.short[i]).filter(f => !Utils.empty(f)).join(', ')}
													</div>
													<div className="product-view-row">
														<label>Способ получения</label>
														{deliveryType.map((v, i) => !v ? null : deliveryTypeName[v]).filter(f => !Utils.empty(f)).join(', ')}
													</div>
													<div className="product-view-row">
														<label>Источники</label>
														{sources.map((v, i) => i === 0 ? null : sourceTypeName[v]).filter(f => !Utils.empty(f)).join(', ')}
													</div>
													<h4>Дополнительные параметры</h4>
													<div className="product-view-row product-view-row-oneline">
														<span>Первый заказ</span>
														<div>{isFirstOrder?'Да':'Нет'}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Одноразовый</span>
														<div>{isOneTime?'Да':'Нет'}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Не применять прочие акции и скидки</span>
														<div>{isStop?'Да':'Нет'}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Активировано</span>
														<div>{useds.length}</div>
													</div>
													{useds.length ?
														<>
															<h4>Использовано</h4>
															{useds.map((v,i) => <div key={i} className="product-view-row product-view-row-oneline">
																{v.name}
																<span>{moment(v.date).format('DD.MM.YYYY')}</span>
															</div>)}
														</> : null}
												</div>
											</div>
										</div>
									: null}
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row">
														<input type="text" {...bindName} placeholder="Название" className="input-title" autoFocus={true} required />
													</div>
													<div className="product-edit-row">
														<textarea placeholder="Описание" {...bindDescription}></textarea>
													</div>
													<div className="product-edit-row">
														<input type="text" {...bindCode} placeholder="Промокод" required pattern="^[A-Za-zА-Яа-я0-9_-]{1,20}$" maxLength={20} onChange={codeChange} />
													</div>
													<h4>Результат</h4>
													<div className="product-edit-row">
														<div className="select select-wide">
															<select id="status" onChange={handleType} value={type} required>
																<option value="">Выберите тип промокода</option>
																{promoCodeTypeName.map((v,i) => i === 0 ? null : <option key={i} value={i}>{v}</option>)}
															</select>
														</div>
													</div>
													{type === promoCodeType.PERCENT ?
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="value" className="label-middle">Скидка</label>
															<input type="text" id="value" {...bindValue} placeholder='0' maxLength={2} />
														</div> : null}
													{type === promoCodeType.DISCOUNT ?
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="value" className="label-middle">Сумма скидки</label>
															<input type="text" id="value" {...bindValue} placeholder='0' maxLength={4} />
														</div> : null}
													{type === promoCodeType.GIFT || type === promoCodeType.FREE ?
															<div className="product-edit-row">
																{productsSelected.length ?
																		<div className="product-edit-components">
																			{productsSelected.map((v,i) => <div key={i} className="product-edit-component">
																				{v.name}
																				<img src={imgClose} alt="" onClick={() => setProductsSelected(productsSelected.filter(f => f._id !== v._id))} />
																			</div>)}
																		</div>
																	: null
																}
																<div className="product-edit-btn" onClick={() => productsShow()}>Добавить товар</div>
															</div>
														: null}
													{type === promoCodeType.BONUSES ?
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="value" className="label-middle">Количество бонусов</label>
															<input type="text" id="value" {...bindValue} placeholder='0' maxLength={4} />
														</div> : null}
													<h4>Параметры</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="dateStart" className="label-middle">Начало действия</label>
														<input type="date" id="dateStart" {...bindDateStart} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="dateEnd" className="label-middle">Окончание действия</label>
														<input type="date" id="dateEnd" {...bindDateEnd} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="amountMin" className="label-middle">Минимальная сумма</label>
														<input type="text" id="amountMin" {...bindAmountMin} maxLength={4} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="amountMax" className="label-middle">Максимальная сумма</label>
														<input type="text" id="amountMax" {...bindAmountMax} maxLength={4} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="counts" className="label-middle">Количество кодов</label>
														<input type="text" id="counts" {...bindCounts} maxLength={4} />
													</div>
													<h4>Персонализация</h4>
													<div className="product-edit-row">
														<div className="product-edit-components">
															{clientsSelected.length ?
																clientsSelected.map((v,i) => <div key={i} className="product-edit-component">
																	{v.name}
																	<img src={imgClose} alt="" onClick={() => setClientsSelected(clientsSelected.filter(f => f._id !== v._id))} />
																</div>) : null}
														</div>
														<div className="product-edit-btn" onClick={() => clientsShow()}>Добавить клиента</div>
													</div>
													<h4>Доступность</h4>
													<div className="product-edit-row">
														<label className="label-title">Доступно для заказа</label>
														{orderDays.map((v,i) => <div key={i} className="product-edit-row-simple product-edit-row-oneline-start">
															<img src={v ? imgCheckOn : imgCheckOff} alt="" onClick={() => setOrderDays(orderDays.map((m,j) => i === j ? !m : m))} />
															{Utils.weekDayName.full[i]}
														</div>)}
													</div>
													<div className="product-edit-row">
														<label className="label-title">Доступно для предзаказа</label>
														{preOrderDays.map((v,i) => <div key={i} className="product-edit-row-simple product-edit-row-oneline-start">
															<img src={v ? imgCheckOn : imgCheckOff} alt="" onClick={() => setPreOrderDays(preOrderDays.map((m,j) => i === j ? !m : m))} />
															{Utils.weekDayName.full[i]}
														</div>)}
													</div>
													<div className="product-edit-row">
														<label className="label-title">Способ получения</label>
														{deliveryTypeName.map((v,i) => i === 0 ? null : <div key={i} className="product-edit-row-simple product-edit-row-oneline-start">
															<img src={deliveryType.includes(i) ? imgCheckOn : imgCheckOff} alt="" onClick={() => deliveryTypeSet(i)} />
															{deliveryTypeName[i]}
														</div>)}
													</div>
													<div className="product-edit-row">
														<label className="label-title">Источники</label>
														{sourceTypeName.map((v,i) => i === 0 ? null : <div key={i} className="product-edit-row-simple product-edit-row-oneline-start">
															<img src={sources.includes(i) ? imgCheckOn : imgCheckOff} alt="" onClick={() => sourceSet(i)} />
															{sourceTypeName[i]}
														</div>)}
													</div>
													<h4>Дополнительные параметры</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label className="label-middle">Первый заказ</label>
														<img src={isFirstOrder ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsFirstOrder(!isFirstOrder)} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label className="label-middle">Одноразовый</label>
														<img src={isOneTime ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsOneTime(!isOneTime)} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label className="label-middle">Не применять прочие акции и скидки</label>
														<img src={isStop ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsStop(!isStop)} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="status">Статус</label>
														<div className="select select-middle">
															<select id="status" onChange={handleStatus} value={status} required>
																<option value=""></option>
																{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
															</select>
														</div>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									: null}
								{isShow || isEditShow ? null : <Empty image={'points'} text={<>Для редактирования промокода,<br />выберите один из списка</>} />}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalChangeStatus>
				<div className="modal">
					<div className="header">
						<h4>Отключить промокод</h4>
						<img src={imgClose} alt="" onClick={modalChangeStatusHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите отключить <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalChangeStatusHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={promocodeChangeStatusModal} className="btn-accept btn-delete">Отключить</button>
					</div>
				</div>
			</ModalChangeStatus>
			<ModalDelete>
				<div className="modal">
					<div className="header">
						<h4>Удалить промокод</h4>
						<img src={imgClose} alt="" onClick={modalDeleteHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите удалить <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalDeleteHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={promocodeDelete} className="btn-accept btn-delete">Удалить</button>
					</div>
				</div>
			</ModalDelete>
			<ModalProducts>
				<div className="modal modal-products">
					<div className="header">
						<h4>Добавить позицию</h4>
						<img src={imgClose} alt="" onClick={modalProductsClose} className="btn-close" />
					</div>
					<input type="search" placeholder="Поиск" className="search" onChange={searchProduct} autoFocus={true} />
					<div className="products">
						<div className="list">
							{products.length ?
									products.map((v,i) => <div key={i} className="product">
										<div>
											{v.name}
											<span>{v.prices.priceFull} ₽ • {v.properties.weight} {measureTypeName[v.properties.measureType]}</span>
										</div>
										<img src={productsSelected.find(f => f._id === v._id) ? imgCheckOn : imgCheckOff} alt="" onClick={() => productSelect(v)} />
									</div>)
								:
									<div className="product-empty">
										<div>
											Ничего не найдено
										</div>
									</div>
							}
						</div>
						<div className="buttons">
							<button type="button" onClick={productAdd} className="btn-accept">Добавить</button>
						</div>
					</div>
				</div>
			</ModalProducts>
			<ModalClients>
				<div className="modal modal-products">
					<div className="header">
						<h4>Добавить клиента</h4>
						<img src={imgClose} alt="" onClick={modalClientsClose} className="btn-close" />
					</div>
					<input type="search" placeholder="Поиск" className="search" onChange={clientSearch} autoFocus={true} />
					<div className="products">
						<div className="list">
							{clientsSearch.length ?
									clientsSearch.map((v,i) => <div key={i} className="product">
										<div>
											{v.name}
											{v.phone ? <span>{Utils.phoneFormatter(v.phone)}</span> : null}
										</div>
										<img src={clientsSelected.find(f => f._id === v._id) ? imgCheckOn : imgCheckOff} alt="" onClick={() => clientSelect(v)} />
									</div>)
								:
									<div className="product-empty">
										<div>
											Никого не найдено
										</div>
									</div>
							}
						</div>
						<div className="buttons">
							<button type="button" onClick={clientAdd} className="btn-accept">Добавить</button>
						</div>
					</div>
				</div>
			</ModalClients>
		</>
	);
};

export default PromocodesScreen;