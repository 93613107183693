import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get		= async ()			=> await Http.request('loyalitytransactions');

const add		= async (data)		=> await Http.request('loyalitytransaction', httpMethods.POST, data);


export {
	get,
	add
}