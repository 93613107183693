import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {Areas, Labels} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { commonStatus, commonStatusName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgClose from './Images/close.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgCheckOff from './Images/checkbox-off.svg';

// start
const LabelsScreen = () => {
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [labels, setLabels] = useState([]);
	const [labelsAll, setLabelsAll] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:color, setValue:setColor, bind:bindColor} = useInput('');
	const {value:colorText, setValue:setColorText, bind:bindColorText} = useInput('');
	const {value:sortOrder, setValue:setSortOrder, bind:bindSortOrder} = useInput('');
	const [areas, setAreas] = useState([]);
	const [labelAreas, setLabelAreas] = useState([]);
	const [search, setSearch] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [status, setStatus] = useState(commonStatus.ACTIVE);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await areasGet();
			await labelsGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const labelsGet = async () => {
		const labels = await Labels.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (labels === undefined) return;
		setLabels(labels);
		setLabelsAll(labels);
	};
	const areasGet = async () => {
		const areas = await Areas.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (areas === undefined) return;
		setAreas(areas);
	};
	const labelShow = (label) => {
		setControlId(null);
		setId(label?._id||null);
		setName(label?.name||'');
		setDescription(label?.description||'');
		setColor(label?.options.color||'#eeeeee');
		setColorText(label?.options.colorText||'#000000');
		setSortOrder(label?.options.sortOrder||'');
		setLabelAreas(label ? (label.areas?.length ? areas.filter(f => label.areas.includes(f._id)) : []) : []);
		setStatus(label?label.status:'');
		setIsEditShow(true);
	};
	const cancel = () => {
		labelShow(null);
		setIsEditShow(false);
		modalDeleteClose();
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			description:description,
			sortOrder:sortOrder,
			color:color||'#eeeeee',
			colorText:colorText||'#000000',
			areas:labelAreas.length ? labelAreas.map(f => f._id) : null,
			status:status
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название метки');
			return;
		}
		const res = id ?
				await Labels.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Labels.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			labelsGet();
			cancel();
		}
	}
	const searchLabel = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setLabels(labelsAll);
		else setLabels(labelsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const labelControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const labelDelete = () => {
		Labels.remove(id).then((res) => {
			successShow(res.message);
			labelsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalDeleteHide = () => modalDeleteClose();
	const modalDeleteShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setName(labels.find(f => f._id === id).name);
		modalDeleteOpen();
	}
	const areaSelect = (id) => {
		if (labelAreas.find(f => f._id === id)) setLabelAreas(labelAreas.filter(f => f._id !== id));
		else setLabelAreas([...labelAreas, areas.find(f => f._id === id)]);
	}
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Метки" type="marketing" />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchLabel} value={search} />
										<button type="button" onClick={() => labelShow()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{labels.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Название</th>
															<th className="status">Статус</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{labels.map((v,i) => <tr key={i} onClick={() => labelShow(v)}>
															<td>{v.name}</td>
															<td className="status">
																<div className={`status${v.status}`}>
																	<div className="status-point"></div>{commonStatusName[v.status]}
																</div>
															</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => labelControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			<div className="edit" onClick={() => labelShow(v)}>Редактировать</div>
																			<div className="delete" onClick={(e) => modalDeleteShow(e, v._id)}>Удалить</div>
																		</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте первую<br/>метку
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row">
														<input type="text" {...bindName} placeholder="Название метки" className="input-title" autoFocus={true} required />
													</div>
													<div className="product-edit-row">
														<textarea placeholder="Описание" {...bindDescription} className="lite"></textarea>
													</div>
													<h4>Доступность на точках</h4>
													<div className="product-edit-row">
														{areas.map((v,i) => <div key={i} className="product-edit-area product-edit-row-oneline-start">
															<img src={labelAreas.find(f => f._id === v._id)?imgCheckOn:imgCheckOff} alt="" className="checkbox" onClick={() => areaSelect(v._id)} />
															{v.name}
														</div>)}
													</div>
													<h4>Дополнительные параметры</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="sortOrder" className="label-middle">Порядок сортировки</label>
														<input id="sortOrder" {...bindSortOrder} type="text" placeholder="0" maxLength={2} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="color" className="label-middle">Цвет фона</label>
														<div className="input">
															<input type="color" id="color" {...bindColor} className="color" />
														</div>
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="color" className="label-middle">Цвет текста</label>
														<div className="input">
															<input type="color" id="color" {...bindColorText} className="color" />
														</div>
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="status">Статус</label>
														<div className="select select-middle">
															<select id="status" onChange={handleStatus} value={status} required>
																<option value=""></option>
																{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
															</select>
														</div>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									:
										<Empty image={'areas'} text={<>Для редактирования метки,<br />выберите одну из списка</>} />
								}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalDelete>
				<div className="modal">
					<div className="header">
						<h4>Удалить метку</h4>
						<img src={imgClose} alt="" onClick={modalDeleteHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите удалить метку <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalDeleteHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={labelDelete} className="btn-accept btn-delete">Удалить</button>
					</div>
				</div>
			</ModalDelete>
		</>
	);
};

export default LabelsScreen;