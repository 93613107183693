import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {Areas, Categories, ComponentCategories, Components, Products, Semifinishes} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { API, commonStatus, commonStatusName, deliveryType as delType, deliveryTypeName, measureType, measureTypeName, sourceType, sourceTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlus from './Images/plus-black.svg';
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveDown from './Images/arrow-curve-down.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgClose from './Images/close.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgNoPhoto from './Images/no-photo.svg';
import imgEdit from './Images/edit.svg';
import imgCopy from './Images/copy.svg';
import imgAdd from './Images/add.svg';


// start
const MenuScreen = () => {
	const componentAmountGet = (t) => {
		const netto = t.id === 'netto' ? t.value : itemNetto || 0;
		const loss = t.id === 'loss' ? t.value : itemLoss || 0;
		const amt = t.id === 'amount' ? t.value : amount || 0;
		const w = netto - loss;
		const a = amt * w;
		setTotalWeight(w > 0 ? Number(w.toFixed(3)) : 0);
		setTotalAmount(a > 0 ? Number(a.toFixed(2)) : 0);
	};
	const [ModalComponent, modalComponentOpen, modalComponentClose] = useModal('root', {preventScroll:true});
	const [ModalItem, modalItemOpen, modalItemClose] = useModal('root', {preventScroll:true});
	const [ModalComponentCategory, modalComponentCategoryOpen, modalComponentCategoryClose] = useModal('root', {preventScroll:true});
	const [ModalItemComponents, modalItemComponentsOpen, modalItemComponentsClose] = useModal('root', {preventScroll:true});
	const [ModalSemifinishes, modalSemifinishesOpen, modalSemifinishesClose] = useModal('root', {preventScroll:true});
	const [ModalCategory, modalCategoryOpen, modalCategoryClose] = useModal('root', {preventScroll:true});
	const [ModalChangeStatus, modalChangeStatusOpen, modalChangeStatusClose] = useModal('root', {preventScroll:true});
	const [ModalStop, modalStopOpen, modalStopClose] = useModal('root', {preventScroll:true});
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [partnerId, setPartnerId] = useState(null);
	const [pointId, setPointId] = useState(null);
	const [productsAll, setProductsAll] = useState([]);
	const [products, setProducts] = useState([]);
	const [categories, setCategories] = useState([]);
	const [areas, setAreas] = useState([]);
	const [category, setCategory] = useState(null);
	const [id, setId] = useState(null);
	const {value:productName, setValue:setProductName, bind:bindProductName} = useInput('');
	const {value:productWeight, setValue:setProductWeight, bind:bindProductWeight} = useInput('');
	const {value:productDescription, setValue:setProductDescription, bind:bindProductDescription} = useInput('');
	const {value:productPrice, setValue:setProductPrice, bind:bindProductPrice} = useInput('');
	const {value:productPriceFull, setValue:setProductPriceFull, bind:bindProductPriceFull} = useInput('');
	const {value:productDiscount, setValue:setProductDiscount, bind:bindProductDiscount} = useInput('');
	const {value:productKkal, setValue:setProductKkal, bind:bindProductKkal} = useInput('');
	const {value:productProteins, setValue:setProductProteins, bind:bindProductProteins} = useInput('');
	const {value:productFats, setValue:setProductFats, bind:bindProductFats} = useInput('');
	const {value:productCarbohydrates, setValue:setProductCarbohydrates, bind:bindProductCarbohydrates} = useInput('');
	const {value:productSortOrder, setValue:setProductSortOrder, bind:bindProductSortOrder} = useInput('');
	const {value:productArticle, setValue:setProductArticle, bind:bindProductArticle} = useInput('');
	const [productImages, setProductImages] = useState([]);
	const [productImageMainIndex, setProductImageMainIndex] = useState(null);
	const [productAreas, setProductAreas] = useState([]);
	const [categoryId, setCategoryId] = useState(0);
	const [productMeasureType, setProductMeasureType] = useState(measureType.GRAMS);
	const [days, setDays] = useState([]);
	const [deliveryType, setDeliveryType] = useState([]);
	const [sources, setSources] = useState([]);
	const {value:categoryName, setValue:setCategoryName, bind:bindCategoryName} = useInput('');
	const {value:categoryDescription, setValue:setCategoryDescription, bind:bindCategoryDescription} = useInput('');
	const {value:categoryLink, setValue:setCategoryLink, bind:bindCategoryLink} = useInput('');
	const {value:categorySortOrder, setValue:setCategorySortOrder, bind:bindCategorySortOrder} = useInput('');
	const [categoryStatus, setCategoryStatus] = useState('');
	const [isCategoryHide, setIsCategoryHide] = useState(false);
	const [categoryAreas, setCategoryAreas] = useState([]);
	const [search, setSearch] = useState(null);
	const {value:componentCategoryName, setValue:setComponentCategoryName, bind:bindComponentCategoryName} = useInput('');
	const [items, setItems] = useState([]);
	const {value:amount, setValue:setAmount, bind:bindAmount} = useInput('', componentAmountGet);
	const {value:itemBrutto, setValue:setItemBrutto, bind:bindItemBrutto} = useInput('');
	const {value:itemNetto, setValue:setItemNetto, bind:bindItemNetto} = useInput('', componentAmountGet);
	const {value:itemLoss, setValue:setItemLoss, bind:bindItemLoss} = useInput('', componentAmountGet);
	const [totalWeight, setTotalWeight] = useState(0);
	const [totalAmount, setTotalAmount] = useState(0);
	const [component, setComponent] = useState(null);
	const [components, setComponents] = useState([]);
	const [componentCategoryId, setComponentCategoryId] = useState(null);
	const [componentCategories, setComponentCategories] = useState([]);
	const {value:componentName, setValue:setComponentName, bind:bindComponentName} = useInput('');
	const {value:componentDescription, setValue:setComponentDescription, bind:bindComponentDescription} = useInput('');
	const {value:componentWeight, setValue:setComponentWeight, bind:bindComponentWeight} = useInput('');
	const {value:componentMeasureType, setValue:setComponentMeasureType, bind:bindComponentMeasureType} = useInput('');
	const {value:componentPrice, setValue:setComponentPrice, bind:bindComponentPrice} = useInput('');
	const {value:componentInvoicePrice, setValue:setComponentInvoicePrice, bind:bindComponentInvoicePrice} = useInput('');
	const {value:componentMinimum, setValue:setComponentMinimum, bind:bindComponentMinimum} = useInput('');
	const {value:componentRequestCount, setValue:setComponentRequestCount, bind:bindComponentRequestCount} = useInput('');
	const [componentStatus, setComponentStatus] = useState('');
	const [isPieces, setIsPieces] = useState(false);
	const [semifinishes, setSemifinishes] = useState([]);
	const [isSemifinish, setIsSemifinish] = useState(undefined);
	const [productIsNew, setProductIsNew] = useState(false);
	const [productSiteHide, setProductSiteHide] = useState(false);
	const [controlId, setControlId] = useState(null);
	const [status, setStatus] = useState(null);
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await areasGet();
			await componentCategoriesGet();
			await componentsGet();
			await semifinishesGet();
			await categoriesGet();
			await productsGet();
			const point = ls('point');
			setPartnerId(point.partner.id);
			setPointId(point.point.id);
		};
		await dataGet();
		setLoading(false);
	}, []);
	const areasGet = async () => {
		const areas = await Areas.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (areas === undefined) return;
		setAreas(areas);
	};
	const componentsGet = async () => {
		const components = await Components.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (components === undefined) return;
		setComponents(components);
	};
	const componentCategoriesGet = async () => {
		const categories = await ComponentCategories.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (categories === undefined) return;
		setComponentCategories(categories);
	};
	const categoriesGet = async () => {
		const categories = await Categories.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (categories === undefined) return;
		setCategories(categories);
	};
	const semifinishesGet = async () => {
		const semifinishes = await Semifinishes.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (semifinishes === undefined) return;
		setSemifinishes(semifinishes);
	};
	const productsGet = async () => {
		const products = await Products.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (products === undefined) return;
		setProducts(products);
		setProductsAll(products);
	};
	const productAdd = () => {
		setIsShow(false);
		setIsEditShow(true);
		show();
		setCategoryId(category?._id||0);
	}
	const productEdit = (e, product) => {
		e.stopPropagation();
		product = product || products.find(f => f._id === id);
		show(product);
		setIsShow(false);
		setIsEditShow(true);
	};
	const productCopy = (e, product) => {
		e.stopPropagation();
		product = product || products.find(f => f._id === id);
		show(product);
		setIsShow(false);
		setIsEditShow(true);
		setId(null);
		setProductName(`${product.name} (копия)`);
	};
	const productShow = (product) => {
		show(product);
		setIsEditShow(false);
		setIsShow(true);
	}
	const show = (product) => {
		setControlId(null);
		setId(product?._id||null);
		setProductName(product?.name||'');
		setProductDescription(product?.description||'');
		setProductPrice(product?.prices.price||'');
		setProductPriceFull(product?.prices.priceFull||'');
		setProductDiscount(product?.discounts.discount||'');
		setProductWeight(product?.properties.weight||'');
		setProductKkal(product?.properties.kkal||'');
		setProductProteins(product?.properties.proteins||'');
		setProductFats(product?.properties.fats||'');
		setProductCarbohydrates(product?.properties.carbohydrates||'');
		setProductArticle(product?.properties.article||'');
		setProductSortOrder(product?.options.sortOrder||'');
		setProductIsNew(product?.options.isNew||false);
		setProductSiteHide(product?.options.siteHide||false);
		setCategoryId(product?.categoryId||0);
		setStatus(product?product.status:'');
		setProductImages(product ? product.images.map((v) => imageUrlGet(product.partnerId, product.pointId, product._id, v)) : []);
		setProductImageMainIndex(product ? product.images.findIndex(f => f === product.image) : null);
		setProductAreas(product ? product.areas : areas.map(v => v._id));
		setItems(product?.components||[]);
		setDays(product && product.options?.days && product.options.days ? product.options.days : [true,true,true,true,true,true,true]);
		setDeliveryType(product && product.options?.deliveryType && product.options.deliveryType ? product.options.deliveryType : Object.values(delType).slice(1).map(v => v));
		setSources(product && product.options && product.options.sources ? product.options.sources : Object.values(sourceType).slice(1).map(v => v));
		setIsEditShow(true);
	};
	const cancel = () => {
		productShow(null);
		setIsEditShow(false);
		setIsShow(false);
		modalChangeStatusHide();
		modalStopHide();
		modalDeleteClose();
		modalCategoryHide();
		modalItemComponentsClose();
		modalItemClose();
		modalComponentCategoryClose();
		modalComponentClose();
		modalCategoryClose();
		modalSemifinishesClose();
	}
	const productControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const categoryAddShow = (category) => {
		setCategoryId(category?._id||null);
		setCategoryName(category?.name||'');
		setCategoryDescription(category?.description||'');
		setCategorySortOrder(category?.options?.sortOrder||'');
		setIsCategoryHide(category?.options?.isHide||false);
		setCategoryLink(category?.link||'');
		setCategoryStatus(category?category.status:'');
		setCategoryAreas(category ? (category.areas?.length ? areas.filter(f => category.areas.includes(f._id)) : []) : []);
		modalCategoryOpen();
	}
	const categorySelect = (cat) => {
		if (category) {
			if (category._id === cat._id) {
				setCategory(null);
				setProducts(productsAll);
				return;
			}
		}
		const products = productsAll.filter(f => f.categoryId === cat._id);
		setCategory(cat);
		setProducts(products);
	}
	const categorySave = async (e) => {
		e.preventDefault();
		const data = {
			name:categoryName,
			description:categoryDescription,
			areas:categoryAreas.length ? categoryAreas.map(f => f._id) : null,
			sortOrder:categorySortOrder,
			isHide:isCategoryHide,
			link:categoryLink,
			status:categoryStatus
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название категории');
			return;
		}
		const res = id ?
				await Categories.update(categoryId, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Categories.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			categoriesGet();
			cancel();
		}
	}
	const productSave = async (e) => {
		e.preventDefault();
		const data = {
			name:productName,
			description:productDescription,
			categoryId:categoryId,
			price:productPrice,
			priceFull:fullPriceGet(),
			discount:productDiscount,
			weight:productWeight,
			measureType:productMeasureType,
			kkal:productKkal,
			proteins:productProteins,
			fats:productFats,
			carbohydrates:productCarbohydrates,
			productArticle:productArticle,
			sortOrder:productSortOrder,
			isNew:productIsNew,
			siteHide:productSiteHide,
			components:items,
			areas:productAreas,
			status:status
		};
		if (days.length > 0) data.days = days;
		if (deliveryType.length > 0) data.deliveryType = deliveryType;
		if (sources.length > 0) data.sources = sources;
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название блюда');
			return;
		}
		if (Utils.empty(data.description)) {
			errorShow('Необходимо заполнить описание блюда');
			return;
		}
		if (Utils.empty(data.categoryId)) {
			errorShow('Необходимо выбрать категорию');
			return;
		}
		if (Utils.empty(data.price)) {
			errorShow('Необходимо указать цену блюда');
			return;
		}
		if (productImages.length === 0) {
			errorShow('Необходимо добавить фотографии блюда');
			return;
		}
		const res = id ?
				await Products.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.message);
				})
			:
				await Products.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.message);
				});
		if (res) {
			imagesUpload(id||res.id);
			imagesUpdate(id||res.id);
			successShow(res.message);
			productsGet();
			cancel();
		}
	}
	const imagesUpdate = async (id) => {
		const images = [];
		productImages.forEach((v,i) => {
			const data = imagePrepare(v);
			if (data) images.push(`${id}-${i}.${Utils.imageExtGet(v)}`);
		});
		if (images.length === 0) return;
		const image = images[productImageMainIndex] || images[0];
		await Products.images(id, {images,image}).catch((ex) => console.log(666, ex));
	}
	const imagesUpload = async (id) => {
		productImages.forEach((v,i) => {
			const data = imagePrepare(v);
			if (data) {
				const type = Utils.imageExtGet(v);
				Products.imageAdd(id, {data,type,index:i}).catch((ex) => console.log(666, ex));
			}
		});
	}
	const imagePrepare = (image) => Utils.isImage(image) ? image.replace(/^.*,/gi,'') : null;
	const handleMeasureType = (e) => {
		const measureType = e.target.value;
		setProductMeasureType(parseInt(measureType));
	}
	const handleCategory = (e) => setCategoryId(e.target.value);
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const handleCategoryStatus = (e) => setCategoryStatus(parseInt(e.target.value));
	const handleComponentStatus = (e) => setComponentStatus(parseInt(e.target.value));
	const productChangeStatusOn = (e, id) => {
		e.stopPropagation();
		productChangeStatus(id);
	}
	const productChangeStatusModal = () => productChangeStatus(id);
	const productChangeStatus = (id) => {
		const product = products.find(f => f._id === id);
		const status = product.status === commonStatus.ACTIVE ? commonStatus.IN_ACTIVE : commonStatus.ACTIVE;
		Products.status(id, {status}).then((res) => {
			successShow(res.message);
			productsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const productStopOff = (e, id) => {
		e.stopPropagation();
		productStop(id);
	}
	const productStopModal = () => productStop(id);
	const productStop = (id) => {
		const product = products.find(f => f._id === id);
		const isStop = product.options?.isStop ? false : true;
		Products.patch(id, {isStop}).then((res) => {
			successShow(res.message);
			productsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalStopHide = () => modalStopClose();
	const modalStopShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setProductName(products.find(f => f._id === id).name);
		modalStopOpen();
	}
	const modalChangeStatusHide = () => modalChangeStatusClose();
	const modalChangeStatusShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setProductName(products.find(f => f._id === id).name);
		modalChangeStatusOpen();
	}
	const modalCategoryHide = () => {
		setCategoryId('');
		modalCategoryClose();
	}
	const handleImage = (e) => {
		const file = e.target.files?.[0];
		if (!file) return;
		const reader = new FileReader();
		reader.onload = (r) => setProductImages([...productImages, r.target.result]);
		reader.readAsDataURL(file);
	};
	const fullPriceGet = () => {
		const price = parseFloat(productPrice) || 0;
		const discount = parseFloat(productDiscount) || 0;
		return price - (price * discount / 100);
	}
	const searchProduct = (e) => {
		const search = e.target.value;
		setSearch(search);
		if (Utils.empty(search)) setProducts(productsAll);
		else setProducts(productsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
	}
	const imageError = (e) => e.target.src = imgNoPhoto;
	const imageUrlGet = (partnerId, pointId, productId, filename) => `${API.assets}partners/${partnerId}/${pointId}/products/${productId}/${filename}`;
	const areaDelete = () => {
		Products.remove(id).then((res) => {
			successShow(res.message);
			productsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalDeleteHide = () => modalDeleteClose();
	const modalDeleteShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setProductName(products.find(f => f._id === id).name);
		modalDeleteOpen();
	}
	const areaSelect = (id) => {
		const i = productAreas.findIndex(f => f === id);
		if (i === -1) setProductAreas([...productAreas, id]);
		else setProductAreas(productAreas.filter(f => f !== id));
	}
	const imageMainSet = (index) => setProductImageMainIndex(index);
	const sourceSet = (item) => {
		if (sources.includes(item)) setSources(sources.filter(f => f !== item));
		else setSources([...sources, item]);
	}
	const deliveryTypeSet = (item) => {
		if (deliveryType.includes(item)) setDeliveryType(deliveryType.filter(f => f !== item));
		else setDeliveryType([...deliveryType, item]);
	}
	const linkCreate = (e) => {
		const name = e.target.value;
		setCategoryLink(Utils.translit(name));
		setCategoryName(name);
	}
	const areaCategorySelect = (id) => {
		if (categoryAreas.find(f => f._id === id)) setCategoryAreas(categoryAreas.filter(f => f._id !== id));
		else setCategoryAreas([...categoryAreas, areas.find(f => f._id === id)]);
	}
	const componentsItemShow = () => modalItemComponentsOpen();
	const componentSelect = (component) => {
		setComponent(component);
		const amount = component.prices?.manual||component.prices?.invoice||0;
		setAmount(amount);
		modalItemComponentsClose();
		setIsSemifinish(false);
	}
	const componentAdd = async (e) => {
		e.preventDefault();
		if (!component) {
			errorShow('Необходимо выбрать компонент');
			return;
		}
		const data = {
			id:component._id,
			name:component.name,
			brutto:parseFloat(isSemifinish ? itemNetto : itemBrutto),
			netto:parseFloat(itemNetto),
			loss:parseFloat(isSemifinish ? 0 : itemLoss),
			amount:parseFloat(amount),
			weight:totalWeight,
			total:totalAmount,
			issemifinish:isSemifinish
		};
		if (Utils.empty(data.brutto) || Utils.empty(data.netto) || Utils.empty(data.loss) || Utils.empty(data.amount)) {
			errorShow('Необходимо заполнить все поля');
			return;
		}
		setItems([...items, data]);
		modalItemClose();
	}
	const componentCategoryShow = () => {
		setComponentCategoryName('');
		modalComponentCategoryOpen();
	}
	const componentCategorySave = async () => {
		if (Utils.empty(componentCategoryName)) return;
		const data = {name:componentCategoryName};
		const res = await ComponentCategories.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			componentCategoriesGet();
			modalComponentCategoryClose();
		}
	}
	const componentAddShow = () => {
		setComponentName('');
		setComponentDescription('');
		setComponentPrice('');
		setComponentInvoicePrice('');
		setComponentMinimum('');
		setComponentRequestCount('');
		setComponentWeight('');
		setComponentMeasureType('');
		setIsPieces(false);
		setComponentStatus('');
		modalComponentOpen();
	}
	const handleComponentCategory = (e) => setComponentCategoryId(e.target.value);
	const handleComponentMeasureType = (e) => setComponentMeasureType(parseInt(e.target.value));
	const itemShow = () => {
		setAmount('');
		setItemBrutto('');
		setItemNetto('');
		setItemLoss('');
		setTotalWeight(0);
		setTotalAmount(0);
		setComponent(null);
		setComponentCategoryId(null);
		setIsSemifinish(undefined);
		modalItemOpen();
	}
	const componentSave = async (e) => {
		e.preventDefault();
		const data = {
			categoryId:componentCategoryId,
			name:componentName,
			description:componentDescription,
			price:componentPrice,
			requestCount:componentRequestCount,
			minimum:componentMinimum,
			weight:componentWeight,
			measure:componentMeasureType,
			isPieces:isPieces
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название компонента');
			return;
		}
		if (Utils.empty(data.weight)) {
			errorShow('Необходимо заполнить объем / вес компонента');
			return;
		}
		if (data.measure === null) {
			errorShow('Необходимо заполнить единицу измерения компонента');
			return;
		}
		const res = id ?
				await Components.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Components.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			componentsGet();
			modalComponentClose();
		}
	}
	const semifinishesShow = () => modalSemifinishesOpen();
	const semifinishSelect = (semifinish) => {
		setComponent(semifinish);
		const amount = semifinish.prices?.manual||semifinish.prices?.calculate||0;
		setAmount(amount);
		modalSemifinishesClose();
		setIsSemifinish(true);
	}
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="menu-container">
							<div className="categories-container">
								<SideMenu title="Меню">
									<div className="categories-list-container">
										<div className="container-inner">
											<div className="list">
												{categories.length ?
														categories.map((v,i) => <div key={i} className={`category${v._id===category?._id?' category-select':''}`} onClick={() => categorySelect(v)}>
															{v.name}
															<span className="category-edit" onClick={() => categoryAddShow(v)}></span>
														</div>)
													:
														<div className="category-empty">
															<div>
																Здесь можно добавить новые категории, чтобы заполнить меню
															</div>
															<img src={imgArrowCurveDown} alt="" />
														</div>
												}
											</div>
										</div>
										<div className="categories-list-footer">
											<button type="button" onClick={() => categoryAddShow()}>
												<img src={imgPlus} alt="" />
												Добавить категорию
											</button>
										</div>
									</div>
								</SideMenu>
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchProduct} value={search} />
										<button type="button" onClick={() => productAdd()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{products.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th className="avatar"></th>
															<th>Наименование</th>
															<th className="prices">Цена</th>
															<th className="discount">Скидка</th>
															<th className="weight">Объем</th>
															<th className="status">Статус</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{products.map((v,i) => <tr key={i} onClick={() => productShow(v)} className={id===v._id?'row-selected':''}>
															<td className="avatar">
																<img src={imageUrlGet(partnerId, pointId, v._id, v.image)} alt="" onError={imageError} />
															</td>
															<td>{v.name}</td>
															<td className="prices">{v.prices.priceFull} ₽</td>
															<td className="discount">{v.discounts.discount?`${v.discounts.discount}%`:'–'}</td>
															<td className="weight">{v.properties.weight} {measureTypeName[v.properties.measureType]}</td>
															<td className="status">
																<div className={`status${v.status===commonStatus.ACTIVE?v.status:0}`}>
																	<div className="status-point"></div>
																</div>
															</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => productControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			<div className="edit" onClick={(e) => productEdit(e, v)}>Редактировать</div>
																			<div className="copy" onClick={(e) => productCopy(e, v)}>Копировать</div>
																			<div className={v.status===commonStatus.ACTIVE?'inactive':'active'} onClick={(e) => v.status === commonStatus.ACTIVE ? modalChangeStatusShow(e, v._id) : productChangeStatusOn(e, v._id)}>{v.status===commonStatus.ACTIVE?'Отключить':'Включить'}</div>
																			<div className={v.options?.isStop?'stopliston':'stoplistoff'} onClick={(e) => v.options?.isStop ? productStopOff(e, v._id) : modalStopShow(e, v._id)}>{v.options?.isStop?'Включить':'В стоп-лист'}</div>
																			<div className="delete" onClick={(e) => modalDeleteShow(e, v._id)}>Удалить</div>
																		</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте первую<br/>позицию меню
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isShow ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
														<h3>{productName}</h3>
														<div className="product-view-row-oneline">
															<img src={imgEdit} alt="" className="edit" onClick={(e) => productCopy(e)} />
															<img src={imgCopy} alt="" className="edit" onClick={(e) => productEdit(e)} />
														</div>
													</div>
													<div className="product-view-row product-view-row-simple">
														<p className="description">{productDescription}</p>
													</div>
													{productArticle ?
														<div className="product-view-row product-view-row-oneline">
															<span>Артикул</span>
															<div>{productArticle}</div>
														</div> : null}
													<div className="product-view-row product-view-row-oneline">
														<span>Цена</span>
														<div>{productPriceFull} ₽</div>
													</div>
													{productDiscount ?
														<div className="product-view-row product-view-row-oneline">
															<span>Цена без скидки</span>
															<div>{productPrice} ₽</div>
														</div> : null}
													{productDiscount ?
														<div className="product-view-row product-view-row-oneline">
															<span>Скидка</span>
															<div>{productDiscount}%</div>
														</div> : null}
													<div className="product-view-row product-view-row-oneline">
														<span>Объем / Вес</span>
														<div>{productWeight} {measureTypeName[productMeasureType]}</div>
													</div>
													<div className="product-view-row">
														<span>Энергетическая ценность</span>
														<div>{productKkal||'–'} ккал, {productProteins||'–'} белка, {productFats||'–'} жира, {productCarbohydrates||'–'} углеводов</div>
													</div>
													{productSiteHide || productIsNew ?
														<div className="product-view-row product-view-row-oneline">
															<span>Дополнительно</span>
															<div>{[productSiteHide?'Скрыто на сайте':null,productIsNew?'Новинка':null].filter(f => f !== null).join(', ')}</div>
														</div> : null}
													<h4>Доступность на точках</h4>
													<div className="product-view-row">
														<div>{areas.map((v) => v.name).join(', ')}</div>
													</div>
													<h4>Компоненты</h4>
													{items && items.length ?
															<div className="product-edit-components-block">
																{items.map((v,i) => <div key={i} className="product-edit-component-item">
																	<div className="product-edit-component-item-name">
																		<b>{v.name}{v.issemifinish ? <span>(п/ф)</span> : null}</b>
																	</div>
																	<div className="info">
																		<div>брутто: {v.brutto}кг • нетто: {v.netto}кг • потери: {v.loss}кг</div>
																		<div><b>вес: {v.weight}кг • цена: {v.total}₽</b></div>
																	</div>
																</div>)}
															</div>
														: null}
													<h4>Фотографии блюда</h4>
													<div className="product-edit-row">
														{productImages.length ?
																<div className="product-edit-images">
																	{productImages.map((v,i) => <div key={i} className="product-edit-image">
																		<img src={v} alt="" />
																	</div>)}
																</div>
															: null
														}
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel btn-cancel-wide">Закрыть</button>
											</div>
										</div>
									: null}
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={productSave}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row">
														<input type="text" {...bindProductName} placeholder="Название блюда" className="input-title" autoFocus={true} required />
													</div>
													<div className="product-edit-row">
														<textarea placeholder="Описание" {...bindProductDescription} required></textarea>
													</div>
													<div className="product-edit-row">
														<div className="select select-wide">
															<select onChange={handleCategory} value={categoryId} required>
																<option value="">Выберите категорию</option>
																{categories.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
															</select>
														</div>
													</div>
													<h4>Цены / Скидки</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="productPrice" className="label-middle">Цена блюда</label>
														<input id="productPrice" {...bindProductPrice} type="text" placeholder="0" required />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="productDiscount" className="label-middle">Процент скидки</label>
														<input id="productDiscount" {...bindProductDiscount} type="text" placeholder="0" maxLength={2} />
													</div>
													<div className="product-edit-row product-edit-row-oneline product-edit-row-select">
														<label className="label-middle">Цена блюда со скидкой</label>
														<b>{fullPriceGet()} ₽</b>
													</div>
													<h4>Фотографии блюда</h4>
													<div className="product-edit-row">
														{productImages.length ?
																<div className="product-edit-images">
																	{productImages.map((v,i) => <div key={i} className={`product-edit-image${productImageMainIndex===i?' product-edit-image-main':''}`}  onClick={() => imageMainSet(i)}>
																		<img src={v} alt="" />
																		<div className="delete" onClick={() => setProductImages(productImages.filter(f => f !== v))}></div>
																	</div>)}
																</div>
															: null
														}
														<label>
															<div className="product-edit-btn">
																Добавить фото
																<input type="file" accept="image/jpeg,image/png,image/webp" onChange={handleImage} />
															</div>
														</label>
													</div>
													<h4>Объем / Вес</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<input type="text" {...bindProductWeight} placeholder="Объем / Вес" required />
														<div className="select">
															<select onChange={handleMeasureType} value={productMeasureType} required>
																{measureTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
															</select>
														</div>
													</div>
													<h4>Энергетическая ценность</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="productKkal" className="label-middle">Каллории</label>
														<input id="productKkal" {...bindProductKkal} type="text" placeholder="Ккал" maxLength={5} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="productProteins" className="label-middle">Белки</label>
														<input id="productProteins" {...bindProductProteins} type="text" placeholder="0" maxLength={3} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="productFats" className="label-middle">Жиры</label>
														<input id="productFats" {...bindProductFats} type="text" placeholder="0" maxLength={3}/>
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="productCarbohydrates" className="label-middle">Углеводы</label>
														<input id="productCarbohydrates" {...bindProductCarbohydrates} type="text" placeholder="0" maxLength={3} />
													</div>
													<h4>Компоненты</h4>
													<div className="product-edit-row">
														{items.length ?
																<div className="product-edit-components-block">
																	{items.map((v,i) => <div key={i} className="product-edit-component-item">
																		<div className="product-edit-component-item-name">
																			<b>{v.name}{v.issemifinish ? <span>(п/ф)</span> : null}</b>
																			<img src={imgClose} alt="" onClick={() => setItems(items.filter(f => f.id !== v.id))} />
																		</div>
																		<div className="info">
																			<div>брутто: {v.brutto}кг • нетто: {v.netto}кг • потери: {v.loss}кг</div>
																			<div><b>вес: {v.weight}кг • цена: {v.total}₽</b></div>
																		</div>
																	</div>)}
																</div>
															: null
														}
														<div className="product-edit-btn" onClick={() => itemShow()}>Добавить</div>
													</div>
													<h4>Доступность</h4>
													<div className="product-edit-row">
														<label className="label-title">Доступно на точках</label>
														{areas.map((v,i) => <div key={i} className="product-edit-area product-edit-row-oneline-start">
															<img src={productAreas.includes(v._id)?imgCheckOn:imgCheckOff} alt="" className="checkbox" onClick={() => areaSelect(v._id)} />
															{v.name}
														</div>)}
													</div>
													<div className="product-edit-row">
														<label className="label-title">Доступно для заказа</label>
														{days.map((v,i) => <div key={i} className="product-edit-area product-edit-row-oneline-start">
															<img src={v ? imgCheckOn : imgCheckOff} alt="" className="checkbox" onClick={() => setDays(days.map((m,j) => i === j ? !m : m))} />
															{Utils.weekDayName.full[i]}
														</div>)}
													</div>
													<div className="product-edit-row">
														<label className="label-title">Способ получения</label>
														{deliveryTypeName.map((v,i) => i === 0 ? null : <div key={i} className="product-edit-area product-edit-row-oneline-start">
															<img src={deliveryType.includes(i) ? imgCheckOn : imgCheckOff} alt="" className="checkbox" onClick={() => deliveryTypeSet(i)} />
															{deliveryTypeName[i]}
														</div>)}
													</div>
													<div className="product-edit-row">
														<label className="label-title">Источники</label>
														{sourceTypeName.map((v,i) => i === 0 ? null : <div key={i} className="product-edit-area product-edit-row-oneline-start">
															<img src={sources.includes(i) ? imgCheckOn : imgCheckOff} alt="" className="checkbox" onClick={() => sourceSet(i)} />
															{sourceTypeName[i]}
														</div>)}
													</div>
													<h4>Дополнительные параметры</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="productArticle" className="label-middle">Артикул</label>
														<input id="productArticle" {...bindProductArticle} type="text" placeholder="0" maxLength={2} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="productSortOrder" className="label-middle">Порядок сортировки</label>
														<input id="productSortOrder" {...bindProductSortOrder} type="text" placeholder="0" maxLength={2} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label>Скрывать на сайте</label>
														<img src={productSiteHide ? imgCheckOn : imgCheckOff} alt="" onClick={() => setProductSiteHide(!productSiteHide)} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label>Новинка</label>
														<img src={productIsNew ? imgCheckOn : imgCheckOff} alt="" onClick={() => setProductIsNew(!productIsNew)} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="status">Статус</label>
														<div className="select select-middle">
															<select id="status" onChange={handleStatus} value={status} required>
																<option value=""></option>
																{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
															</select>
														</div>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									: null}
								{isShow || isEditShow ? null :
									<Empty image={'products'} text={<>Для редактирования блюда,<br />выберите один пункт из списка</>}>
										<div className="product-add-container">
											<p>или<br/>добавьте новую позицию</p>
											<button type="button" onClick={() => productAdd()}>Добавить блюдо</button>
										</div>
									</Empty>
								}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalCategory>
				<div className="modal modal-category">
					<div className="header">
						<h4>{categoryId ? 'Изменить категорию' : 'Добавить категорию'}</h4>
						<img src={imgClose} alt="" onClick={modalCategoryHide} className="btn-close" />
					</div>
					<form className="product-edit" onSubmit={categorySave}>
						<div className="container-inner">
							<div className="list">
								<div className="product-edit-row">
									<input type="text" {...bindCategoryName} placeholder="Название категории" className="input-title" autoFocus={true} onChange={linkCreate} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindCategoryDescription}></textarea>
								</div>
								<h4>Доступность на точках</h4>
								<div className="product-edit-row">
									{areas.map((v,i) => <div key={i} className="product-edit-area product-edit-row-oneline-start">
										<img src={categoryAreas.find(f => f._id === v._id)?imgCheckOn:imgCheckOff} alt="" className="checkbox" onClick={() => areaCategorySelect(v._id)} />
										{v.name}
									</div>)}
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="link" className="label-middle">Ссылка</label>
									<input id="link" {...bindCategoryLink} type="text" placeholder="Ссылка для категории" />
								</div>
								<h4>Свойства</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label className="label-middle">Скрытая категория</label>
									<img src={isCategoryHide ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsCategoryHide(!isCategoryHide)} />
								</div>
								<h4>Дополнительные параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="sortOrder" className="label-middle">Порядок сортировки</label>
									<input id="sortOrder" {...bindCategorySortOrder} type="text" placeholder="0" maxLength={2} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="status">Статус</label>
									<div className="select select-middle">
										<select id="status" onChange={handleCategoryStatus} value={categoryStatus} required>
											<option value=""></option>
											{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
							</div>
						</div>
						<div className="product-edit-footer">
							<button type="button" onClick={modalCategoryHide} className="btn-cancel">Отменить</button>
							<button type="submit" className="btn-save btn-accept">Сохранить</button>
						</div>
					</form>
				</div>
			</ModalCategory>
			<ModalItem>
				<div className="modal modal-items">
					<div className="header">
						<h4>Добавить компонент</h4>
						<img src={imgClose} alt="" onClick={modalItemClose} className="btn-close" />
					</div>
					<form onSubmit={componentAdd}>
						{component ?
								<div>
									<b>{component.name}</b> • <span>{component.prices?.manual||component.prices?.invoice||0} ₽ за {component.weight} {measureTypeName[component.measure]}</span>
								</div>
							: null}
						<div className="buttons">
							<button type="button" className={`btn-cancel btn-select btn-select-half${isSemifinish===false?' btn-select-half-select':''}`} onClick={() => componentsItemShow()}>Компонент</button>
							<button type="button" className={`btn-cancel btn-select btn-select-half${isSemifinish===true?' btn-select-half-select':''}`} onClick={() => semifinishesShow()}>Полуфабрикат</button>
						</div>
						<div className="weights-form">
							{isSemifinish ?
									<div className="weights-form-row">
										<label htmlFor="brutto">Цена<span>полуфабриката за {component.properties.netto} кг</span></label>
										<div>
											<input type="text" {...bindAmount} placeholder="0" maxLength={5} required />
											<span>₽</span>
										</div>
									</div>
								:
									<div className="weights-form-row">
										<label htmlFor="brutto">Цена<span>компонента за 1 кг</span></label>
										<div>
											<input type="text" {...bindAmount} placeholder="0" maxLength={5} required />
											<span>₽</span>
										</div>
									</div>
							}
						</div>
						<div className="weights-form">
							{!isSemifinish ?
								<div className="weights-form-row">
									<label htmlFor="brutto">Брутто<span>вес с упаковкой</span></label>
									<div>
										<input id="brutto" type="text" {...bindItemBrutto} placeholder="0" maxLength={5} required />
										<span>кг</span>
									</div>
								</div> : null}
							<div className="weights-form-row">
								<label htmlFor="netto">Нетто<span>чистый вес</span></label>
								<div>
									<input id="netto" type="text" {...bindItemNetto} placeholder="0" maxLength={5} required />
									<span>кг</span>
								</div>
							</div>
							{!isSemifinish ?
								<div className="weights-form-row">
									<label htmlFor="loss">Потери</label>
									<div>
										<input id="loss" type="text" {...bindItemLoss} placeholder="0" maxLength={5} required />
										<span>кг</span>
									</div>
								</div> : null}
						</div>
						<div className="weights-form">
							<div className="weights-form-row">
								<label>Выход веса</label>
								<div>
									<b>{totalWeight} кг</b>
								</div>
							</div>
							<div className="weights-form-row">
								<label>Сумма</label>
								<div>
									<b>{totalAmount} ₽</b>
								</div>
							</div>
						</div>
						<div className="buttons">
							<button type="submit" className="btn-accept">Добавить</button>
						</div>
					</form>
				</div>
			</ModalItem>
			<ModalItemComponents>
				<div className="modal modal-conditions-lite">
					<div className="header">
						<div className="header-sub">
							<h4>Добавить компонент</h4>
							<img src={imgAdd} alt="" className="add" onClick={() => componentAddShow()} />
						</div>
						<img src={imgClose} alt="" onClick={modalItemComponentsClose} className="btn-close" />
					</div>
					<div className="conditions">
						<div className="sections">
							<div className="inner">
								{componentCategories.length ?
										<div className="list">
											{componentCategories.map((v,i) => <div key={i} className={`section${componentCategoryId===v._id?' section-select':''}`} onClick={() => setComponentCategoryId(v._id)}>
												{v.name}
											</div>)}
										</div>
									:
										<div className="category-empty">
											<div>
												Здесь можно добавить новые категории, чтобы заполнить меню
											</div>
											<img src={imgArrowCurveDown} alt="" />
										</div>
								}
							</div>
							<div className="sections-footer">
								<button type="button" onClick={() => componentCategoryShow()}>
									<img src={imgPlus} alt="" />
										Добавить категорию
								</button>
							</div>
						</div>
						<div className="content">
							{components.filter(f => f.category.id === componentCategoryId).length ?
									<div className="components-list">
										{components.filter(f => f.category.id === componentCategoryId).map((v,i) => <div key={i} className="component" onClick={() => componentSelect(v)}>
											{v.name}
											<span>{v.category.name} • {v.prices?.manual||v.prices?.invoice||0} ₽ • {v.weight} {measureTypeName[v.measure]}</span>
										</div>)}
									</div>
								:
									<div className="empty">
										<Empty image={'components'} text={
											<>
												Для добавления компонента,<br />выберите один из списка слева
												<div className="product-add-container">
													<p>или<br/>добавьте новую позицию</p>
													<button type="button" onClick={() => componentAddShow()}>Добавить компонент</button>
												</div>
											</>} />
									</div>
								}
						</div>
					</div>
				</div>
			</ModalItemComponents>
			<ModalComponent>
				<div className="modal modal-component-newadd">
					<div className="header">
						<h4>Добавить компонент</h4>
						<img src={imgClose} alt="" onClick={modalComponentClose} className="btn-close" />
					</div>
					<form className="product-edit" onSubmit={componentSave}>
						<div className="container-inner">
							<div className="list">
								<div className="product-edit-row">
									<input type="text" {...bindComponentName} placeholder="Название компонента" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindComponentDescription} className="lite"></textarea>
								</div>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-plus">
									<div className="select select-wide">
										<select onChange={handleComponentCategory} value={componentCategoryId} required>
											<option value="">Выберите категорию</option>
											{componentCategories.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
									<img src={imgPlusButton} alt="" className="categoty-add" onClick={() => componentCategoryShow()} />
								</div>
								<h4>Объем / Вес</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<input type="text" {...bindComponentWeight} placeholder="Объем / Вес" maxLength={4} required />
									<div className="select">
										<select onChange={handleComponentMeasureType} value={componentMeasureType} required>
											{measureTypeName.map((v,i) => <option key={i} value={i===0?'':i}>{v}</option>)}
										</select>
									</div>
								</div>
								<h4>Цены</h4>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-select">
									<label className="label-middle">Цена из последней накладной</label>
									{componentInvoicePrice?`${componentInvoicePrice} ₽`:'–'}
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="price" className="label-middle">Цена (коррекция), ₽</label>
									<input id="price" {...bindComponentPrice} type="text" placeholder="0" maxLength={6} />
								</div>
								<h4>Свойства</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label className="label-middle">Штучный, количественный товар</label>
									<img src={isPieces ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsPieces(!isPieces)} />
								</div>
								<h4>Дополнительные параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="minimum" className="label-middle">Минимальный остаток на складе</label>
									<input id="minimum" {...bindComponentMinimum} type="text" placeholder="0" maxLength={4} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="requestcount" className="label-middle">Количество для заявки по умолчанию</label>
									<input id="requestcount" {...bindComponentRequestCount} type="text" placeholder="0" maxLength={4} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="status">Статус</label>
									<div className="select select-middle">
										<select id="status" onChange={handleComponentStatus} value={componentStatus} required>
											<option value=""></option>
											{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
							</div>
						</div>
						<div className="product-edit-footer">
							<button type="button" onClick={modalComponentClose} className="btn-cancel">Отменить</button>
							<button type="submit" className="btn-save btn-accept">Сохранить</button>
						</div>
					</form>
				</div>
			</ModalComponent>
			<ModalComponentCategory>
				<div className="modal">
					<div className="header">
						<h4>Добавить категорию</h4>
						<img src={imgClose} alt="" onClick={modalComponentCategoryClose} className="btn-close" />
					</div>
					<input type="text" {...bindComponentCategoryName} placeholder="Название категории" autoFocus={true} />
					<div className="buttons">
						<button type="button" onClick={modalComponentCategoryClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={componentCategorySave} className="btn-accept">Сохранить</button>
					</div>
				</div>
			</ModalComponentCategory>
			<ModalSemifinishes>
				<div className="modal modal-products modal-semifinishes">
					<div className="header">
						<h4>Добавить полуфабрикат</h4>
						<img src={imgClose} alt="" onClick={modalSemifinishesClose} className="btn-close" />
					</div>
					<div className="products">
						<div className="list">
							{semifinishes.length ?
									semifinishes.map((v,i) => <div key={i} className="product product-semifinish" onClick={() => semifinishSelect(v)}>
										<div>{v.name}</div>
									</div>)
								:
									<div className="product-empty">
										<div>
											Ничего не найдено
										</div>
									</div>
							}
						</div>
					</div>
				</div>
			</ModalSemifinishes>
			<ModalStop>
				<div className="modal">
					<div className="header">
						<h4>Позицию в стоп-лист</h4>
						<img src={imgClose} alt="" onClick={modalStopHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите перевести позицию <b>{productName}</b> в стоп-лист?</p>
					<div className="buttons">
						<button type="button" onClick={modalStopHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={productStopModal} className="btn-accept btn-delete">В стоп-лист</button>
					</div>
				</div>
			</ModalStop>
			<ModalChangeStatus>
				<div className="modal">
					<div className="header">
						<h4>Отключить позицию</h4>
						<img src={imgClose} alt="" onClick={modalChangeStatusHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите отключить позицию <b>{productName}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalChangeStatusHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={productChangeStatusModal} className="btn-accept btn-delete">Отключить</button>
					</div>
				</div>
			</ModalChangeStatus>
			<ModalDelete>
				<div className="modal">
					<div className="header">
						<h4>Удалить позицию</h4>
						<img src={imgClose} alt="" onClick={modalDeleteHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите удалить зону <b>{productName}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalDeleteHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={areaDelete} className="btn-accept btn-delete">Удалить</button>
					</div>
				</div>
			</ModalDelete>
		</>
	);
};

export default MenuScreen;