import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {Sources} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgClose from './Images/close.svg';
import imgEdit from './Images/edit.svg';


// start
const SourcesScreen = () => {
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [sourcesAll, setSourcesAll] = useState([]);
	const [sources, setSources] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:url, setValue:setUrl, bind:bindUrl} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const [search, setSearch] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await sourcesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const sourcesGet = async () => {
		const sources = await Sources.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (sources === undefined) return;
		setSources(sources);
		setSourcesAll(sources);
	};
	const sourceAdd = () => {
		setIsShow(false);
		setIsEditShow(true);
		show();
	}
	const sourceEdit = (e, source) => {
		e.stopPropagation();
		source = source || sources.find(f => f._id === id);
		show(source);
		setIsShow(false);
		setIsEditShow(true);
	};
	const sourceShow = (source) => {
		show(source);
		setIsEditShow(false);
		setIsShow(true);
	};
	const show = (source) => {
		setControlId(null);
		setId(source?._id||null);
		setName(source?.name||'');
		setUrl(source?.url||'');
		setDescription(source?.description||'');
		setIsEditShow(true);
	};
	const cancel = () => {
		sourceShow(null);
		setIsEditShow(false);
		setIsShow(false);
		modalDeleteHide();
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			url:url,
			description:description
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название источника');
			return;
		}
		const res = id ?
				await Sources.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Sources.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			sourcesGet();
			cancel();
		}
	}
	const searchSource = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setSources(sourcesAll);
		else setSources(sourcesAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const sourceControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const sourceDelete = () => {
		Sources.remove(id).then((res) => {
			successShow(res.message);
			sourcesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalDeleteHide = () => modalDeleteClose();
	const modalDeleteShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setName(sources.find(f => f._id === id).name);
		modalDeleteOpen();
	}
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Источники заказов" type="books" />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchSource} value={search} />
										<button type="button" onClick={() => sourceAdd()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{sources.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Название</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{sources.map((v,i) => <tr key={i} onClick={() => sourceShow(v)} className={id===v._id?'row-selected':''}>
															<td>
																{v.name}
																{v.url ? <span>{v.url}</span> : null}
															</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => sourceControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			<div className="edit" onClick={(e) => sourceEdit(e, v)}>Редактировать</div>
																			<div className="delete" onClick={(e) => modalDeleteShow(e, v._id)}>Удалить</div>
																		</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте первую<br/>причину списания
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isShow ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
														<h3>{name}</h3>
														<img src={imgEdit} alt="" className="edit" onClick={(e) => sourceEdit(e)} />
													</div>
													{description ?
														<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel btn-cancel-wide">Закрыть</button>
											</div>
										</div>
									: null}
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row">
														<input type="text" {...bindName} placeholder="Название источника" className="input-title" autoFocus={true} required />
													</div>
													<div className="product-edit-row">
														<input type="text" placeholder="URL" {...bindUrl} />
													</div>
													<div className="product-edit-row">
														<textarea placeholder="Описание" {...bindDescription} className="lite"></textarea>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									: null}
								{isShow || isEditShow ? null : <Empty image={'components'} text={<>Для редактирования причны списания,<br />выберите одину из списка</>} />}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalDelete>
				<div className="modal">
					<div className="header">
						<h4>Удалить источник заказов</h4>
						<img src={imgClose} alt="" onClick={modalDeleteHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите удалить <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalDeleteHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={sourceDelete} className="btn-accept btn-delete">Удалить</button>
					</div>
				</div>
			</ModalDelete>
		</>
	);
};

export default SourcesScreen;