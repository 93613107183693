import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {Products, PromoActions, Promocodes} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { API, commonStatus, commonStatusName, measureTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgClose from './Images/close.svg';
import imgEdit from './Images/edit.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgNoImage from './Images/no-image.svg';

// start
const ActionsScreen = () => {
	const [ModalChangeStatus, modalChangeStatusOpen, modalChangeStatusClose] = useModal('root', {preventScroll:true});
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root', {preventScroll:true});
	const [ModalProducts, modalProductsOpen, modalProductsClose] = useModal('root', {preventScroll:true});
	const [ModalPromocodes, modalPromocodesOpen, modalPromocodesClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [partnerId, setPartnerId] = useState(null);
	const [pointId, setPointId] = useState(null);
	const [promoactions, setPromoactions] = useState([]);
	const [promoactionsAll, setPromoactionsAll] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:content, setValue:setContent, bind:bindContent} = useInput('');
	const {value:sortOrder, setValue:setSortOrder, bind:bindSortOrder} = useInput('');
	const [isMainShow, setIsMainShow] = useState(false);
	const [isFirstOrder, setIsFirstOrder] = useState(false);
	const [products, setProducts] = useState([]);
	const [productsAll, setProductsAll] = useState([]);
	const [promocodes, setPromocodes] = useState([]);
	const [promocodesAll, setPromocodesAll] = useState([]);
	const [promocodeId, setPromocodeId] = useState(null);
	const [productsSelected, setProductsSelected] = useState([]);
	const [image, setImage] = useState(null);
	const [search, setSearch] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [status, setStatus] = useState(commonStatus.ACTIVE);
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await promocodesGet();
			await productsGet();
			await promoactionsGet();
			const point = ls('point');
			setPartnerId(point.partner.id);
			setPointId(point.point.id);
		};
		await dataGet();
		setLoading(false);
	}, []);
	const promoactionsGet = async () => {
		const promoactions = await PromoActions.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (promoactions === undefined) return;
		setPromoactions(promoactions);
		setPromoactionsAll(promoactions);
	};
	const productsGet = async () => {
		const products = await Products.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (products === undefined) return;
		setProducts(products);
		setProductsAll(products);
	};
	const promocodesGet = async () => {
		const promocodes = await Promocodes.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (promocodes === undefined) return;
		setPromocodes(promocodes);
		setPromocodesAll(promocodes);
	};
	const promoactionAdd = () => {
		setIsShow(false);
		setIsEditShow(true);
		show();
	}
	const promoactionEdit = (e, promoaction) => {
		e.stopPropagation();
		promoaction = promoaction || promoactions.find(f => f._id === id);
		show(promoaction);
		setIsShow(false);
		setIsEditShow(true);
	};
	const promoactionshow = (promoaction) => {
		show(promoaction);
		setIsEditShow(false);
		setIsShow(true);
	}
	const show = (promoaction) => {
		setControlId(null);
		setId(promoaction?._id||null);
		setName(promoaction?.name||'');
		setDescription(promoaction?.description||'');
		setContent(promoaction?.content||'');
		setSortOrder(promoaction?.options.sortOrder||'');
		setIsMainShow(promoaction?.options.isMainShow||false);
		setIsFirstOrder(promoaction?.options.isFirstOrder||false);
		setProductsSelected(promoaction?.products||[]);
		setPromocodeId(promoaction?.promocode?.id||null);
		setImage(promoaction ? imageUrlGet(partnerId, pointId, promoaction._id) : null);
		setStatus(promoaction?promoaction.status:commonStatus.ACTIVE);
		setIsEditShow(true);
	};
	const cancel = () => {
		promoactionshow(null);
		setIsEditShow(false);
		setIsShow(false);
		modalChangeStatusHide();
		modalDeleteHide();
		modalProductsClose();
		modalPromocodesClose();
		setProducts(productsAll);
		setPromocodesAll(promocodesAll);
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			description:description,
			content:content,
			products:productsSelected.map(f => f.id),
			promocodeId:promocodeId,
			isFirstOrder:isFirstOrder,
			isMainShow:isMainShow,
			sortOrder:sortOrder,
			status:status
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название акции');
			return;
		}
		const res = id ?
				await PromoActions.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await PromoActions.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			imageUpload(id||res.id);
			successShow(res.message);
			promoactionsGet();
			cancel();
		}
	}
	const imageUpload = async (id) => image && image.indexOf('data:image') === 0 ? await PromoActions.imageAdd(id, {data:image.replace(/^.*,/gi,'')}).catch((ex) => console.log(666, ex)) : null;
	const searchPromoaction = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setPromoactions(promoactionsAll);
		else setPromoactions(promoactionsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase()) || f.code.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const promoactionControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const promoactionChangeStatusOn = (e, id) => {
		e.stopPropagation();
		promoactionChangeStatus(id);
	}
	const promoactionChangeStatusModal = () => promoactionChangeStatus(id);
	const promoactionChangeStatus = (id) => {
		const promoaction = promoactions.find(f => f._id === id);
		const status = promoaction.status === commonStatus.ACTIVE ? commonStatus.IN_ACTIVE : commonStatus.ACTIVE;
		PromoActions.status(id, {status}).then((res) => {
			successShow(res.message);
			promoactionsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalChangeStatusHide = () => modalChangeStatusClose();
	const modalChangeStatusShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setName(promoactions.find(f => f._id === id).name);
		modalChangeStatusOpen();
	}
	const promoactionDelete = () => {
		PromoActions.remove(id).then((res) => {
			successShow(res.message);
			promoactionsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalDeleteHide = () => modalDeleteClose();
	const modalDeleteShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setName(promoactions.find(f => f._id === id).name);
		modalDeleteOpen();
	}
	const imageUrlGet = (partnerId, pointId, actionId) => `${API.assets}partners/${partnerId}/${pointId}/actions/${actionId}/action.jpg`;
	const imageError = (e) => e.target.src = imgNoImage;
	const imageDelete = () => setImage(null);
	const handleImage = (e) => {
		const file = e.target.files?.[0];
		if (!file) return;
		const reader = new FileReader();
		reader.onload = (r) => setImage(r.target.result);
		reader.readAsDataURL(file);
	};
	const productsShow = () => modalProductsOpen();
	const productSelect = (product) => {
		const i = productsSelected.findIndex(f => f.id === product._id);
		if (i === -1) setProductsSelected([...productsSelected, product]);
		else setProductsSelected(productsSelected.filter(f => f.id !== product._id));
	};
	const searchProduct = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setProducts(productsAll);
		else setProducts(productsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
	};
	const productAdd = () => {
		modalProductsClose();
		setProducts(productsAll);
	};
	const promocodeShow = () => modalPromocodesOpen();
	const promocodeSelect = (promocode) => {
		if (promocodeId === promocode._id) setPromocodeId(null);
		else setPromocodeId(promocode._id);
	};
	const searchPromocode = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setPromocodes(promocodesAll);
		else setPromocodes(promocodesAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
	};
	const promocodeAdd = () => {
		modalPromocodesClose();
		setPromocodes(promocodesAll);
	};
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Акции" type="marketing" />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchPromoaction} value={search} />
										<button type="button" onClick={() => promoactionAdd()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{promoactions.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Название</th>
															<th className="status status-small">Статус</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{promoactions.map((v,i) => <tr key={i} onClick={() => promoactionshow(v)}>
															<td>{v.name}</td>
															<td className="status status-small">
																<div className={`status${v.status}`}>
																	<div className="status-point"></div>
																</div>
															</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => promoactionControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			<div className="edit" onClick={(e) => promoactionEdit(e, v)}>Редактировать</div>
																			<div className={v.status===commonStatus.ACTIVE?'inactive':'active'} onClick={(e) => v.status === commonStatus.ACTIVE ? modalChangeStatusShow(e, v._id) : promoactionChangeStatusOn(e, v._id)}>{v.status===commonStatus.ACTIVE?'Отключить':'Включить'}</div>
																			<div className="delete" onClick={(e) => modalDeleteShow(e, v._id)}>Удалить</div>
																		</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте первую<br/>акцию
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isShow ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
														<h3>{name}</h3>
														<img src={imgEdit} alt="" className="edit" onClick={(e) => promoactionEdit(e)} />
													</div>
													{description ? 
														<div className="product-view-row-simple">
															{description}
														</div> : null}
													<div className="product-view-row">
														<img src={imageUrlGet(partnerId, pointId, id)} alt="" onError={imageError} className="image" />
													</div>
													<h4>Свойства</h4>
													<div className="product-view-row product-view-row-oneline">
														<span>Показывать на главной</span>
														<div>{isMainShow ? 'Да' : 'Нет'}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Действует на первый заказ</span>
														<div>{isFirstOrder ? 'Да' : 'Нет'}</div>
													</div>
													{productsSelected.length ?
														<>
															<h4>Выбранные позиции</h4>
															{productsSelected.map((v,i) => <div key={i} className="product-view-row product-view-row-oneline">
																<span>{v.name}</span>
																<div>{v.price} ₽</div>
															</div>)}
														</> : null}
													{promocodeId ?
														<>
															<h4>Промокод</h4>
															<div className="product-view-row product-view-row-oneline">
																{promocodes.find(f => f._id === promocodeId).name}
															</div>
														</> : null}
												</div>
											</div>
										</div>
									: null}
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row">
														<input type="text" {...bindName} placeholder="Название" className="input-title" autoFocus={true} required />
													</div>
													<div className="product-edit-row">
														<textarea placeholder="Краткое описание" {...bindDescription} className="lite"></textarea>
													</div>
													<div className="product-edit-row">
														<textarea placeholder="Полное описание" {...bindContent}></textarea>
													</div>
													<h4>Изображение</h4>
													<div className="product-edit-row">
														{image ?
																<div className="product-edit-images">
																	<div className="product-edit-image product-edit-image-wide">
																		<img src={image} alt="" onError={imageError} />
																		<div className="delete" onClick={imageDelete}></div>
																	</div>
																</div>
															: null}
														<label>
															<div className="product-edit-btn">
																Добавить изображение
																<input type="file" accept="image/jpeg,image/png" onChange={handleImage} />
															</div>
														</label>
													</div>
													<h4>Позиции</h4>
													<div className="product-edit-row">
														{productsSelected.length ?
																<div className="product-edit-components">
																	{productsSelected.map((v,i) => <div key={i} className="product-edit-component">
																		{v.name}
																		<img src={imgClose} alt="" onClick={() => setProductsSelected(productsSelected.filter(f => f._id !== v._id))} />
																	</div>)}
																</div>
															: null
														}
														<div className="product-edit-btn" onClick={() => productsShow()}>Добавить товар</div>
													</div>
													<h4>Промокод</h4>
													<div className="product-edit-row">
														{promocodeId ?
																<div className="product-edit-components">
																	<div className="product-edit-component">
																		{promocodes.find(f => f._id === promocodeId).name}
																		<img src={imgClose} alt="" onClick={() => setPromocodeId(null)} />
																	</div>
																</div>
															: null
														}
														<div className="product-edit-btn" onClick={() => promocodeShow()}>Добавить промокод</div>
													</div>
 													<h4>Дополнительные параметры</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label className="label-middle">Показывать на главной</label>
														<img src={isMainShow ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsMainShow(!isMainShow)} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label className="label-middle">Первый заказ</label>
														<img src={isFirstOrder ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsFirstOrder(!isFirstOrder)} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="sortOrder" className="label-middle">Порядок сортировки</label>
														<input id="sortOrder" {...bindSortOrder} type="text" placeholder="0" maxLength={2} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="status">Статус</label>
														<div className="select select-middle">
															<select id="status" onChange={handleStatus} value={status} required>
																<option value=""></option>
																{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
															</select>
														</div>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									: null}
								{isShow || isEditShow ? null : <Empty image={'points'} text={<>Для редактирования акции,<br />выберите одину из списка</>} />}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalChangeStatus>
				<div className="modal">
					<div className="header">
						<h4>Отключить акцию</h4>
						<img src={imgClose} alt="" onClick={modalChangeStatusHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите отключить <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalChangeStatusHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={promoactionChangeStatusModal} className="btn-accept btn-delete">Отключить</button>
					</div>
				</div>
			</ModalChangeStatus>
			<ModalDelete>
				<div className="modal">
					<div className="header">
						<h4>Удалить акцию</h4>
						<img src={imgClose} alt="" onClick={modalDeleteHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите удалить <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalDeleteHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={promoactionDelete} className="btn-accept btn-delete">Удалить</button>
					</div>
				</div>
			</ModalDelete>
			<ModalProducts>
				<div className="modal modal-products">
					<div className="header">
						<h4>Добавить позицию</h4>
						<img src={imgClose} alt="" onClick={modalProductsClose} className="btn-close" />
					</div>
					<input type="search" placeholder="Поиск" className="search" onChange={searchProduct} autoFocus={true} />
					<div className="products">
						<div className="list">
							{products.length ?
									products.map((v,i) => <div key={i} className="product">
										<div>
											{v.name}
											<span>{v.prices.priceFull} ₽ • {v.properties.weight} {measureTypeName[v.properties.measureType]}</span>
										</div>
										<img src={productsSelected.find(f => f._id === v._id) ? imgCheckOn : imgCheckOff} alt="" onClick={() => productSelect(v)} />
									</div>)
								:
									<div className="product-empty">
										<div>
											Ничего не найдено
										</div>
									</div>
							}
						</div>
						<div className="buttons">
							<button type="button" onClick={productAdd} className="btn-accept">Добавить</button>
						</div>
					</div>
				</div>
			</ModalProducts>
			<ModalPromocodes>
				<div className="modal modal-products">
					<div className="header">
						<h4>Выбрать промокод</h4>
						<img src={imgClose} alt="" onClick={modalPromocodesClose} className="btn-close" />
					</div>
					<input type="search" placeholder="Поиск" className="search" onChange={searchPromocode} autoFocus={true} />
					<div className="products">
						<div className="list">
							{promocodes.length ?
									promocodes.map((v,i) => <div key={i} className="product">
										<div>{v.name}</div>
										<img src={promocodeId === v._id ? imgCheckOn : imgCheckOff} alt="" onClick={() => promocodeSelect(v)} />
									</div>)
								:
									<div className="product-empty">
										<div>
											Ничего не найдено
										</div>
									</div>
							}
						</div>
						<div className="buttons">
							<button type="button" onClick={promocodeAdd} className="btn-accept">Добавить</button>
						</div>
					</div>
				</div>
			</ModalPromocodes>
		</>
	);
};

export default ActionsScreen;