import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import InputMask from 'react-input-mask';
import Moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {Areas, Partners, Points, Roles, Users} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { API, permissionTypeName, userType, userTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgClose from './Images/close.svg';
import imgNoPhoto from './Images/no-photo.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgPlus from './Images/plus.svg';
import imgEdit from './Images/edit.svg';

// start
const AccountScreen = () => {
	const [ModalAccess, modalAccessOpen, modalAccessClose] = useModal('root', {preventScroll:true});
	const [ModalRolesAdd, modalRolesAddOpen, modalRolesAddClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [partnerId, setPartnerId] = useState(null);
	const [pointId, setPointId] = useState(null);
	const [profile, setProfile] = useState([]);
	const [partner, setPartner] = useState([]);
	const [type, setType] = useState('profile');
	const [points, setPoints] = useState([]);
	const [areas, setAreas] = useState([]);
	const [areasPoint, setAreasPoint] = useState([]);
	const [roles, setRoles] = useState([]);
	const [rolesPoint, setRolesPoint] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:comment, setValue:setComment, bind:bindComment} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:email, setValue:setEmail, bind:bindEmail} = useInput('');
	const {value:password, setValue:setPassword, bind:bindPassword} = useInput('');
	const {value:phone, setValue:setPhone, bind:bindPhone} = useInput('');
	const {value:address, setValue:setAddress, bind:bindAddress} = useInput('');
	const {value:officialName, setValue:setOfficialName, bind:bindOfficialName} = useInput('');
	const {value:officialAddress, setValue:setOfficialAddress, bind:bindOfficialAddress} = useInput('');
	const {value:inn, setValue:setInn, bind:bindInn} = useInput('');
	const {value:kpp, setValue:setKpp, bind:bindKpp} = useInput('');
	const {value:ogrn, setValue:setOgrn, bind:bindOgrn} = useInput('');
	const {value:okpo, setValue:setOkpo, bind:bindOkpo} = useInput('');
	const {value:okved, setValue:setOkved, bind:bindOkved} = useInput('');
	const {value:bank, setValue:setBank, bind:bindBank} = useInput('');
	const {value:bik, setValue:setBik, bind:bindBik} = useInput('');
	const {value:correspondent, setValue:setCorrespondent, bind:bindCorrespondent} = useInput('');
	const {value:account, setValue:setAccount, bind:bindAccount} = useInput('');
	const {value:roleName, setValue:setRoleName, bind:bindRoleName} = useInput('');
	const [avatar, setAvatar] = useState(null);
	const [access, setAccess] = useState(null);
	const [accessTemp, setAccessTemp] = useState(null);
	const [permissions, setPermissions] = useState([]);
	const [typeId, setTypeId] = useState(null);
	const [roleId, setRoleId] = useState(null);
	const [areaId, setAreaId] = useState(null);
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await rolesGet();
			await areasGet();
			await pointsGet();
			await profileGet();
			await partnerGet();
			const point = ls('point');
			setPartnerId(point.partner.id);
			setPointId(point.point.id);
		};
		await dataGet();
		setLoading(false);
	}, []);
	const profileGet = async () => {
		const profile = await Users.profile().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (profile === undefined) return;
		setProfile(profile);
	};
	const areasGet = async () => {
		const areas = await Areas.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (areas === undefined) return;
		setAreas(areas);
	};
	const rolesGet = async () => {
		const roles = await Roles.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (roles === undefined) return;
		setRoles(roles);
	};
	const pointsGet = async () => {
		const points = await Points.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (points === undefined) return;
		setPoints(points);
	};
	const partnerGet = async () => {
		const partner = await Partners.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (partner === undefined) return;
		setPartner(partner);
	}
	const profileEdit = () => {
		show(profile);
		setIsShow(false);
		setIsEditShow(true);
	};
	const profileShow = () => {
		show(profile);
		setIsEditShow(false);
		setIsShow(true);
	};
	const show = (profile) => {
		setType('profile');
		setProfile(profile);
		setId(profile?._id||null);
		setName(profile?.name||'');
		setComment(profile?.comment||'');
		setEmail(profile?.email||'');
		setPassword(profile?.password||'');
		setPhone(profile?.phone||'');
		setAccess(profile?.points||null);
		setAccessTemp(profile && profile.points && profile.points.length ? [...profile.points] : null);
		if (profile?.points && profile.points.length === 1) {
			const p = profile.points[0];
			setTypeId(p.type);
			setRoleId(p.role.id);
			setAreaId(p.areas.length === 1 ? p.areas[0].id : 0);
		}
		const point = ls('point');
		setAreasPoint(areas.filter(f => f.pointId === point.point.id));
		setRolesPoint(roles.filter(f => f.pointId === point.point.id));
		setAvatar(profile ? avatarUrlGet(partnerId, pointId, profile._id) : null);
		setIsEditShow(true);
	};
	const partnerEdit = () => {
		showPartner(partner);
		setIsShow(false);
		setIsEditShow(true);
	};
	const partnerShow = () => {
		showPartner(partner);
		setIsEditShow(false);
		setIsShow(true);
	};
	const showPartner = (partner) => {
		setType('partner');
		setPartner(partner);
		setId(partner?._id||null);
		setName(partner?.name||'');
		setDescription(partner?.description||'');
		setOfficialName(partner?.requisites?.name||'');
		setAddress(partner?.contacts?.address||'');
		setPhone(partner?.contacts?.phone||'');
		setOfficialAddress(partner?.requisites?.officialAddress||'');
		setInn(partner?.requisites?.inn||'');
		setKpp(partner?.requisites?.kpp||'');
		setOgrn(partner?.requisites?.ogrn||'');
		setOkpo(partner?.requisites?.okpo||'');
		setOkved(partner?.requisites?.okved||'');
		setBank(partner?.requisites?.bank?.name||'');
		setBik(partner?.requisites?.bank?.bik||'');
		setCorrespondent(partner?.requisites?.bank?.correspondent||'');
		setAccount(partner?.requisites?.bank?.account||'');
		setIsEditShow(true);
	};
	const tariffShow = () => {
		showTariff(partner);
		setIsEditShow(false);
		setIsShow(true);
	};
	const showTariff = (partner) => {
		setType('tariff');
		setPartner(partner);
		setIsEditShow(true);
	};
	const cancel = () => {
		setIsEditShow(false);
		setIsShow(true);
	}
	const savePartner = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			description:description,
			officialName:officialName,
			address:address,
			inn:inn,
			kpp:kpp,
			ogrn:ogrn,
			okpo:okpo,
			okved:okved,
			bankName:bank,
			bik:bik,
			correspondent:correspondent,
			account:account
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название компании');
			return;
		}
		const res = await Partners.update(id, data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			partnerGet();
			cancel();
		}
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			email:email,
			password:password,
			phone:Utils.phoneNormalize(phone),
			points:access,
			comment:comment
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить имя сотрудника');
			return;
		}
		if (Utils.empty(data.email)) {
			errorShow('Необходимо заполнить электронную почту');
			return;
		}
		if (!id) {
			if (Utils.empty(data.password)) {
				errorShow('Необходимо заполнить пароль');
				return;
			}
		}
		if (Utils.empty(data.phone)) {
			errorShow('Необходимо заполнить телефон');
			return;
		}
		if (Utils.empty(data.points) || data.points.length === 0) {
			if (areaId === 0 || roleId === 0 || typeId === 0) {
				errorShow('Необходимо заполнить права и доступ');
				return;
			}
			const point = ls('point');
			data.points = [{point:{id:point.point.id},type:typeId,role:{id:roleId},areas:[{id:areaId}]}];
		}
		const res = id ?
				await Users.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.code === 409 ? ex.message : null);
				})
			:
				await Users.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.code === 409 ? ex.message : null);
				});
		if (res) {
			avatarUpload(id||res.id);
			successShow(res.message);
			profileGet();
			cancel();
		}
	}
	const avatarUpload = async (id) => avatar && avatar.indexOf('data:image') === 0 ? await Users.imageAdd(id, {data:avatar.replace(/^.*,/gi,'')}).catch((ex) => console.log(666, ex)) : null;
	const handleType = (e) => setTypeId(parseInt(e.target.value));
	const handleRole = (e) => setRoleId(e.target.value);
	const handleArea = (e) => setAreaId(e.target.value);
	const typeGet = (type) => {
		if (type !== userType.UNKNOWN) {
			const idx = Object.values(userType).indexOf(type);
			if (idx) return userTypeName[idx];
		}
		return null;
	}
	const typesGet = (user) => {
		const types = [];
		user.points.forEach((v) => {
			if (v.type !== userType.UNKNOWN) {
				const t = typeGet(v.type);
				if (t) {
					if (!types.includes(t)) types.push(t);
				}
			}
		});
		return types.length > 0 ? <div className="types">{types.join(', ')}</div> : null;
	}
	const imageError = (e) => e.target.src = imgNoPhoto;
	const avatarDelete = () => setAvatar(null);
	const handleImage = (e) => {
		const file = e.target.files?.[0];
		if (!file) return;
		const reader = new FileReader();
		reader.onload = (r) => setAvatar(r.target.result);
		reader.readAsDataURL(file);
	};
	const accessShow = () => modalAccessOpen();
	const modalAccessHide = () => modalAccessClose();
	const accessAdd = () => {
		if (accessTemp && accessTemp.length === 1) {
			const p = accessTemp[0];
			setTypeId(p.type);
			setRoleId(p.role.id);
			setAreaId(p.areas.length === 1 ? p.areas[0].id : 0);
		}
		setAccess(accessTemp);
		modalAccessHide();
	}
	const roleAddShow = () => modalRolesAddOpen();
	const modalRolesAddHide = () => {
		setPermissions([]);
		modalRolesAddClose();
	}
	const roleSave = async () => {
		const data = {
			name:roleName,
			permissions:permissions
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название роли');
			return;
		}
		const res = await Roles.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			rolesGet();
			modalRolesAddHide();
		}
	};
	const permissionSet = (id) => {
		if (permissions.includes(id)) setPermissions(permissions.filter(f => f !== id));
		else setPermissions([...permissions, id]);
	};
	const isInPermission = (id) => permissions.includes(id);
	const accessBlock = () => <>
		<div className="product-edit-row product-edit-row-oneline">
			<label htmlFor="productProteins">Точка</label>
			<div className="select select-wide">
				<select onChange={handleArea} value={areaId} required>
					<option value=""></option>
					{areasPoint.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
				</select>
			</div>
		</div>
		<div className="product-edit-row product-edit-row-oneline">
			<label htmlFor="productProteins">Должность</label>
			<div className="select select-wide">
				<select onChange={handleType} value={typeId} required>
					<option value=""></option>
					{Object.entries(userType).map(([k,v]) => v === 0 ? null : <option key={k} value={v}>{typeGet(v)}</option>)}
				</select>
			</div>
		</div>
		<div className="product-edit-row product-edit-row-oneline">
			<label htmlFor="productProteins">Роль</label>
			<div className="select select-wide">
				<select onChange={handleRole} value={roleId} required>
					<option value=""></option>
					{rolesPoint.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
				</select>
			</div>
		</div>
	</>;
	const accessPointCheck = (id) => accessTemp?.find(f => f.point.id === id);
	const accessAreaCheck = (id) => accessTemp?.find(f => f.areas.find(a => a.id === id));
	const handleTypeModal = (e, pointId) => {
		accessTemp.forEach((v) => {
			if (v.point.id === pointId) v.type = parseInt(e.target.value);
		});
		setAccessTemp([...accessTemp]);
	}
	const handleRoleModal = (e, pointId) => {
		accessTemp.forEach((v) => {
			if (v.point.id === pointId) v.role = {id:e.target.value};
		});
		setAccessTemp([...accessTemp]);
	}
	const accessAreaSet = (pointId, areaId) => {
		const acc = accessTemp || {point:{id:pointId}};
		const item = acc.find(f => f.point.id === pointId);
		if (!item) {
			accessTemp.push({point:{id:pointId},areas:[{id:areaId}]});
			setAccessTemp([...accessTemp]);
		}
		else {
			const area = item.areas.find(f => f.id === areaId);
			if (area) item.areas.splice(item.areas.indexOf(area), 1);
			else item.areas.push({id:areaId});
			const accTmp = [];
			accessTemp.forEach((v) => {
				if (v.areas.length > 0) accTmp.push(v);
			});
			setAccessTemp([...accTmp]);
		}
	}
	const avatarUrlGet = (partnerId, pointId, userId) => `${API.assets}partners/${partnerId}/${pointId}/users/${userId}/avatar.jpg`;
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
							<SideMenu title="Профиль" type={[{type:'account'},{type:'settings',title:'Настройки'}]} />
								<div className="products-list">
									<div className="container-inner">
										<table className="items items-next">
											<tbody>
												<tr onClick={() => profileShow()}>
													<td className="avatar">
														<img src={avatarUrlGet(partnerId, pointId, profile._id)} alt="" onError={imageError} />
													</td>
													<td>
														{profile.name}
														<span>{typesGet(profile)}</span>
													</td>
												</tr>
											</tbody>
										</table>
										<table className="items items-next">
											<tbody>
												<tr onClick={() => partnerShow()}><td>Реквизиты компании</td></tr>
												<tr onClick={() => tariffShow()}><td>Тариф</td></tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isShow ?
									<>
										{type === 'profile' ?
												<div className="product-edit-list-container">
													<div className="container-inner">
														<div className="list">
															<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
																<div className="product-view-row-oneline product-view-row-oneline-start">
																	<img src={avatarUrlGet(partnerId, pointId, id)} alt="" onError={imageError} className="avatar" />
																	<div>
																		<h3>{name}</h3>
																		<span className="user-type">{typesGet(profile)}</span>
																	</div>
																</div>
																<img src={imgEdit} alt="" className="edit" onClick={() => profileEdit()} />
															</div>
															<h4>Телефон</h4>
															<div className="product-view-row-simple">
																{Utils.phoneFormatter(phone)}
															</div>
															{comment ?
																<>
																	<h4>Комментарий</h4>
																	<div className="product-view-row product-view-row-simple">
																		<p className="comment">{comment}</p>
																	</div>
																</> : null}
														</div>
													</div>
												</div> : null
											}
										{type === 'partner' ?
												<div className="product-edit-list-container">
													<div className="container-inner">
														<div className="list">
															<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
																<div>
																	<h3>{partner.name}</h3>
																	<span className="user-type"> ID: {partner.number}</span>
																</div>
																<img src={imgEdit} alt="" className="edit" onClick={() => partnerEdit()} />
															</div>
															{partner.description ?
																<>
																	<div className="product-view-row product-view-row-simple">
																		<p className="comment">{partner.description}</p>
																	</div>
																</> : null}
															<h4>Контакты</h4>
															<div className="product-view-row">
																<span><b>Адрес</b></span>
																<div>{partner.contacts.address}</div>
															</div>
															<div className="product-view-row">
																<span><b>Телефон</b></span>
																<div>{Utils.phoneFormatter(partner.contacts.phone)}</div>
															</div>
															<h4>Реквизиты</h4>
															<div className="product-view-row">
																<span>Официальное название</span>
																<div><b>{partner.requisites?.name||'–'}</b></div>
															</div>
															<div className="product-view-row">
																<span>Юридический адрес</span>
																<div>{partner.requisites?.address||'–'}</div>
															</div>
															<div className="product-view-row product-view-row-oneline">
																<span>ИНН</span>
																<div>{partner.requisites?.inn||'–'}</div>
															</div>
															<div className="product-view-row product-view-row-oneline">
																<span>КПП</span>
																<div>{partner.requisites?.kpp||'–'}</div>
															</div>
															<div className="product-view-row product-view-row-oneline">
																<span>ОГРН</span>
																<div>{partner.requisites?.ogrn||'–'}</div>
															</div>
															<div className="product-view-row product-view-row-oneline">
																<span>ОКПО</span>
																<div>{partner.requisites?.okpo||'–'}</div>
															</div>
															<div className="product-view-row product-view-row-oneline">
																<span>ОКВЕД</span>
																<div>{partner.requisites?.okved||'–'}</div>
															</div>
														</div>
													</div>
												</div> : null}

										{type === 'tariff' ?
												<div className="product-edit-list-container">
													<div className="container-inner">
														<div className="list">
															<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
																<div>
																	<h3>{partner.tariff.name}</h3>
																</div>
															</div>
															<div className="product-view-row product-view-row-simple">
																<p className="comment">{partner.tariff.description}</p>
															</div>
															<h4>Стоимость</h4>
															<div className="product-view-row product-view-row-simple">
																<p className="comment">{partner.tariff.price} ₽</p>
															</div>
															<h4>Действует до</h4>
															<div className="product-view-row product-view-row-simple">
																<p className="comment">{Moment(partner.license.paidTill).format('DD.MM.YYYY')}</p>
															</div>
														</div>
													</div>
												</div> : null}

									</> : null}
								{isEditShow ?
									<>
										{type === 'profile' ?
											<form className="product-edit-list-container" onSubmit={save}>
												<div className="container-inner">
													<div className="list">
														<div className="product-edit-row">
															<input type="text" {...bindName} placeholder="Ваше имя" className="input-title" autoFocus={true} required />
														</div>
														<div className="product-edit-row">
															<textarea placeholder="Комментарий" {...bindComment} className="lite"></textarea>
														</div>
														<h4>Фотография</h4>
														<div className="product-edit-row">
															{avatar ?
																	<div className="product-edit-images">
																		<div className="product-edit-image">
																			<img src={avatar} alt="" onError={imageError} />
																			<div className="delete" onClick={avatarDelete}></div>
																		</div>
																	</div>
																: null}
															<label>
																<div className="product-edit-btn">
																	Добавить фото
																	<input type="file" accept="image/jpeg,image/png" onChange={handleImage} />
																</div>
															</label>
														</div>
														<h4>Параметры доступа</h4>
														<div className="product-edit-row">
															<input type="email" {...bindEmail} placeholder="Электронная почта" required />
														</div>
														<div className="product-edit-row">
															<input type="password" {...bindPassword} placeholder="Пароль" required={id==null} />
														</div>
														<div className="product-edit-row">
															<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder="Телефон" maskPlaceholder={null} {...bindPhone} required />
														</div>
														<h4>Права и доступ</h4>
														<div className="product-edit-row">
															{access ?
																	(access.length === 1 ?
																			accessBlock()
																		:
																			<div>
																				Комплексный доступ<br/>
																				Для каждого филиала и точки задана должность и роль
																			</div>
																	)
																:
																	accessBlock()
															}
														</div>
														<div className="product-edit-row">
															<button type="button" className="product-edit-btn" onClick={accessShow}>Настроить</button>
														</div>
													</div>
												</div>
												<div className="product-edit-footer">
													<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
													<button type="submit" className="btn-save">Сохранить</button>
												</div>
											</form> : null}
										{type === 'partner' ?
											<form className="product-edit-list-container" onSubmit={savePartner}>
												<div className="container-inner">
													<div className="list">
														<div className="product-edit-row">
															<input type="text" {...bindName} placeholder="Название" className="input-title" autoFocus={true} required />
														</div>
														<div className="product-edit-row">
															<textarea placeholder="Описание" {...bindDescription} className="lite"></textarea>
														</div>
														<h4>Контакты</h4>
														<div className="product-edit-row">
															<input type="text" {...bindAddress} placeholder="Адрес" />
														</div>
														<div className="product-edit-row">
															<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder="Телефон" maskPlaceholder={null} {...bindPhone} />
														</div>
														<h4>Реквизиты</h4>
														<div className="product-edit-row">
															<input type="text" {...bindOfficialName} placeholder="Официальное название" />
														</div>
														<div className="product-edit-row">
															<input type="text" {...bindOfficialAddress} placeholder="Юридический адрес" />
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="inn" className="label-middle">ИНН</label>
															<input id="inn" type="text" {...bindInn} placeholder="ИНН" maxLength={12} />
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="kpp" className="label-middle">КПП</label>
															<input id="kpp" type="text" {...bindKpp} placeholder="КПП" maxLength={9} />
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="ogrn" className="label-middle">ОГРН</label>
															<input id="ogrn" type="text" {...bindOgrn} placeholder="ОГРН" maxLength={15} />
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="okpo" className="label-middle">ОКПО</label>
															<input id="okpo" type="text" {...bindOkpo} placeholder="ОКПО" maxLength={10} />
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="okved" className="label-middle">ОКВЕД</label>
															<input id="okved" type="text" {...bindOkved} placeholder="ОКВЕД" />
														</div>
														<h4>Банковские реквизиты</h4>
														<div className="product-edit-row">
															<input type="text" {...bindBank} placeholder="Наименование банка" />
														</div>
														<div className="product-edit-row">
															<input type="text" {...bindAddress} placeholder="Юридический адрес" />
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="bik" className="label-middle">БИК</label>
															<input id="bik" type="text" {...bindBik} placeholder="БИК" maxLength={9} />
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="correspondent" className="label-middle">Корр.счет</label>
															<input id="correspondent" type="text" {...bindCorrespondent} placeholder="Корреспондентский счет" maxLength={20} />
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="account" className="label-middle">Расчетный счет</label>
															<input id="account" type="text" {...bindAccount} placeholder="Расчетный счет" maxLength={20} />
														</div>
													</div>
												</div>
												<div className="product-edit-footer">
													<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
													<button type="submit" className="btn-save">Сохранить</button>
												</div>
											</form> : null}
									</> : null}
								{isShow || isEditShow ? null : <Empty image={'products'} text={<>Для просмотра или редактирования,<br />выберите одну позицию из списка</>} />}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalAccess>
				<div className="modal modal-access">
					<div className="header">
						<h4>Права и доступ</h4>
						<img src={imgClose} alt="" onClick={modalAccessHide} className="btn-close" />
					</div>
					<div className="container-inner">
						{points.length === 0 && areas.length === 0 ?
								<div className="points-empty">
									<div>
										<p>Не найдены<br/>филиалы и точки</p>
										<p>Начните с добавления филиала</p>
									</div>
									<Link to={'/books/points'} className="button">Добавить филиал</Link>
								</div>
							:
								<>
									{points.length === 0 ?
										<div className="points-empty">
											<div>
												Не найден<br/>ни один филиал
											</div>
											<Link to={'/books/points'} className="button">Добавить филиал</Link>
										</div> : null}
									{areas.length === 0 ?
										<div className="points-empty">
											<div>
												Не найдена<br/>ни одина точка
											</div>
											<Link to={'/books/areas'} className="button">Добавить точку</Link>
										</div> : null}
								</>
						}
						{points.length && areas.length ?
								points.map((v,i) => <div key={i} className="modal-row-points">
									<h5>{v.name}</h5>
									<div className="type-role">
										<div className="select-block">
											<label htmlFor="type">Должность</label>
											<div className="select">
												<select id="type" onChange={(e) => handleTypeModal(e, v._id)} value={accessTemp?.find(f=>f.point.id===v._id)?.type} disabled={!accessPointCheck(v._id)}>
													<option value=""></option>
													{Object.entries(userType).map(([k,v]) => v === 0 ? null : <option key={k} value={v}>{typeGet(v)}</option>)}
												</select>
											</div>
										</div>
										<div className="select-block">
											<label htmlFor="role">Права</label>
											<div className="select">
												<select id="role" onChange={(e) => handleRoleModal(e, v._id)} value={accessTemp?.find(f=>f.point.id===v._id)?.role?.id} disabled={!accessPointCheck(v._id)}>
													<option value=""></option>
													{roles.filter(f => f.pointId === v._id).map((r,k) => <option key={k} value={r._id}>{r.name}</option>)}
												</select>
											</div>
											<img src={imgPlus} alt="" onClick={roleAddShow} />
										</div>
									</div>
									{areas.filter(f => f.pointId === v._id).map((a,j) => <div key={j} className="modal-row-points-area" onClick={() => accessAreaSet(v._id, a._id)}>
										<img src={accessAreaCheck(a._id)?imgCheckOn:imgCheckOff} alt="" />
										<div>{a.name}</div>
									</div>)}
								</div>)
							:
								null
						}
					</div>
					<div className="buttons">
						<button type="button" onClick={modalAccessHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={accessAdd} className="btn-accept">Добавить</button>
					</div>
				</div>
			</ModalAccess>
			<ModalRolesAdd>
				<div className="modal modal-permissions">
					<div className="header">
						<h4>Новая роль</h4>
						<img src={imgClose} alt="" onClick={modalRolesAddHide} className="btn-close" />
					</div>
					<input type="text" {...bindRoleName} placeholder="Название роли" autoFocus={true} />
					<div className="container-inner">
						{permissionTypeName.map((v,i) => <div key={i} className="permission-item" onClick={() => permissionSet(v.id)}>
							<img src={isInPermission(v.id)?imgCheckOn:imgCheckOff} alt="" />
							{v.name}
						</div>)}
					</div>
					<div className="buttons">
						<button type="button" onClick={modalRolesAddHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={roleSave} className="btn-accept">Сохранить</button>
					</div>
				</div>
			</ModalRolesAdd>
		</>
	);
};

export default AccountScreen;