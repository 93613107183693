import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import InputMask from 'react-input-mask';
import Moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';
import OrderInfo from '../../../Components/OrderInfo';

// models
import {Addresses, Clients} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';
import Orfo from '../../../Globals/Orfo';

// constants
import { API, clientStatus, clientStatusName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgClose from './Images/close.svg';
import imgNoPhoto from './Images/no-photo.svg';
import imgEdit from './Images/edit.svg';


// start
const ClientsScreen = (props) => {
	const [ModalOrders, modalOrdersOpen, modalOrdersClose] = useModal('root', {preventScroll:true});
	const [ModalAddressAdd, modalAddressAddOpen, modalAddressAddClose] = useModal('root', {preventScroll:true});
	const [ModalChangeStatus, modalChangeStatusOpen, modalChangeStatusClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [partnerId, setPartnerId] = useState(null);
	const [pointId, setPointId] = useState(null);
	const [clients, setClients] = useState([]);
	const [clientsAll, setClientsAll] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:comment, setValue:setComment, bind:bindComment} = useInput('');
	const {value:email, setValue:setEmail, bind:bindEmail} = useInput('');
	const {value:phone, setValue:setPhone, bind:bindPhone} = useInput('');
	const {value:birthdate, setValue:setBirthdate, bind:bindBirthdate} = useInput('');
	const {value:discount, setValue:setDiscount, bind:bindDiscount} = useInput('');
	const [addressId, setAddressId] = useState(null);
	const {value:addressName, setValue:setAddressName, bind:bindAddressName} = useInput('');
	const {value:addressRoom, setValue:setAddressRoom, bind:bindAddressRoom} = useInput('');
	const {value:addressFloor, setValue:setAddressFloor, bind:bindAddressFloor} = useInput('');
	const {value:addressEntrance, setValue:setAddressEntrance, bind:bindAddressEntrance} = useInput('');
	const {value:addressIntercom, setValue:setAddressIntercom, bind:bindAddressIntercom} = useInput('');
	const {value:addressComment, setValue:setAddressComment, bind:bindAddressComment} = useInput('');
	const [addresses, setAddresses] = useState([]);
	const [clientAddresses, setClientAddresses] = useState([]);
	const [address, setAddress] = useState(null);
	const [clientOrders, setClientOrders] = useState([]);
	const [avatar, setAvatar] = useState(null);
	const [search, setSearch] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [status, setStatus] = useState(null);
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await clientsGet();
			const point = ls('point');
			setPartnerId(point.partner.id);
			setPointId(point.point.id);
		};
		await dataGet();
		setLoading(false);
	}, []);
	useEffect(() => {
		if (clients.length > 0) {
			const client = clients.find(f => f._id === props.match.params.id);
			if (client) clientShow(client);
		}
	}, [clients]);
	const clientsGet = async (id) => {
		const clients = await Clients.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (clients === undefined) return;
		setClients(clients);
		setClientsAll(clients);
		if (id) {
			const client = clients.find(f => f.id === id);
			clientShow(client);
		}
	};
	const clientAdd = () => {
		setIsShow(false);
		setIsEditShow(true);
		show();
	}
	const clientEdit = (e, client) => {
		e.stopPropagation();
		client = client || clients.find(f => f._id === id);
		show(client);
		setIsShow(false);
		setIsEditShow(true);
	};
	const clientShow = (client) => {
		show(client);
		setIsEditShow(false);
		setIsShow(true);
	}
	const show = (client) => {
		setControlId(null);
		setId(client?._id||null);
		setName(client?.name||'');
		setComment(client?.comment||'');
		setEmail(client?.email||'');
		setPhone(client?.phone||'');
		setDiscount(client?.discount||'');
		setBirthdate(Utils.dateNormalize(client, 'birthdate'));
		setStatus(client?client.status:'');
		setAvatar(client ? avatarUrlGet(partnerId, pointId, client._id) : null);
		setClientAddresses(client?.addresses||[]);
		setClientOrders(client?.orders||[]);
	};
	const cancel = () => {
		clientShow(null);
		setIsEditShow(false);
		setIsShow(false);
		modalChangeStatusHide();
		modalAddressAddClose();
		modalOrdersClose();
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			email:email,
			phone:Utils.phoneNormalize(phone),
			comment:comment,
			discount:discount,
			birthdate:birthdate,
			status:status
		};
		if (clientAddresses.length) data.addresses = clientAddresses;
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить имя клиента');
			return;
		}
		if (Utils.empty(data.phone)) {
			errorShow('Необходимо заполнить телефон');
			return;
		}
		const res = id ?
				await Clients.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.code === 409 ? ex.message : null);
				})
			:
				await Clients.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.code === 409 ? ex.message : null);
				});
		if (res) {
			avatarUpload(id||res.id);
			successShow(res.message);
			clientsGet();
			cancel();
		}
	}
	const avatarUpload = async (id) => avatar && avatar.indexOf('data:image') === 0 ? await Clients.imageAdd(id, {data:avatar.replace(/^.*,/gi,'')}).catch((ex) => console.log(666, ex)) : null;
	const searchClient = (e) => {
		const search = e.target.value;
		setSearch(search);
		if (Utils.empty(search)) setClients(clientsAll);
		else setClients(clientsAll.filter(f => f.phone.includes(search) || f.name.toLowerCase().includes(search.toLowerCase())));
	}
	const clientControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const clientChangeStatusOn = (e, id) => {
		e.stopPropagation();
		clientChangeStatus(id);
	}
	const clientChangeStatusModal = () => clientChangeStatus(id);
	const clientChangeStatus = (id) => {
		const client = clients.find(f => f._id === id);
		const status = client.status === clientStatus.ACTIVE ? clientStatus.IN_ACTIVE : clientStatus.ACTIVE;
		Clients.status(id, {status}).then((res) => {
			successShow(res.message);
			clientsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalChangeStatusHide = () => modalChangeStatusClose();
	const modalChangeStatusShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setName(clients.find(f => f._id === id).name);
		modalChangeStatusOpen();
	}
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const imageError = (e) => e.target.src = imgNoPhoto;
	const avatarDelete = () => setAvatar(null);
	const handleImage = (e) => {
		const file = e.target.files?.[0];
		if (!file) return;
		const reader = new FileReader();
		reader.onload = (r) => setAvatar(r.target.result);
		reader.readAsDataURL(file);
	};
	const avatarUrlGet = (partnerId, pointId, clientId) => `${API.assets}partners/${partnerId}/${pointId}/clients/${clientId}/avatar.jpg`;
	const modalAddressAddShow = (address) => {
		setAddress(address);
		setAddressId(address?._id||null);
		setAddressName(address?.address||'');
		setAddressRoom(address?.room||'');
		setAddressFloor(address?.floor||'');
		setAddressEntrance(address?.entrance||'');
		setAddressIntercom(address?.intercom||'');
		setAddressComment(address?.comment||'');
		setAddresses([]);
		modalAddressAddOpen();
	}
	const addressAdd = async () => {
		if (Utils.empty(addressName)) {
			errorShow('Необходимо заполнить адрес');
			return;
		}
		if (Utils.empty(addressRoom)) {
			errorShow('Необходимо заполнить квартиру / офис');
			return;
		}
		if (Utils.empty(address) || Utils.empty(address.latitude) || Utils.empty(address.longitude)) {
			errorShow('Необходимо выбрать адрес из списка');
			return;
		}
		const data = {
			address:addressName,
			room:addressRoom,
			floor:addressFloor,
			entrance:addressEntrance,
			intercom:addressIntercom,
			comment:addressComment,
			latitude:address.latitude,
			longitude:address.longitude
		};
		const addresses = addressId ? clientAddresses.map(v => v._id === addressId ? data : v) : [...clientAddresses, data];
		const res = await Clients.address(id, {addresses}).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			clientsGet();
			setClientAddresses(addresses);
			addressClose();
		}
	}
	const addressClose = () => {
		setAddress(null);
		setAddressName('');
		setAddressRoom('');
		setAddressFloor('');
		setAddressEntrance('');
		setAddressIntercom('');
		setAddressComment('');
		modalAddressAddClose();
	}
	const addressGet = async (e) => {
		const address = e.target.value;
		const addresses = await Addresses.get(address).catch((ex) => console.log(666, ex));
		setAddressName(address);
		setAddresses(addresses);
	}
	const selectAddress = (address) => {
		setAddress(address);
		setAddressName(address.address);
		setAddresses([]);
	}
	const ordersTotalGet = (orders, isdiscount) => orders.reduce((a,v) => a + (isdiscount ? v.amounts.amountFull : v.amounts.amount), 0);
	const ordersAverageGet = (orders) => orders.length ? Math.round(ordersTotalGet(orders, true) / orders.length) : 0;
	const ordersMedianGet = (orders) => {
		if (orders.length === 0) return 0;
		const prices = orders.map(v => v.amounts.amountFull);
		prices.sort((a, b) => a - b);
		const mid = Math.floor(prices.length / 2);
		if (prices.length % 2 !== 0) return prices[mid];
		return (prices[mid - 1] + prices[mid]) / 2;
	}
	const modalOrderHistoryShow = () => modalOrdersOpen();
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Клиенты" type="books" />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchClient} value={search} />
										<button type="button" onClick={(e) => clientAdd()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{clients.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Имя</th>
															<th>Номер телефона</th>
															<th className="discount">Скидка</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{clients.map((v,i) => <tr key={i} onClick={() => clientShow(v)} className={id===v._id?'row-selected':''}>
															<td>{v.name}</td>
															<td>{Utils.phoneFormatter(v.phone)}</td>
															<td className="discount">{v.discount?`${v.discount}%`:'–'}</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => clientControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			<div className="edit" onClick={(e) => clientEdit(e, v)}>Редактировать</div>
																			<div className={v.status===clientStatus.ACTIVE?'inactive':'active'} onClick={(e) => v.status === clientStatus.ACTIVE ? modalChangeStatusShow(e, v._id) : clientChangeStatusOn(e, v._id)}>{v.status===clientStatus.ACTIVE?'Отключить':'Включить'}</div>
																		</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте новых<br/>клиента
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isShow ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													<div className="product-view-row product-view-row-oneline product-view-row-oneline-up">
														<div className="product-view-row-oneline product-view-row-oneline-start">
															<img src={avatarUrlGet(partnerId, pointId, id)} alt="" onError={imageError} className="avatar" />
															<h3>{name}</h3>
														</div>
														<img src={imgEdit} alt="" className="edit" onClick={(e) => clientEdit(e)} />
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Телефон</span>
														<div>{Utils.phoneFormatter(phone)}</div>
													</div>
													{email ?
														<div className="product-view-row product-view-row-oneline">
															<span>Эл.почта</span>
															<div>{email}</div>
														</div> : null}
													{birthdate ?
														<div className="product-view-row product-view-row-oneline">
															<span>Дата рождения</span>
															<div>{Moment(birthdate).format('DD MMMM y')} ({Orfo.counters.years(Moment().diff(birthdate, 'years', false))})</div>
														</div> : null}
													{discount ?
														<div className="product-view-row product-view-row-oneline">
															<span>Скидка</span>
															<div>{discount?`${discount}%`:'–'}</div>
														</div> : null}
													{comment ?
														<div className="product-view-row">
															<span>Комментарий</span>
															<div>{comment}</div>
														</div> : null}
													<h4>Адреса доставки</h4>
													<div className="product-view-row-simple">
														{clientAddresses.length ?
																clientAddresses.map((v,i) => <div key={i} className="product-view-address" onClick={() => modalAddressAddShow(v)}>
																	{v.address}
																</div>)
															:
																<div>Адреса не заданы</div>
														}
													</div>
													<div className="product-edit-row">
														<button type="button" className="product-edit-btn" onClick={modalAddressAddShow}>Добавить адрес</button>
													</div>
													<h4>Статистика заказов</h4>
													<div className="product-view-row-simple">
														<div className="product-view-orders">
															<div className="product-view-orderinfo">
																<span>Количество заказов</span>
																<div>{clientOrders.length}</div>
															</div>
															<div className="product-view-orderinfo">
																<span>Скидка</span>
																<div>{discount ? `${discount}%` : '–'}</div>
															</div>
															<div className="product-view-orderinfo">
																<span>Сумма заказов</span>
																<div>{Utils.moneyFormat(ordersTotalGet(clientOrders, true))} ₽</div>
															</div>
															<div className="product-view-orderinfo">
																<span>Срдений чек</span>
																<div>{Utils.moneyFormat(ordersAverageGet(clientOrders))} ₽</div>
															</div>
															<div className="product-view-orderinfo">
																<span>Медианный чек</span>
																<div>{Utils.moneyFormat(ordersMedianGet(clientOrders))} ₽</div>
															</div>
															<div className="product-view-orderinfo">
																<span>Сумма заказов без скидки</span>
																<div>{Utils.moneyFormat(ordersTotalGet(clientOrders, false))} ₽</div>
															</div>
														</div>
													</div>
													<div className="product-edit-row product-view-row-oneline-start">
														{clientOrders.length ?
																<button type="button" className="product-edit-btn" onClick={modalOrderHistoryShow}>История заказов</button>
															: null}
														<Link to={`/order-add/${id}`} className="product-edit-btn product-edit-btn-second">Добавить заказ</Link>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel btn-cancel-wide">Закрыть</button>
											</div>
										</div>
									: null}
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row">
														<input type="text" {...bindName} placeholder="Имя клиента" className="input-title" autoFocus={true} required />
													</div>
													<div className="product-edit-row">
														<textarea placeholder="Комментарий" {...bindComment} className="lite"></textarea>
													</div>
													<h4>Фотография</h4>
													<div className="product-edit-row">
														{avatar ?
																<div className="product-edit-images">
																	<div className="product-edit-image">
																		<img src={avatar} alt="" onError={imageError} />
																		<div className="delete" onClick={avatarDelete}></div>
																	</div>
																</div>
															: null}
														<label>
															<div className="product-edit-btn">
																Добавить фото
																<input type="file" accept="image/jpeg,image/png" onChange={handleImage} />
															</div>
														</label>
													</div>
													<h4>Реквизиты</h4>
													<div className="product-edit-row">
														<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder="Телефон" maskPlaceholder={null} {...bindPhone} required />
													</div>
													<div className="product-edit-row">
														<input type="email" {...bindEmail} placeholder="Электронная почта" />
													</div>
													<h4>Дополнительные параметры</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="birthdate" className="label-middle">Дата рождения</label>
														<input id="birthdate" {...bindBirthdate} type="date" placeholder="19.12.2000" />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="discount" className="label-middle">Скидка</label>
														<input id="discount" {...bindDiscount} type="text" placeholder="0" maxLength={2} />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="status">Статус</label>
														<div className="select select-middle">
															<select id="status" onChange={handleStatus} value={status} required>
																<option value=""></option>
																{clientStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
															</select>
														</div>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									: null}
								{isShow || isEditShow ? null : <Empty image={'clients'} text={<>Для редактирования клиента,<br />выберите одного из списка</>} />}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalChangeStatus>
				<div className="modal">
					<div className="header">
						<h4>Отключить клиента</h4>
						<img src={imgClose} alt="" onClick={modalChangeStatusHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите отключить клиента <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalChangeStatusHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={clientChangeStatusModal} className="btn-accept btn-delete">Отключить</button>
					</div>
				</div>
			</ModalChangeStatus>
			<ModalAddressAdd>
				<div className="modal modal-address-add">
					<div className="header">
						<h4>{address ? 'Изменить адрес' : 'Добавить новый адрес'}</h4>
						<img src={imgClose} alt="" onClick={addressClose} className="btn-close" />
					</div>
					<div className="container-inner">
						<input type="text" {...bindAddressName} placeholder="Введите адрес (улица и дом)" onChange={addressGet} />
						{addresses.length ?
							<div className="addresses-list">
								{addresses.map((v,i) => <div key={i} onClick={() => selectAddress(v)}>{v.address}</div>)}
							</div> : null}
						<div className="address-form">
							<div>
								<input type="text" {...bindAddressRoom} placeholder="Квартира, офиc" maxLength={5} required />
								<span>кв/офис</span>
							</div>
							<div>
								<input type="text" {...bindAddressEntrance} placeholder="Подъезд" maxLength={3} />
								<span>подъезд</span>
							</div>
							<div>
								<input type="text" {...bindAddressFloor} placeholder="Этаж" maxLength={2} />
								<span>этаж</span>
							</div>
							<div>
								<input type="text" {...bindAddressIntercom} placeholder="Код домофона" maxLength={6} />
								<span>домофон</span>
							</div>
						</div>
						<div className="address-form">
							<textarea {...bindAddressComment} placeholder="Комментарий к адресу"></textarea>
						</div>
					</div>
					<div className="buttons">
						<button type="button" onClick={addressClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={addressAdd} className="btn-accept">Добавить</button>
					</div>
				</div>
			</ModalAddressAdd>
			<ModalOrders>
				<div className="modal modal-orders">
					<div className="header">
						<h4>История заказов</h4>
						<img src={imgClose} alt="" onClick={modalOrdersClose} className="btn-close" />
					</div>
					<div className="container-inner">
						{clientOrders.length > 0 ?
								clientOrders.map((v,i) => <OrderInfo key={i} order={v} clientHide={true} />)
							:
								<div className="orders-empty">
									Заказов пока нет
								</div>
						}
					</div>
				</div>
			</ModalOrders>
		</>
	);
};

export default ClientsScreen;