import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get		= async ()			=> await Http.request('tickets');

const add		= async (data)		=> await Http.request('ticket', httpMethods.POST, data);
const update	= async (id, data)	=> await Http.request(`ticket/${id}`, httpMethods.PUT, data);

const close		= async (id)		=> await Http.request(`ticket/close/${id}`, httpMethods.PATCH);


export {
	get,
	add,
	update,
	close
}