import React, {useEffect,useState} from 'react';
import { useHistory } from "react-router-dom";

// plug-ins
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import SideMenu from '../../../Components/SideMenu';

// models
import {Orders} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// styles
import './styles.css';

// images
import imgMenuPoints from './Images/menu-points.svg';


// start
const FeedbacksScreen = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const [orders, setOrders] = useState([]);
	const [ordersAll, setOrdersAll] = useState([]);
	const [order, setOrder] = useState(null);
	const [search, setSearch] = useState('');
	const [id, setId] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [isShow, setIsShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await ordersGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const ordersGet = async () => {
		const transactions = await Orders.get.feedaback().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (transactions === undefined) return;
		setOrders(transactions);
		setOrdersAll(transactions);
	};
	const orderShow = (order) => {
		show(order);
		setIsShow(true);
	}
	const show = (order) => {
		setId(order?._id||null);
		setOrder(order);
		setIsShow(true);
	};
	const cancel = () => {
		orderShow(null);
		setIsShow(false);
	}
	const searchOrder = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setOrders(ordersAll);
		else setOrders(ordersAll.filter(f => f.client?.name.toLowerCase().includes(search.toLowerCase())
			|| f.number.toString().includes(search.toLowerCase())
			|| f.feedback.comment.toLowerCase().includes(search.toLowerCase())
			|| f.client.phone.includes(search.toLowerCase())));
		setSearch(search);
	}
	const orderControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const goto = (link) => history.push(link);
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Отзывы, рейтинг" type="marketing" />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search search-single" onChange={searchOrder} value={search} />
									</div>
									<div className="container-inner">
										{orders.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Дата</th>
															<th>Заказ</th>
															<th>Клиент</th>
															<th className="status status-small">Оценка</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{orders.map((v,i) => <tr key={i} onClick={() => orderShow(v)} className={id===v._id?'row-selected':''}>
															<td>{moment(v.createdAt).format('DD.MM.YYYY HH:mm')}</td>
															<td>№{v.number}</td>
															<td>{v.client.name}</td>
															<td className="status status-small">
																<div className={`status${v.feedback.rating>3?1:0}`}>
																	<div className="status-point"></div>{v.feedback.rating}
																</div>
															</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => orderControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			<div className="receipt" onClick={(e) => goto(`/order/${v._id}`)}>Перейти в заказ</div>
																			<div className="profile" onClick={(e) => goto(`/books/client/${v.client.id}`)}>Профиль клиента</div>
																		</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Здесь появятся заказы<br/>с отзывами или рейтингом
													</div>
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isShow ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													<div className="product-view-row product-view-row-simple">
														<h3>№{order?.number} от {moment(order?.createdAt).format('DD.MM.YYYY HH:mm')}</h3>
													</div>
													{order?.comment ? 
														<div className="product-view-row-simple">
															{order.comment}
														</div> : null}
													<h4>Отзыв</h4>
													<div className="product-view-row">
														{order?.feedback.comment}
													</div>
													<h4>Оценка</h4>
													<div className="product-view-row">
														<div className={`status${order.feedback.rating>3?1:0}`}>
															<div className="status-point"></div>{order.feedback.rating}
														</div>
													</div>
													<h4>Состав заказа</h4>
													<div className="product-view-row">
														{order?.products.map((v,i) => <div key={i} className="product-view-row product-view-row-oneline">
															{v.name}
															<span>{v.quantity} шт.</span>
														</div>)}
													</div>
													<h4>Клиент</h4>
													<div className="product-view-row">
														{order?.client.name} ({Utils.phoneFormatter(order?.client.phone)})
													</div>
													<h4>Точка</h4>
													<div className="product-view-row">
														{order?.area.name}
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel btn-cancel-wide">Закрыть</button>
											</div>
										</div>
									:
										<Empty image={'points'} text={<>Для просмотра отзыва,<br />выберите заказ из списка</>} />
								}
							</div>
						</div>
					)
				}
			</Template>
		</>
	);
};

export default FeedbacksScreen;