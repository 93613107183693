import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {Areas, ExpenseCategories, Expenses} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { cashboxPaymentType, commonStatus } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgClose from './Images/close.svg';
import imgEdit from './Images/edit.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';


// start
const ExpensesScreen = () => {
	const [ModalCategoryAdd, modalCategoryAddOpen, modalCategoryAddClose] = useModal('root', {preventScroll:true});
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root', {preventScroll:true});
	const [ModalPay, modalPayOpen, modalPayClose] = useModal('root', {preventScroll:true});
	const [ModalUnpay, modalUnpayOpen, modalUnpayClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [areas, setAreas] = useState([]);
	const [expensesAll, setExpensesAll] = useState([]);
	const [expenses, setExpenses] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:amount, setValue:setAmount, bind:bindAmount} = useInput('');
	const {value:date, setValue:setDate, bind:bindDate} = useInput('');
	const [categoryId, setCategoryId] = useState(null);
	const [areaId, setAreaId] = useState([]);
	const [categories, setCategories] = useState([]);
	const {value:categoryName, setValue:setCategoryName, bind:bindCategoryName} = useInput('');
	const [status, setStatus] = useState(null);
	const [search, setSearch] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await areasGet();
			await categoriesGet();
			await expensesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const areasGet = async () => {
		const areas = await Areas.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (areas === undefined) return;
		setAreas(areas);
	};
	const categoriesGet = async () => {
		const categories = await ExpenseCategories.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (areas === undefined) return;
		setCategories(categories);
	};
	const expensesGet = async () => {
		const expenses = await Expenses.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (expenses === undefined) return;
		setExpenses(expenses);
		setExpensesAll(expenses);
	};
	const expenseAdd = () => {
		setIsShow(false);
		setIsEditShow(true);
		show();
	}
	const expenseEdit = (e, expense) => {
		e.stopPropagation();
		expense = expense || expenses.find(f => f._id === id);
		show(expense);
		setIsShow(false);
		setIsEditShow(true);
	};
	const expenseShow = (expense) => {
		show(expense);
		setIsEditShow(false);
		setIsShow(true);
	};
	const show = (expense) => {
		setControlId(null);
		setId(expense?._id||null);
		setName(expense?.name||'');
		setAmount(expense?.amount||'');
		setDate(Utils.dateTimeNormalize(expense, 'date'));
		setAreaId(expense?expense.area?.id:null);
		setCategoryId(expense?expense.category.id:'');
		setStatus(expense?expense.status:null);
		setIsEditShow(true);
	};
	const cancel = () => {
		expenseShow(null);
		setIsEditShow(false);
		setIsShow(false);
		modalDeleteHide();
		modalCategoryAddClose();
		modalPayClose();
		modalUnpayClose();
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			amount:amount,
			date:date,
			areaId:areaId,
			categoryId:categoryId
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название причины расхода');
			return;
		}
		if (Utils.empty(data.amount)) {
			errorShow('Необходимо заполнить сумму');
			return;
		}
		if (Utils.empty(data.date)) {
			errorShow('Необходимо заполнить дату');
			return;
		}
		if (Utils.empty(data.areaId)) {
			errorShow('Необходимо выбрать точку');
			return;
		}
		if (Utils.empty(data.categoryId)) {
			errorShow('Необходимо выбрать категорию');
			return;
		}
		const res = id ?
				await Expenses.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Expenses.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			expensesGet();
			cancel();
		}
	}
	const searchExpense = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setExpenses(expensesAll);
		else setExpenses(expensesAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const expenseControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const expenseDelete = () => {
		Expenses.remove(id).then((res) => {
			successShow(res.message);
			expensesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalDeleteHide = () => modalDeleteClose();
	const modalDeleteShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setName(expenses.find(f => f._id === id).name);
		modalDeleteOpen();
	}
	const handleCategory = (e) => setCategoryId(e.target.value);
	const areaSelect = (id) => setAreaId(id);
	const categoryAddShow = () => {
		setCategoryName('');
		modalCategoryAddOpen();
	}
	const categorySave = async () => {
		if (Utils.empty(categoryName)) return;
		const data = {name:categoryName};
		const res = await ExpenseCategories.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			categoriesGet();
			modalCategoryAddClose();
		}
	}
	const expensePayShow = (e, id, type) => {
		e?.stopPropagation();
		setControlId(null);
		setId(id);
		setAmount(expenses.find(f => f._id === id).amount);
		if (type === 'pay') modalPayOpen();
		if (type === 'unpay') modalUnpayOpen();
	}
	const expensePay = (type, paymentType) => {
		Expenses.pay[type](id, paymentType).then((res) => {
			successShow(res.message);
			expensesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Прочие расходы" type="money" />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchExpense} value={search} />
										<button type="button" onClick={() => expenseAdd()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{expenses.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Название</th>
															<th className="prices">Сумма</th>
															<th className="status status-small">Статус</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{expenses.map((v,i) => <tr key={i} onClick={() => expenseShow(v)}>
															<td>{v.name}</td>
															<td className="prices">{v.amount} ₽</td>
															<td className="status status-small">
																<div className={`status${v.status}`}>
																	<div className="status-point"></div>
																</div>
															</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => expenseControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			<div className="edit" onClick={(e) => expenseEdit(e, v)}>Редактировать</div>
																			{v.status === commonStatus.ACTIVE ?
																					<div className="check" onClick={(e) => expensePayShow(e, v._id, 'pay')}>Оплатить</div>
																				:
																					<div className="uncheck" onClick={(e) => expensePayShow(e, v._id, 'unpay')}>Отозвать оплату</div>
																			}
																			<div className="delete" onClick={(e) => modalDeleteShow(e, v._id)}>Удалить</div>
																		</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте первую<br/>расходную запись
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isShow ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
														<h3>{name}</h3>
														<img src={imgEdit} alt="" className="edit" onClick={(e) => expenseEdit(e)} />
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Операция на точке точке</span>
														<div>{areas.find(f => f._id === areaId)?.name}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Категория</span>
														<div>{categories.find(f => f._id === categoryId)?.name}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Дата</span>
														<div>{moment(date).format('DD.MM.YYYY HH:mm')}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Сумма</span>
														<div><b>{amount} ₽</b></div>
													</div>
													<div className="product-edit-row">
														{status === commonStatus.ACTIVE ?
																<div className="product-edit-btn" onClick={() => expensePayShow(null, id, 'pay')}>Оплатить из кассы</div>
															:
																<div className="product-edit-btn" onClick={() => expensePayShow(null, id, 'unpay')}>Отозвать оплату</div>
														}
													</div>
												</div>
											</div>
										</div>
									: null}
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row">
														<input type="text" {...bindName} placeholder="Наименование" className="input-title" autoFocus={true} required />
													</div>
													<div className="product-edit-row product-edit-row-oneline product-edit-row-plus">
														<div className="select select-wide">
															<select onChange={handleCategory} value={categoryId} required>
																<option value="">Выберите категорию</option>
																{categories.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
															</select>
														</div>
														<img src={imgPlusButton} alt="" className="categoty-add" onClick={() => categoryAddShow()} />
													</div>
													<h4>Операция на точке</h4>
													<div className="product-edit-row">
														{areas.map((v,i) => <div key={i} className="product-edit-area product-edit-row-oneline-start">
															<img src={v._id === areaId?imgCheckOn:imgCheckOff} alt="" className="checkbox" onClick={() => areaSelect(v._id)} />
															{v.name}
														</div>)}
													</div>
													<h4>Параметры</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="date" className="label-middle">Дата операции</label>
														<input id="date" {...bindDate} type="datetime-local" required />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="amount" className="label-middle">Сумма, ₽</label>
														<input id="amount" {...bindAmount} type="text" placeholder="0" required />
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									: null}
								{isShow || isEditShow ? null : <Empty image={'caregories'} text={<>Для редактирования записи,<br />выберите одину из списка</>} />}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalCategoryAdd>
				<div className="modal modal-component-add">
					<div className="header">
						<h4>Добавить категорию</h4>
						<img src={imgClose} alt="" onClick={modalCategoryAddClose} className="btn-close" />
					</div>
					<input type="text" {...bindCategoryName} placeholder="Название категории" autoFocus={true} />
					<div className="buttons">
						<button type="button" onClick={modalCategoryAddClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={categorySave} className="btn-accept">Сохранить</button>
					</div>
				</div>
			</ModalCategoryAdd>
			<ModalPay>
				<div className="modal modal-expense">
					<div className="header">
						<h4>Оплата расходной операции</h4>
						<img src={imgClose} alt="" onClick={modalPayClose} className="btn-close" />
					</div>
					<p>Будет произведена операция списания из кассы на сумму <b>{amount}₽</b></p>
					<div className="buttons">
						<button type="button" onClick={modalPayClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={() => expensePay('pay', cashboxPaymentType.CASH)} className="btn-accept">Наличные</button>
						<button type="button" onClick={() => expensePay('pay', cashboxPaymentType.CARD)} className="btn-accept">Безналичные</button>
					</div>
				</div>
			</ModalPay>
			<ModalUnpay>
				<div className="modal">
					<div className="header">
						<h4>Отзыв расходной операции</h4>
						<img src={imgClose} alt="" onClick={modalUnpayClose} className="btn-close" />
					</div>
					<p>
						Если смена открыта, изъятие из кассы на сумму оплаты <b>{amount}₽</b> будет удалено.<br/>
						Если смена закрыта, то будет произведен возврат средств (внесение) за оплату в кассу.
					</p>
					<div className="buttons">
						<button type="button" onClick={modalUnpayClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={() => expensePay('unpay')} className="btn-accept btn-delete">Отозвать</button>
					</div>
				</div>
			</ModalUnpay>
			<ModalDelete>
				<div className="modal">
					<div className="header">
						<h4>Удалить расход</h4>
						<img src={imgClose} alt="" onClick={modalDeleteHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите удалить <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalDeleteHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={expenseDelete} className="btn-accept btn-delete">Удалить</button>
					</div>
				</div>
			</ModalDelete>
		</>
	);
};

export default ExpensesScreen;