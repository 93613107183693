import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get		= async ()			=> await Http.request('expenses');

const add		= async (data)		=> await Http.request('expense', httpMethods.POST, data);
const update	= async (id, data)	=> await Http.request(`expense/${id}`, httpMethods.PUT, data);

const pay		= {
	pay			: async (id, type)	=> await Http.request(`expense/pay/${id}`, httpMethods.PATCH, {type}),
	unpay		: async (id)		=> await Http.request(`expense/unpay/${id}`, httpMethods.PATCH)
};

const remove	= async (id)		=> await Http.request(`expense/${id}`, httpMethods.DELETE);


export {
	get,
	add,
	update,
	pay,
	remove
}