import React, {useEffect,useState} from 'react';

// plug-ins
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import SideMenu from '../../../Components/SideMenu';

// models
import {Areas, DayShifts} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';

// constants
import { commonStatusDayShiftName } from '../../../Globals/Constants';

// styles
import './styles.css';


// start
const DayShiftsScreen = () => {
	const [loading, setLoading] = useState(true);
	const [dayShifts, setDayShifts] = useState([]);
	const [areas, setAreas] = useState([]);
	const [dayShift, setDayShift] = useState(null);
	const [isShow, setIsShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await areasGet();
			await dayShiftsGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const dayShiftsGet = async () => {
		const dayShifts = await DayShifts.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (dayShifts === undefined) return;
		setDayShifts(dayShifts);
	};
	const areasGet = async () => {
		const areas = await Areas.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (areas === undefined) return;
		setAreas(areas);
	};
	const datShiftShow = (dayShift) => {
		show(dayShift);
		setIsShow(true);
	};
	const show = (dayShift) => setDayShift(dayShift);
	const areaNameGet = (id) => areas.find(f => f._id === id)?.name;
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Смены" type="statistics" />
								<div className="products-list">
									<div className="container-inner container-inner-nosearch">
										{dayShifts.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Название / точка</th>
															<th className="date">Дата</th>
															<th className="status status-last">Статус</th>
														</tr>
													</thead>
													<tbody>
														{dayShifts.map((v,i) => <tr key={i} onClick={() => datShiftShow(v)}>
															<td>
																Смена №{v.number}
																<span>{areaNameGet(v.areaId)}</span>
															</td>
															<td className="date">{moment(v.date).format('DD.MM.YYYY')}</td>
															<td className="status status-last">
																<div className={`status${v.status}`}>
																	<div className="status-point"></div>{commonStatusDayShiftName[v.status]}
																</div>
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте новую<br/>смену используя пункт меню вверху
													</div>
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isShow ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													<div className="product-view-row product-view-row-simple">
														<h3>Смена №{dayShift.number}</h3>
														<span className="user-type">{moment(dayShift.date).format('DD.MM.YYYY')}</span>
													</div>
													<div className="product-view-row-simple">
														Статус <b>{commonStatusDayShiftName[dayShift.status]}</b>
													</div>
													<h4>Точка</h4>
													<div className="product-view-row-simple">
														{areaNameGet(dayShift.areaId)}
													</div>
													<h4>Открыта</h4>
													<div className="product-view-row-simple product-edit-row-oneline">
														<label>Дата и время</label>
														<div>{moment(dayShift.start).format('DD.MM.YYYY HH:mm:ss')}</div>
													</div>
													<div className="product-view-row-simple product-edit-row-oneline">
														<label>Оветственный</label>
														<div>{dayShift.users.opener.name}</div>
													</div>
													{dayShift.users.closer ?
														<>
															<h4>Закрыта</h4>
															<div className="product-view-row-simple product-edit-row-oneline">
																<label>Дата и время</label>
																<div>{moment(dayShift.end).format('DD.MM.YYYY HH:mm:ss')}</div>
															</div>
															<div className="product-view-row-simple product-edit-row-oneline">
																<label>Оветственный</label>
																<div>{dayShift.users.opener.name}</div>
															</div>
														</> : null}
												</div>
											</div>
										</div>
									:
										<Empty image={'settings'} text={<>Для просмотра смены,<br />выберите одину из списка</>} />}
							</div>
						</div>
					)
				}
			</Template>
		</>
	);
};

export default DayShiftsScreen;