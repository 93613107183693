import React, {useEffect,useState} from 'react';

// components
import Alert,{errorShow} from '../../Components/Alert';

// models
import {Partners,Users} from './../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import Utils from '../../Globals/Utils';

// styles
import './styles.css';

// images
import imgLogo from './Images/logo.svg';


// start
const LoginScreen = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [usernameError, setUsernameError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [isPointSelect, setIsPointSelect] = useState(false);
	const [points, setPoints] = useState(null);
	useEffect(async () => {
		await Partners.check().catch((ex) => console.log(666, ex));
	}, []);
	const next = async () => {
		clear();
		if (Utils.empty(username)) {
			setUsernameError(true);
			return;
		}
		if (Utils.empty(password)) {
			setPasswordError(true);
			return;
		}
		const res = await Users.login(username, password).catch((ex) => {
			console.log(666, ex);
			return error(ex);
		});
		if (res === undefined) return;
		setPoints(res.user.points);
		ls('user', res.user);
		ls('token', res.token);
		if (res.user.points.length === 1) pointSelect(res.user.points[0]);
		else setIsPointSelect(true);
	}
	const clear = () => {
		setUsernameError(false);
		setPasswordError(false);
	}
	const error = (message) => {
		setUsernameError(true);
		setPasswordError(true);
		errorShow(message.message);
	}
	const handleUsername = (e) => setUsername(e.target.value);
	const handlePassword = (e) => setPassword(e.target.value);
	const pointSelect = (point) => {
		ls('point', point);
		window.location.href='/';
	}
	return (
		<>
			<div className="login-container">
				<img src={imgLogo} alt="" />
				{isPointSelect ?
						<>
							<div className="title">Выберите точку с которой продолжить работать</div>
							<div className="points-list">
								{points.map((v,i) => <div key={i} className="point" onClick={() => pointSelect(v)}>{v.point.name}</div>)}
							</div>
						</>
					:
						<>
							<div className="form-column">
								<label>
									<input type="text" placeholder=" " iserror={`${usernameError!==undefined&&usernameError}`} value={username} onChange={handleUsername} className="input-panel" maxLength={32} />
									<span className="placeholder">Имя пользователя</span>
								</label>
							</div>
							<div className="form-column">
								<label>
									<input type="password" placeholder=" " iserror={`${passwordError!==undefined&&passwordError}`} value={password} onChange={handlePassword} className="input-panel" maxLength={32} />
									<span className="placeholder">Пароль</span>
								</label>
							</div>
							<button onClick={next} className="btn">Войти</button>
						</>
				}
			</div>
			<Alert />
		</>
	);
}

export default LoginScreen;