import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {ComponentCategories, Components, Reasons, Workshops, Writeoffs} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { commonStatus, commonStatusName, measureTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlus from './Images/plus-black.svg';
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgClose from './Images/close.svg';
import imgEdit from './Images/edit.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgArrowCurveDown from './Images/arrow-curve-down.svg';
import imgAdd from './Images/add.svg';
import imgCopy from './Images/copy.svg';


// start
const WriteoffsScreen = () => {
	const componentAmountGet = (t) => {
		const p = t.id === 'price' ? t.value : price || 0;
		const q = t.id === 'quantity' ? t.value : quantity || 0;
		const a = p * q;
		setTotalAmount(a > 0 ? Number(a.toFixed(2)) : 0);
	};
	const [ModalComponent, modalComponentOpen, modalComponentClose] = useModal('root', {preventScroll:true});
	const [ModalItem, modalItemOpen, modalItemClose] = useModal('root', {preventScroll:true});
	const [ModalItemComponents, modalItemComponentsOpen, modalItemComponentsClose] = useModal('root', {preventScroll:true});
	const [ModalCategoryAdd, modalCategoryAddOpen, modalCategoryAddClose] = useModal('root', {preventScroll:true});
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [writeoffsAll, setWriteoffsAll] = useState([]);
	const [writeoffs, setWriteoffs] = useState([]);
	const [id, setId] = useState(null);
	const [name, setName] = useState(null);
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:date, setValue:setDate, bind:bindDate} = useInput('');
	const [items, setItems] = useState([]);
	const [total, setTotal] = useState(0);
	const [inventory, setInventory] = useState([]);
	const [reasonId, setReasonId] = useState(null);
	const [reasons, setReasons] = useState([]);
	const {value:quantity, setValue:setQuantity, bind:bindQuantity} = useInput('', componentAmountGet);
	const {value:price, setValue:setPrice, bind:bindPrice} = useInput('', componentAmountGet);
	const [totalAmount, setTotalAmount] = useState(0);
	const [workshopId, setWorkshopId] = useState(null);
	const [workshops, setWorkshops] = useState([]);
	const [component, setComponent] = useState(null);
	const [components, setComponents] = useState([]);
	const [categoryId, setCategoryId] = useState(null);
	const [categories, setCategories] = useState([]);
	const {value:categoryName, setValue:setCategoryName, bind:bindCategoryName} = useInput('');
	const {value:componentName, setValue:setComponentName, bind:bindComponentName} = useInput('');
	const {value:componentDescription, setValue:setComponentDescription, bind:bindComponentDescription} = useInput('');
	const {value:componentWeight, setValue:setComponentWeight, bind:bindComponentWeight} = useInput('');
	const {value:componentMeasureType, setValue:setComponentMeasureType, bind:bindComponentMeasureType} = useInput('');
	const {value:componentPrice, setValue:setComponentPrice, bind:bindComponentPrice} = useInput('');
	const {value:componentInvoicePrice, setValue:setComponentInvoicePrice, bind:bindComponentInvoicePrice} = useInput('');
	const {value:componentMinimum, setValue:setComponentMinimum, bind:bindComponentMinimum} = useInput('');
	const {value:componentRequestCount, setValue:setComponentRequestCount, bind:bindComponentRequestCount} = useInput('');
	const [componentStatus, setComponentStatus] = useState('');
	const [isPieces, setIsPieces] = useState(false);
	const [status, setStatus] = useState('');
	const [search, setSearch] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await workshopsGet();
			await reasonsGet();
			await categoriesGet();
			await componentsGet();
			await writeoffsGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const writeoffsGet = async () => {
		const writeoffs = await Writeoffs.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (writeoffs === undefined) return;
		setWriteoffs(writeoffs);
		setWriteoffsAll(writeoffs);
	};
	const workshopsGet = async () => {
		const workshops = await Workshops.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (workshops === undefined) return;
		setWorkshops(workshops);
	};
	const categoriesGet = async () => {
		const categories = await ComponentCategories.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (categories === undefined) return;
		setCategories(categories);
	};
	const componentsGet = async () => {
		const components = await Components.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (components === undefined) return;
		setComponents(components);
	};
	const reasonsGet = async () => {
		const reasons = await Reasons.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (reasons === undefined) return;
		setReasons(reasons);
	};
	const writeoffAdd = () => {
		setIsShow(false);
		setIsEditShow(true);
		show();
	}
	const writeoffEdit = (e, writeoff) => {
		e.stopPropagation();
		writeoff = writeoff || writeoffs.find(f => f._id === id);
		show(writeoff);
		setIsShow(false);
		setIsEditShow(true);
	};
	const writeoffCopy = (e, writeoff) => {
		e.stopPropagation();
		writeoff = writeoff || writeoffs.find(f => f._id === id);
		show(writeoff);
		setIsShow(false);
		setIsEditShow(true);
		setId(null);
	};
	const writeoffShow = (writeoff) => {
		show(writeoff);
		setIsEditShow(false);
		setIsShow(true);
	};
	const show = (writeoff) => {
		setControlId(null);
		setId(writeoff?._id||null);
		setName(writeoff ? moment(writeoff.date).format('DD.MM.YYYY HH:mm') : '');
		setDate(Utils.dateTimeNormalize(writeoff, 'date'));
		setDescription(writeoff?.description||'');
		setReasonId(writeoff?.reason?.id||null);
		setItems(writeoff?.items||[]);
		setTotal(writeoff?.total||0);
		setInventory(writeoff?.inventory||null);
		setWorkshopId(writeoff?.workshop?.id||null);
		setStatus(writeoff?.status||'');
		setIsEditShow(true);
	};
	const cancel = () => {
		writeoffShow(null);
		setIsEditShow(false);
		setIsShow(false);
		modalDeleteHide();
		modalCategoryAddClose();
		modalComponentClose();
		modalItemComponentsClose();
		modalItemClose();
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			date:date,
			workshopId:workshopId,
			reasonId:reasonId,
			description:description,
			items:items,
			total:calcTotalGet()
		};
		if (Utils.empty(data.date)) {
			errorShow('Необходимо заполнить дату');
			return;
		}
		if (Utils.empty(data.reasonId)) {
			errorShow('Необходимо выбрать причину списания');
			return;
		}
		if (Utils.empty(data.workshopId)) {
			errorShow('Необходимо выбрать склад');
			return;
		}
		if (items.length === 0) {
			errorShow('Необходимо добавить компоненты');
			return;
		}
		const res = id ?
				await Writeoffs.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Writeoffs.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			writeoffsGet();
			cancel();
		}
	}
	const searchWriteoff = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setWriteoffs (writeoffsAll);
		else setWriteoffs(writeoffsAll.filter(f => f.date.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const writeoffControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const writeoffDelete = () => {
		Writeoffs.remove(id).then((res) => {
			successShow(res.message);
			writeoffsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalDeleteHide = () => modalDeleteClose();
	const modalDeleteShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		const i = writeoffs.find(f => f._id === id);
		setName(moment(i.date).format('DD.MM.YYYY HH:mm'));
		modalDeleteOpen();
	}
	const handleReason = (e) => setReasonId(e.target.value);
	const handleWorkshop = (e) => setWorkshopId(e.target.value);
	const componentsItemShow = () => modalItemComponentsOpen();
	const componentSelect = (component) => {
		setComponent(component);
		const amount = component.prices?.manual||component.prices?.invoice||0;
		setPrice(amount);
		modalItemComponentsClose();
	}
	const componentAdd = async (e) => {
		e.preventDefault();
		if (!component) {
			errorShow('Необходимо выбрать компонент');
			return;
		}
		const data = {
			id:component._id,
			name:component.name,
			price:parseFloat(price),
			quantity:parseFloat(quantity),
			weight:component.weight,
			measure:component.measure,
			total:totalAmount
		};
		if (Utils.empty(data.price) || Utils.empty(data.quantity)) {
			errorShow('Необходимо заполнить все поля');
			return;
		}
		setItems([...items, data]);
		modalItemClose();
	}
	const categoryAddShow = () => {
		setCategoryName('');
		modalCategoryAddOpen();
	}
	const categorySave = async () => {
		if (Utils.empty(categoryName)) return;
		const data = {name:categoryName};
		const res = await ComponentCategories.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			categoriesGet();
			modalCategoryAddClose();
		}
	}
	const componentAddShow = () => {
		setCategoryId(null);
		setComponentName('');
		setComponentDescription('');
		setComponentPrice('');
		setComponentInvoicePrice('');
		setComponentMinimum('');
		setComponentRequestCount('');
		setComponentWeight('');
		setComponentMeasureType('');
		setIsPieces(false);
		setComponentStatus('');
		modalComponentOpen();
	}
	const handleCategory = (e) => setCategoryId(e.target.value);
	const handleMeasureType = (e) => setComponentMeasureType(parseInt(e.target.value));
	const itemShow = () => {
		setPrice('');
		setQuantity('');
		setTotalAmount(0);
		setComponent(null);
		setCategoryId(null);
		modalItemOpen();
	}
	const componentSave = async (e) => {
		e.preventDefault();
		const data = {
			categoryId:categoryId,
			name:componentName,
			description:componentDescription,
			price:componentPrice,
			requestCount:componentRequestCount,
			minimum:componentMinimum,
			weight:componentWeight,
			measure:componentMeasureType,
			isPieces:isPieces
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название компонента');
			return;
		}
		if (Utils.empty(data.weight)) {
			errorShow('Необходимо заполнить объем / вес компонента');
			return;
		}
		if (data.measure === null) {
			errorShow('Необходимо заполнить единицу измерения компонента');
			return;
		}
		const res = id ?
				await Components.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Components.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			componentsGet();
			modalComponentClose();
		}
	}
	const handleComponentStatus = (e) => setComponentStatus(parseInt(e.target.value));
	const calcTotalGet = () => items.reduce((acc, v) => acc += parseFloat(v.total), 0);
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Списания" type="warehouse" />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchWriteoff} value={search} />
										<button type="button" onClick={() => writeoffAdd()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{writeoffs.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Дата</th>
															<th className="prices">Сумма</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{writeoffs.map((v,i) => <tr key={i} onClick={() => writeoffShow(v)}>
															<td>{moment(v.date).format('DD.MM.YYYY HH:mm')}</td>
															<td className="prices">{v.total} ₽</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => writeoffControlSelect(e, v._id)} />
																{controlId === v._id ?
																	(
																		v.status === commonStatus.ACTIVE ?
																			<div className="control-block">
																				<div className="edit" onClick={(e) => writeoffEdit(e, v)}>Редактировать</div>
																				<div className="copy" onClick={(e) => writeoffCopy(e, v)}>Копировать</div>
																				<div className="delete" onClick={(e) => modalDeleteShow(e, v._id)}>Удалить</div>
																			</div>
																		:
																			<div className="control-block">
																				<div className="copy" onClick={(e) => writeoffCopy(e, v)}>Копировать</div>
																			</div>
																	) : null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте первую<br/>накладную
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isShow ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
														<h3>{name}</h3>
														<div className="product-view-row-oneline">
															{status === commonStatus.ACTIVE ? <img src={imgEdit} alt="" className="edit" onClick={(e) => writeoffEdit(e)} /> : null}
															<img src={imgCopy} alt="" className="edit" onClick={(e) => writeoffCopy(e)} />
														</div>
													</div>
													{description ?
														<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
													<div className="product-view-row product-view-row-oneline">
														<span>Дата</span>
														<div>{moment(date).format('DD.MM.YYYY HH:mm')}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Склад</span>
														<div>{workshops.find(f => f._id === workshopId)?.name||'–'}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Причина</span>
														<div>{reasons.find(f => f._id === reasonId)?.name||'–'}</div>
													</div>
													{inventory ?
														<div className="product-view-row product-view-row-oneline">
															<span>Инвентаризация от</span>
															<div>{moment(inventory.date).format('DD.MM.YYYY HH:mm')}</div>
														</div> : null}
													<h4>Компоненты</h4>
													{items.length ?
															<div className="product-edit-components-block">
																{items.map((v,i) => <div key={i} className="product-edit-component-item">
																	<div className="product-edit-component-item-name">
																		<b>{v.name}</b>
																	</div>
																	<div className="info">
																		<div><b>сумма: {v.total}₽</b> • количество: {v.quantity}{measureTypeName[v.measure]} • цена: {v.price}₽</div>
																	</div>
																</div>)}
															</div>
														: null}
													<div className="product-edit-row product-edit-row-oneline product-edit-row-select">
														<label className="label-middle"><b>Итого</b></label>
														<b>{total} ₽</b>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel btn-cancel-wide">Закрыть</button>
											</div>
										</div>
									: null}
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row product-edit-row-oneline product-edit-row-first">
														<label htmlFor="date" className="label-middle">Дата</label>
														<input id="date" {...bindDate} type="datetime-local" required />
													</div>
													<div className="product-edit-row">
														<div className="select select-wide">
															<select onChange={handleWorkshop} value={workshopId} required>
																<option value="">Выберите склад</option>
																{workshops.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
															</select>
														</div>
													</div>
													<div className="product-edit-row">
														<div className="select select-wide">
															<select onChange={handleReason} value={reasonId} required>
																<option value="">Выберите причину списания</option>
																{reasons.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
															</select>
														</div>
													</div>
													<div className="product-edit-row">
														<textarea placeholder="Примечание" {...bindDescription} className="lite"></textarea>
													</div>
													<h4>Компоненты</h4>
													<div className="product-edit-row">
														{items.length ?
																<div className="product-edit-components-block">
																	{items.map((v,i) => <div key={i} className="product-edit-component-item">
																		<div className="product-edit-component-item-name">
																			<b>{v.name}</b>
																			<img src={imgClose} alt="" onClick={() => setItems(items.filter(f => f.id !== v.id))} />
																		</div>
																		<div className="info">
																			<div><b>сумма: {v.total}₽</b> • количество: {v.quantity}{measureTypeName[v.measure]} • цена: {v.price}₽</div>
																		</div>
																	</div>)}
																</div>
															: null
														}
														<div className="product-edit-btn" onClick={() => itemShow()}>Добавить</div>
													</div>
													{items.length ?
														<div className="product-edit-row product-edit-row-oneline product-edit-row-select">
															<label className="label-middle"><b>Итого</b></label>
															<b>{calcTotalGet().toFixed(2)} ₽</b>
														</div> : null}
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									: null}
								{isShow || isEditShow ? null : <Empty image={'components'} text={<>Для просмотра накладной,<br />выберите одину из списка</>} />}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalItem>
				<div className="modal modal-items">
					<div className="header">
						<h4>Добавить компонент</h4>
						<img src={imgClose} alt="" onClick={modalItemClose} className="btn-close" />
					</div>
					<form onSubmit={componentAdd}>
						{component ?
								<div>
									<b>{component.name}</b> • <span>{component.prices?.manual||component.prices?.invoice||0} ₽ за {component.weight} {measureTypeName[component.measure]}</span>
								</div>
							: null}
						<div className="buttons">
							<button type="button" className="btn-cancel btn-select" onClick={() => componentsItemShow()}>{component ? 'Изменить компонент' : 'Выбрать компонент'}</button>
						</div>
						<div className="weights-form">
							<div className="weights-form-row">
								<label htmlFor="price">Цена{component ? <span>компонента за {component.weight} {measureTypeName[component.measure]}</span> : null}</label>
								<div>
									<input id="price" type="text" {...bindPrice} placeholder="0" maxLength={5} required />
									<span>₽</span>
								</div>
							</div>
							<div className="weights-form-row">
								<label htmlFor="quantity">Количество</label>
								<div>
									<input id="quantity" type="text" {...bindQuantity} placeholder="0" maxLength={5} required />
									{component ? <span>{measureTypeName[component.measure]}</span> : <span>–</span>}
								</div>
							</div>
						</div>
						<div className="weights-form">
							<div className="weights-form-row">
								<label>Сумма</label>
								<div>
									<b>{totalAmount} ₽</b>
								</div>
							</div>
						</div>
						<div className="buttons">
							<button type="submit" className="btn-accept">Добавить</button>
						</div>
					</form>
				</div>
			</ModalItem>
			<ModalItemComponents>
				<div className="modal modal-conditions-lite">
					<div className="header">
						<div className="header-sub">
							<h4>Добавить компонент</h4>
							<img src={imgAdd} alt="" className="add" onClick={() => componentAddShow()} />
						</div>
						<img src={imgClose} alt="" onClick={modalItemComponentsClose} className="btn-close" />
					</div>
					<div className="conditions">
						<div className="sections">
							<div className="inner">
								{categories.length ?
										<div className="list">
											{categories.map((v,i) => <div key={i} className={`section${categoryId===v._id?' section-select':''}`} onClick={() => setCategoryId(v._id)}>
												{v.name}
											</div>)}
										</div>
									:
										<div className="category-empty">
											<div>
												Здесь можно добавить новые категории, чтобы заполнить меню
											</div>
											<img src={imgArrowCurveDown} alt="" />
										</div>
								}
							</div>
							<div className="sections-footer">
								<button type="button" onClick={() => categoryAddShow()}>
									<img src={imgPlus} alt="" />
										Добавить категорию
								</button>
							</div>
						</div>
						<div className="content">
							{components.filter(f => f.category.id === categoryId).length ?
									<div className="components-list">
										{components.filter(f => f.category.id === categoryId).map((v,i) => <div key={i} className="component" onClick={() => componentSelect(v)}>
											{v.name}
											<span>{v.category.name} • {v.prices?.manual||v.prices?.invoice||0} ₽ • {v.weight} {measureTypeName[v.measure]}</span>
										</div>)}
									</div>
								:
									<div className="empty">
										<Empty image={'components'} text={
											<>
												Для добавления компонента,<br />выберите один из списка слева
												<div className="product-add-container">
													<p>или<br/>добавьте новую позицию</p>
													<button type="button" onClick={() => componentAddShow()}>Добавить компонент</button>
												</div>
											</>} />
									</div>
								}
						</div>
					</div>
				</div>
			</ModalItemComponents>
			<ModalComponent>
				<div className="modal modal-component-newadd">
					<div className="header">
						<h4>Добавить компонент</h4>
						<img src={imgClose} alt="" onClick={modalComponentClose} className="btn-close" />
					</div>
					<form className="product-edit" onSubmit={componentSave}>
						<div className="container-inner">
							<div className="list">
								<div className="product-edit-row">
									<input type="text" {...bindComponentName} placeholder="Название компонента" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindComponentDescription} className="lite"></textarea>
								</div>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-plus">
									<div className="select select-wide">
										<select onChange={handleCategory} value={categoryId} required>
											<option value="">Выберите категорию</option>
											{categories.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
									<img src={imgPlusButton} alt="" className="categoty-add" onClick={() => categoryAddShow()} />
								</div>
								<h4>Объем / Вес</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<input type="text" {...bindComponentWeight} placeholder="Объем / Вес" maxLength={4} required />
									<div className="select">
										<select onChange={handleMeasureType} value={componentMeasureType} required>
											{measureTypeName.map((v,i) => <option key={i} value={i===0?'':i}>{v}</option>)}
										</select>
									</div>
								</div>
								<h4>Цены</h4>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-select">
									<label className="label-middle">Цена из последней накладной</label>
									{componentInvoicePrice?`${componentInvoicePrice} ₽`:'–'}
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="price" className="label-middle">Цена (коррекция), ₽</label>
									<input id="price" {...bindComponentPrice} type="text" placeholder="0" maxLength={6} />
								</div>
								<h4>Свойства</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label className="label-middle">Штучный, количественный товар</label>
									<img src={isPieces ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsPieces(!isPieces)} />
								</div>
								<h4>Дополнительные параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="minimum" className="label-middle">Минимальный остаток на складе</label>
									<input id="minimum" {...bindComponentMinimum} type="text" placeholder="0" maxLength={4} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="requestcount" className="label-middle">Количество для заявки по умолчанию</label>
									<input id="requestcount" {...bindComponentRequestCount} type="text" placeholder="0" maxLength={4} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="status">Статус</label>
									<div className="select select-middle">
										<select id="status" onChange={handleComponentStatus} value={componentStatus} required>
											<option value=""></option>
											{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
							</div>
						</div>
						<div className="product-edit-footer">
							<button type="button" onClick={modalComponentClose} className="btn-cancel">Отменить</button>
							<button type="submit" className="btn-save btn-accept">Сохранить</button>
						</div>
					</form>
				</div>
			</ModalComponent>
			<ModalCategoryAdd>
				<div className="modal">
					<div className="header">
						<h4>Добавить категорию</h4>
						<img src={imgClose} alt="" onClick={modalCategoryAddClose} className="btn-close" />
					</div>
					<input type="text" {...bindCategoryName} placeholder="Название категории" autoFocus={true} />
					<div className="buttons">
						<button type="button" onClick={modalCategoryAddClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={categorySave} className="btn-accept">Сохранить</button>
					</div>
				</div>
			</ModalCategoryAdd>
			<ModalDelete>
				<div className="modal">
					<div className="header">
						<h4>Удалить причну списания</h4>
						<img src={imgClose} alt="" onClick={modalDeleteHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите удалить <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalDeleteHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={writeoffDelete} className="btn-accept btn-delete">Удалить</button>
					</div>
				</div>
			</ModalDelete>
		</>
	);
};

export default WriteoffsScreen;