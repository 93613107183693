import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get		= async ()						=> await Http.request('roles');
const all		= async ()						=> await Http.request('roles/all');

const add		= async (data)					=> await Http.request('role', httpMethods.POST, data);
const update	= async (id, data)				=> await Http.request(`role/${id}`, httpMethods.PUT, data);

const remove	= async (id)					=> await Http.request(`role/${id}`, httpMethods.DELETE);


export {
	get,
	all,
	add,
	update,
	remove
}