import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get		= async ()			=> await Http.request('components');

const add		= async (data)		=> await Http.request('component', httpMethods.POST, data);
const update	= async (id, data)	=> await Http.request(`component/${id}`, httpMethods.PUT, data);

const remove	= async (id)		=> await Http.request(`component/${id}`, httpMethods.DELETE);


export {
	get,
	add,
	update,
	remove
}