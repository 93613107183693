import React, {useEffect,useState} from 'react';

// plug-ins
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {LoyalityTransactions} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';
import Orfo from '../../../Globals/Orfo';

// constants
import { transactionTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';


// start
const LoyalityTransactionsScreen = () => {
	const [loading, setLoading] = useState(true);
	const [transactions, setTransactions] = useState([]);
	const [transactionsAll, setTransactionsAll] = useState([]);
	const {value:amount, setValue:setAmount, bind:bindAmount} = useInput('');
	const {value:comment, setValue:setComment, bind:bindComment} = useInput('');
	const {value:phones, setValue:setPhones, bind:bindPhones} = useInput('');
	const {value:days, setValue:setDays, bind:bindDays} = useInput('30');
	const [createdAt, setCreatedAt] = useState('');
	const [clients, setClients] = useState([]);
	const [type, setType] = useState('');
	const [search, setSearch] = useState('');
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await loyalitiesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const loyalitiesGet = async () => {
		const transactions = await LoyalityTransactions.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (transactions === undefined) return;
		setTransactions(transactions);
		setTransactionsAll(transactions);
	};
	const transactionAdd = () => {
		setIsShow(false);
		setIsEditShow(true);
		show();
	}
	const transactionShow = (transaction) => {
		show(transaction);
		setIsEditShow(false);
		setIsShow(true);
	}
	const show = (transaction) => {
		setAmount(transaction?transaction.amount:'');
		setComment(transaction?transaction.description:'');
		setPhones(transaction?transaction.phones:'');
		setDays(transaction?transaction.properties.days:'');
		setType(transaction?transaction.type:'');
		setClients(transaction?transaction.clients:[]);
		setCreatedAt(transaction?transaction.createdAt:'');
		setIsEditShow(true);
	};
	const cancel = () => {
		transactionShow(null);
		setIsEditShow(false);
		setIsShow(false);
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			amount:amount,
			comment:comment,
			phones:phones,
			days:days,
			type:type,
		};
		if (Utils.empty(data.amount)) {
			errorShow('Необходимо заполнить сумму');
			return;
		}
		const res = await LoyalityTransactions.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			loyalitiesGet();
			cancel();
		}
	}
	const searchTransactions = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setTransactions(transactionsAll);
		else setTransactions(transactionsAll.filter(f => f.phone.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const handleType = (e) => setType(parseInt(e.target.value));
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Бонусы" type="marketing" />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchTransactions} value={search} />
										<button type="button" onClick={() => transactionAdd()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{transactions.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Дата</th>
															<th className="status">Тип</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{transactions.map((v,i) => <tr key={i} onClick={() => transactionShow(v)}>
															<td>{moment(v.createdAt).format('DD.MM.YYYY HH:mm')}</td>
															<td className="status">
																<div className={`status${v.type}`}>
																	{transactionTypeName[v.type]}
																</div>
															</td>
															<td className="control"></td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте бонусы<br/>программы лояльности
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isShow ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													<div className="product-view-row product-view-row-simple">
														<h3>{moment(createdAt).format('DD.MM.YYYY HH:mm')}</h3>
													</div>
													{comment ? 
														<div className="product-view-row-simple">
															{comment}
														</div> : null}
													<h4>Пользователи</h4>
													<div className="product-view-row">
														{clients.map((v,i) => <div key={i}>
															{Utils.phoneFormatter(v.phone)}
															{v.name ? ` (${v.name})` : null}
														</div>)}
													</div>
													<h4>Параметры</h4>
													<div className="product-view-row product-view-row-oneline">
														<span>Бонусов</span>
														<div>{amount?`${amount} ₽`:'–'}</div>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Действует в течении</span>
														<div>{Orfo.counters.days(days)}</div>
													</div>
												</div>
											</div>
										</div>
									: null}
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row product-edit-row-first">
														<div className="select select-wide">
															<select onChange={handleType} value={type} required>
																<option value="">Выберите тип</option>
																{transactionTypeName.map((v,i) => i === 0 ? null : <option key={i} value={i}>{v}</option>)}
															</select>
														</div>
													</div>
													<div className="product-edit-row">
														<textarea placeholder="Комментарий" {...bindComment}></textarea>
													</div>
													<h4>Пользователи</h4>
													<div className="product-edit-row">
														<textarea placeholder="Телефоны через запятую" {...bindPhones}></textarea>
													</div>
													<h4>Правила</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="bonuses" className="label-middle">Кол-во бонусов</label>
														<input id="bonuses" {...bindAmount} type="text" placeholder="0" maxLength={5} required />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="bonuses" className="label-middle">Время действия, дней</label>
														<input id="bonuses" {...bindDays} type="text" placeholder="0" maxLength={3} required />
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									: null}
								{isShow || isEditShow ? null : <Empty image={'points'} text={<>Для добавления или просмотра транзакции,<br />выберите одину из списка</>} />}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default LoyalityTransactionsScreen;