import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get = {
	all			: async ()			=> await Http.request('products'),
	id			: async (id)		=> await Http.request(`product/${id}`),
	active		: async ()			=> await Http.request('products/active'),
};

const add		= async (data)		=> await Http.request('product', httpMethods.POST, data);
const update	= async (id, data)	=> await Http.request(`product/${id}`, httpMethods.PUT, data);

const status	= async (id, data)	=> await Http.request(`product/status/${id}`, httpMethods.PATCH, data);

const patch		= async (id, data)	=> await Http.request(`product/${id}`, httpMethods.PATCH, data);

const remove	= async (id)		=> await Http.request(`product/${id}`, httpMethods.DELETE);

const imageAdd	= async (id, data)	=> await Http.request(`product/image/${id}`, httpMethods.POST, data);

const images	= async (id, data)	=> await Http.request(`product/images/${id}`, httpMethods.PATCH, data);


export {
	get,
	add,
	update,
	status,
	patch,
	remove,
	imageAdd,
	images
}