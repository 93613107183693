import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import InputMask from 'react-input-mask';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {Addresses, Cities, Points} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { commonStatus, commonStatusName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgCheck from './Images/check.svg';
import imgClose from './Images/close.svg';
import imgEdit from './Images/edit.svg';

// start
const PointsScreen = () => {
	const [ModalChangeStatus, modalChangeStatusOpen, modalChangeStatusClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [point, setPoint] = useState(null);
	const [pointsAll, setPointsAll] = useState([]);
	const [points, setPoints] = useState([]);
	const [cities, setCities] = useState([]);
	const [addresses, setAddresses] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:domain, setValue:setDomain, bind:bindDomain} = useInput('');
	const {value:address, setValue:setAddress, bind:bindAddress} = useInput('');
	const {value:phone, setValue:setPhone, bind:bindPhone} = useInput('');
	const [search, setSearch] = useState(null);
	const [cityId, setCityId] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [status, setStatus] = useState(null);
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await citiesGet();
			await pointsGet();
			const point = ls('point');
			setPoint(point);
		};
		await dataGet();
		setLoading(false);
	}, []);
	const citiesGet = async () => {
		const cities = await Cities.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (cities === undefined) return;
		setCities(cities);
	};
	const pointsGet = async () => {
		const points = await Points.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (points === undefined) return;
		setPoints(points);
		setPointsAll(points);
	};
	const pointAdd = () => {
		setIsShow(false);
		setIsEditShow(true);
		show();
	}
	const pointEdit = (e, point) => {
		e.stopPropagation();
		point = point || points.find(f => f._id === id);
		show(point);
		setIsShow(false);
		setIsEditShow(true);
	};
	const pointShow = (point) => {
		show(point);
		setIsEditShow(false);
		setIsShow(true);
	}
	const show = (point) => {
		setControlId(null);
		setId(point?._id||null);
		setName(point?.name||'');
		setDomain(point?.domain||'');
		setAddress(point?.contacts.address||'');
		setPhone(point?.contacts.phone||'');
		setCityId(point?.cityId||'');
		setStatus(point?point.status:'');
		setIsEditShow(true);
	};
	const cancel = () => {
		pointShow(null);
		setIsEditShow(false);
		setIsShow(false);
		modalChangeStatusHide();
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			cityId:cityId,
			domain:domain,
			address:address,
			phone:Utils.phoneNormalize(phone),
			status:status
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название филиала');
			return;
		}
		if (Utils.empty(data.cityId)) {
			errorShow('Необходимо выбрать город филиала');
			return;
		}
		if (Utils.empty(data.address)) {
			errorShow('Необходимо заполнить адрес филиала');
			return;
		}
		if (Utils.empty(data.phone)) {
			errorShow('Необходимо заполнить телефон филиала');
			return;
		}
		const res = id ?
				await Points.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Points.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			pointsGet();
			cancel();
		}
	}
	const searchPoint = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setPoints(pointsAll);
		else setPoints(pointsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const pointControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const handleCity = (e) => setCityId(e.target.value);
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const addressGet = async (e) => {
		const address = e.target.value;
		const addresses = await Addresses.get(address, cityId).catch((ex) => console.log(666, ex));
		setAddress(address);
		setAddresses(addresses);
	}
	const selectAddress = (address) => {
		setAddress(address.address);
		setAddresses([]);
	}
	const pointSelect = (e, id) => {
		e.stopPropagation();
		const user = ls('user');
		const point = user.points.find(v => v.point.id === id);
		ls('point', point);
		setPoint(point);
		cancel();
	}
	const isCurrentPoint = (id) => point.point.id === id;
	const pointChangeStatusOn = (e, id) => {
		e.stopPropagation();
		pointChangeStatus(id);
	}
	const pointChangeStatusModal = () => pointChangeStatus(id);
	const pointChangeStatus = (id) => {
		const point = points.find(f => f._id === id);
		const status = point.status === commonStatus.ACTIVE ? commonStatus.IN_ACTIVE : commonStatus.ACTIVE;
		Points.status(id, {status}).then((res) => {
			successShow(res.message);
			pointsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalChangeStatusHide = () => modalChangeStatusClose();
	const modalChangeStatusShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		setName(points.find(f => f._id === id).name);
		modalChangeStatusOpen();
	}
	const cityGet = (id) => {
		const city = cities.find(f => f._id === id);
		return city?.name||'';
	};
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Филиалы" type={[{type:'account'},{type:'settings',title:'Настройки'}]} />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchPoint} value={search} />
										<button type="button" onClick={() => pointAdd()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{points.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Название</th>
															<th className="status status-small">Статус</th>
															<th className="current">Текущий</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{points.map((v,i) => <tr key={i} onClick={() => pointShow(v)}>
															<td>
																{v.name}
																<span>{v.contacts.address}</span>
															</td>
															<td className="status status-small">
																<div className={`status${v.status}`}>
																	<div className="status-point"></div>
																</div>
															</td>
															<td className="current">{isCurrentPoint(v._id) ? <img src={imgCheck} alt="" /> : null}</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => pointControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			<div className="edit" onClick={(e) => pointEdit(e, v)}>Редактировать</div>
																			<div className={v.status===commonStatus.ACTIVE?'inactive':'active'} onClick={(e) => v.status === commonStatus.ACTIVE ? modalChangeStatusShow(e, v._id) : pointChangeStatusOn(e, v._id)}>{v.status===commonStatus.ACTIVE?'Отключить':'Включить'}</div>
																			{isCurrentPoint(v._id) ? null : <div className="check" onClick={(e) => pointSelect(e, v._id)}>Сделать текущим</div>}
																		</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте первый<br/>филиал
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isShow ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
														<div className="product-view-row">
															<h3>{name}</h3>
															<span className="user-type">{cityGet(cityId)}</span>
														</div>
														<img src={imgEdit} alt="" className="edit" onClick={(e) => pointEdit(e)} />
													</div>
													<h4>Контакты</h4>
													<div className="product-view-row-simple">
														{address}
													</div>
													<div className="product-view-row-simple">
														{Utils.phoneFormatter(phone)}
													</div>
													<div className="product-view-row-simple">
														{domain}
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel btn-cancel-wide">Закрыть</button>
											</div>
										</div>
									: null}
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row">
														<input type="text" {...bindName} placeholder="Название филиала" className="input-title" autoFocus={true} required />
													</div>
													<div className="product-edit-row">
														<div className="select select-wide">
															<select onChange={handleCity} value={cityId} required>
																<option value="">Выберите город</option>
																{cities.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
															</select>
														</div>
													</div>
													<div className="product-edit-row">
														<input type="text" {...bindDomain} placeholder="Домен" required />
													</div>
													<h4>Контакты</h4>
													<div className="product-edit-row">
														<input type="text" {...bindAddress} placeholder="Адрес точки" onChange={addressGet} required />
													</div>
													{addresses.length ?
														<div className="addresses-list">
															{addresses.map((v,i) => <div key={i} onClick={() => selectAddress(v)}>{v.address}</div>)}
														</div> : null}
													<div className="product-edit-row">
														<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder="Телефон точки" maskPlaceholder={null} {...bindPhone} required />
													</div>
													<h4>Дополнительные параметры</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="status">Статус</label>
														<div className="select select-middle">
															<select id="status" onChange={handleStatus} value={status} required>
																<option value=""></option>
																{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
															</select>
														</div>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									: null}
								{isShow || isEditShow ? null : <Empty image={'points'} text={<>Для редактирования филиала,<br />выберите один из списка</>} />}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalChangeStatus>
				<div className="modal">
					<div className="header">
						<h4>Отключить филиал</h4>
						<img src={imgClose} alt="" onClick={modalChangeStatusHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите отключить филиал <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalChangeStatusHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={pointChangeStatusModal} className="btn-accept btn-delete">Отключить</button>
					</div>
				</div>
			</ModalChangeStatus>
		</>
	);
};

export default PointsScreen;