import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {Tickets} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { commonStatus } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgMenuPoints from './Images/menu-points.svg';
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgClose from './Images/close.svg';


// start
const SupportScreen = () => {
	const [ModalChangeStatus, modalChangeStatusOpen, modalChangeStatusClose] = useModal('root', {preventScroll:true});
	const [loading, setLoading] = useState(true);
	const [tickets, setTickets] = useState([]);
	const [ticketsAll, setTicketsAll] = useState([]);
	const [ticketsFull, setTicketsFull] = useState([]);
	const [ticket, setTicket] = useState(null);
	const [search, setSearch] = useState('');
	const [id, setId] = useState(null);
	const [parentId, setParentId] = useState(null);
	const {value:title, setValue:setTitle, bind:bindTitle} = useInput('');
	const {value:comment, setValue:setComment, bind:bindComment} = useInput('');
	const [isClosed, setIsClosed] = useState(false);
	const [status, setStatus] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await ticketsGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const ticketsGet = async () => {
		const tickets = await Tickets.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (tickets === undefined) return;
		const t = tickets.filter(f => Utils.empty(f.parentId));
		setTickets(t);
		setTicketsAll(t);
		setTicketsFull(tickets);
	};
	const ticketAdd = () => {
		setIsEditShow(true);
		show();
	}
	const ticketShow = (ticket) => {
		show(ticket);
		setIsEditShow(true);
	}
	const show = (ticket) => {
		setParentId(ticket ? ticket.parentId||ticket._id : null);
		setComment('');
		setTitle('');
		if (ticket) {
			const t = ticketsFull.filter(f => f._id === ticket._id || f.parentId === ticket._id);
			setTicket(t);
			setStatus(ticket?.status);
		} else setTicket(null);
		setIsEditShow(true);
	};
	const cancel = () => {
		ticketShow(null);
		setIsEditShow(false);
		modalChangeStatusHide();
	};
	const save = async (e) => {
		e.preventDefault();
		const data = {
			comment:comment
		};
		if (title) data.title = title;
		if (parentId) data.parentId = parentId;
		if (isClosed) data.status = commonStatus.IN_ACTIVE;
		if (Utils.empty(parentId)) {
			if (Utils.empty(data.title)) {
				errorShow('Необходимо заполнить тему');
				return;
			}
		}
		if (Utils.empty(data.comment)) {
			errorShow('Вы ничего не написали в сообщении');
			return;
		}
		const res = await Tickets.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			ticketsGet();
			cancel();
		}
	}
	const searchTicket = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setTickets(ticketsAll);
		else setTickets(ticketsAll.filter(f => f.title.name.toLowerCase().includes(search.toLowerCase()) || f.comment.toString().includes(search.toLowerCase())));
		setSearch(search);
	}
	const ticketControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const modalChangeStatusHide = () => modalChangeStatusClose();
	const ticketClose = () => {
		Tickets.close(id).then((res) => {
			successShow(res.message);
			ticketsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const changeStatusShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		modalChangeStatusOpen();
	};
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Поддержка" type="support" />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchTicket} value={search} />
										<button type="button" onClick={() => ticketAdd()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{tickets.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Тема</th>
															<th className="status status-small">Статус</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{tickets.map((v,i) => <tr key={i} onClick={() => ticketShow(v)} className={id===v._id?'row-selected':''}>
															<td>
																{v.title}
																<span>{moment(v.createdAt).format('DD.MM.YYYY HH:mm')}</span>
															</td>
															<td className="status status-small">
																<div className={`status${v.status}`}>
																	<div className="status-point"></div>
																</div>
															</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => ticketControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			{v.status === commonStatus.ACTIVE ?
																					<div className="check" onClick={(e) => changeStatusShow(e, v._id)}>Закрыть тикет</div>
																				:
																					<div className="empty">Нет доступных действий</div>
																			}
																	</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте обращение<br/>в поддержку
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													{ticket ?
															<div className="product-view-row product-view-row-simple">
																<h3>{ticket[ticket.length-1].title}</h3>
															</div>
														:
															<div className="product-edit-row">
																<input type="text" {...bindTitle} placeholder="Тема" className="input-title" autoFocus={true} required />
															</div>
													}
													<div className="product-edit-row">
														<textarea placeholder="Комментарий" {...bindComment} required></textarea>
													</div>
													{ticket ?
														<div className="product-edit-row product-edit-row-oneline">
															<label>Закрыть тикет</label>
															<img src={isClosed ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsClosed(!isClosed)} />
														</div> : null}
													{ticket ?
														<>
															<h4>Сообщения</h4>
															<div className="ticket-list">
																{ticket.map((v,i) => <div key={i} className="ticket">
																	<div className="ticket-body">
																		{v.comment}
																	</div>
																	<div className="ticket-info">
																		<div className="ticket-user">{v.isAdmin?<span>Техническая поддержка • </span>:''}{v.client?v.client.name:v.user.name}</div>
																		<div className="ticket-date">{moment(v.createdAt).format('DD.MM.YYYY HH:mm')}</div>
																	</div>
																</div>)}
															</div>
														</> : null}
												</div>
											</div>
											{ticket && status === commonStatus.IN_ACTIVE ?
													<div className="product-edit-footer">
														<button type="button" onClick={cancel} className="btn-cancel btn-cancel-wide">Закрыть</button>
													</div>
												:
													<div className="product-edit-footer">
														<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
														<button type="submit" className="btn-save">Сохранить</button>
													</div>
											}
										</form>
									:
										<Empty image={'caregories'} text={<>Для редактирования склада,<br />выберите один из списка</>} />
								}
								
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalChangeStatus>
				<div className="modal">
					<div className="header">
						<h4>Закрыть тикет</h4>
						<img src={imgClose} alt="" onClick={modalChangeStatusHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите закрыть тикет?</p>
					<div className="buttons">
						<button type="button" onClick={modalChangeStatusHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={ticketClose} className="btn-accept btn-delete">Закрыть</button>
					</div>
				</div>
			</ModalChangeStatus>
		</>
	);
};

export default SupportScreen;